import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { useCart } from '../cartContext/cartContext';
import { CartItem, CartWithSteps } from '../apiContext/cart/cart';
import { useProduct } from '../productContext/productContext';
import { queryKeys } from '../../queryKeys';

import { UpsellItem } from './upsell';

interface UpsellContextType {
  upsells: UpsellItem[];
  cartStatus: UseQueryResult<CartWithSteps, unknown>;
  currentItem?: CartItem;
  hasUpsells: boolean;
  hasError: boolean;
  setUpsells: (upsells: UpsellItem[]) => void;
  resetUpsells: () => void;
}

const upsellContext = createContext<UpsellContextType>({} as UpsellContextType);

export const UpsellContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { productId } = useProduct();
  const { cartWithSteps } = useCart();
  const queryClient = useQueryClient();
  const [upsells, setUpsells] = useState<UpsellItem[]>([]);
  const currentItem = cartWithSteps.data?.cart?.items
    .filter((item) => item.product.productId === productId)
    .at(-1);
  const hasUpsells = !!currentItem?.upsells?.length;
  const hasError = upsells.some((upsell) => !!upsell.error && !!upsell.totalQuantity);

  useEffect(() => {
    setOriginalUpsells();
  }, [cartWithSteps.isSuccess, cartWithSteps.isFetching]);

  const setOriginalUpsells = () => {
    const { upsells } = currentItem || {};

    if (!upsells) return;

    const upsellsDeepCopy = upsells.map((upsell) => ({
      ...upsell,
      guestTypes: { ...upsell.guestTypes },
    }));

    setUpsells(upsellsDeepCopy);
  };

  const resetUpsells = () => {
    queryClient.removeQueries({ queryKey: [queryKeys.UPSELL], exact: true });
    setOriginalUpsells();
  };

  const value = {
    upsells,
    cartStatus: cartWithSteps,
    currentItem,
    hasUpsells,
    hasError,
    setUpsells,
    resetUpsells,
  };

  return <upsellContext.Provider value={value}>{children}</upsellContext.Provider>;
};

export const useUpsell = () => useContext(upsellContext);
