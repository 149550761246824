import { GuestProduct, ProductBase } from '../contexts/productContext/product';
import { Settings } from '../contexts/settingsContext/settings';

import { mapGA4ViewItems } from './mappers';

export const getProduct = (product: GuestProduct, settings: Settings) => {
  const viewItem = {
    id: settings.productId,
    name: product.name || '',
    brand: settings.providerName || '',
    category: '', // not available in product or settings
    price: product.price || 0,
  };

  return {
    ...viewItem,
    value: viewItem.price,
    currency: product.currency,
    ga4_items: [mapGA4ViewItems(viewItem)],
  };
};
