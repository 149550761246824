import React from 'react';
import {
  ButtonGroup,
  Box,
  Divider as MuiDivider,
  FormHelperText as MuiFormHelperText,
  IconButton as MuiIconButton,
  TextField as MuiTextField,
  Tooltip as MuiTooltip,
  Theme,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { ContainersArea, styled } from '@guest-widgets/core';
import { red } from '@mui/material/colors';

import { getMarginLeft, cssTextFieldValues, cssButtonValues } from './helpers';

export type ComponentPosition = 'start' | 'end';

type ControllerProps = {
  position: ComponentPosition;
  theme: Theme;
} & ContainersArea;

export type InputProps = {
  size: 'large' | 'medium' | 'small';
  theme: Theme;
};

type FormHelperTextProps = {
  label?: string;
  position: ComponentPosition;
} & ContainersArea;

export const CustomButtonGroup = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: 'none',
  '&:before': {
    content: '""',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderColor: 'inherit',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 4,
    position: 'absolute',
    opacity: 0.23,
  },
  '& > button:first-of-type': {
    marginLeft: 1,
  },
  '& > button:last-of-type': {
    marginRight: 1,
  },
});

export const Divider = styled(MuiDivider)({
  opacity: 0.23,
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
});

export const Controller = styled(({ position, ...props }) => <ButtonGroup {...props} />)(
  ({ position, widgetArea: { basedOnSize }, theme: { spacing } }: ControllerProps) => ({
    display: 'flex',
    flexDirection: basedOnSize('column', 'row'),
    alignItems: basedOnSize(`flex-${position}`, 'center'),
    justifyContent: basedOnSize(`flex-${position}`, `flex-${position}`),
    gap: spacing(2),
  })
);

export const LoadingContainer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
});

export const IconButton = styled(({ size, ...props }) => <MuiIconButton {...props} />)(
  ({ size, theme }: InputProps) => ({
    padding: cssButtonValues(size, theme).padding,
    minWidth: cssButtonValues(size).width,

    '& svg': {
      fontSize: cssButtonValues(size, theme).fontSize,
    },
  })
);

export const TextField = styled(({ size, ...props }) => <MuiTextField {...props} />)(
  ({ size, theme: { spacing } }: InputProps) => ({
    width: cssTextFieldValues(size).width,

    '& input': {
      textAlign: 'center',
      padding: cssTextFieldValues(size, spacing).padding,
    },

    '& fieldset': {
      top: 0,
      borderRadius: 0,
      borderColor: 'transparent',
      margin: '0 -2px',
    },

    // Remove arrows and spinners for Chrome, Safari, Edge, Opera
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    // Remove arrows and spinners for Firefox
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  })
);

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: red[50],
    color: 'inherit',
    border: `1px solid ${red[900]}`,
    maxWidth: '14em',
  },
  '& p': {
    opacity: 0.75,
  },
});
