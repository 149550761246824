export enum ThemeFactor {
  /** Value to reach the Mui button font size from typography.fontSize (16px) */
  BUTTON_FONT_SIZE = 1.143,
  /** Value to reach the Mui button font size contained from typography.fontSize (18px) */
  BUTTON_FONT_SIZE_CONTAINED = 1.2857,
  /** Value to reach the Mui button font size outlined from typography.fontSize (16px) */
  BUTTON_FONT_SIZE_OUTLINED = 1.143,
  /** Value to reach the Mui button font size small from typography.fontSize (13px) */
  BUTTON_FONT_SIZE_SMALL = 0.9286,
  /** Value to reach the Mui button font size icon from typography.fontSize (20px) */
  BUTTON_FONT_SIZE_ICON = 1.4286,
  /** Value to reach the Mui button font size icon small from typography.fontSize (18px) */
  BUTTON_FONT_SIZE_ICON_SMALL = 1.2857,
  /** Value to reach the Mui link font size from typography.fontSize (13px) */
  LINK_FONT_SIZE = 0.9286,
  /** Value to reach the Mui badge font size from typography.fontSize (12px) */
  BADGE_FONT_SIZE = 0.8571,
  /** Value to reach the Mui helper text font size from typography.fontSize (12.25px) */
  HELPER_TEXT_FONT_SIZE = 0.875,
  /** Value to reach the Mui icon font size from typography.fontSize (35px) */
  ICON_FONT_SIZE_LARGE = 2.5,
  /** Value to reach the Mui icon font size from typography.fontSize (24px) */
  ICON_FONT_SIZE = 1.7143,
  /** Value to reach the Mui icon font size small from typography.fontSize (21px) */
  ICON_FONT_SIZE_SMALL = 1.5,
}
