import { ChangeEvent, useEffect } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useCalendarLanguage } from '@guest-widgets/shared/src/components/Calendar/useCalendarLanguage';
import { isEqual } from 'date-fns';

import { useProductInSelection } from '../../Guest/productInSelectionContext';
import { useSettings } from '../../../contexts/settingsContext/settingsContext';
import { AvailabilityTimeDayTicket } from '../../../contexts/timeslotsContext/timeslots';

import { getTimeslotOptionLabel } from './getTimeslotOptionLabel';
import { getTestID } from './getTestId';

export const TimeDropdown = () => {
  const { date, displayTime, time = {}, setTime, companyInformation } = useProductInSelection();
  const { locale } = useSettings();
  const calendarLanguage = useCalendarLanguage(locale);
  const availabilities = date?.from?.availabilityTimes as Array<AvailabilityTimeDayTicket>;

  if (!availabilities) return null;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const availability = availabilities.find((item) =>
      isEqual(item.timeRange.startTime, new Date(Number(event.target.value)))
    );

    setTime({
      startTime: availability?.timeRange.startTime,
      endTime: availability?.timeRange.endTime,
    });
  };

  const getLabel = (option: AvailabilityTimeDayTicket) =>
    getTimeslotOptionLabel({
      timeRange: option.timeRange,
      locale: calendarLanguage,
      showEndTimes: displayTime?.showEndTimes,
      providerLocale: companyInformation?.locale,
    });

  return (
    <TextField
      select
      fullWidth
      value={time.startTime?.getTime() || ''}
      onChange={handleChange}
      data-testid={getTestID('dropdown', displayTime)}
      SelectProps={{
        MenuProps: {
          marginThreshold: 0,
        },
      }}
    >
      {availabilities.map((option) => (
        <MenuItem
          key={option.timeRange.startTime.getTime()}
          value={option.timeRange.startTime.getTime()}
          disabled={option.trafficLightStatus === 'sold-out'}
        >
          {
            <span
              style={{
                textDecoration: option.trafficLightStatus === 'sold-out' ? 'line-through' : 'none',
              }}
            >
              {getLabel(option)}
            </span>
          }
        </MenuItem>
      ))}
    </TextField>
  );
};
