import { BuyerAdditionalField, CheckboxField, Contact, ContactForm } from './contact';
import { isBuyerAdditionalField, isCheckboxField, isHtmlField, isWaiverField } from './typeGuards';

export const getContactDefaultValues = (contact?: Contact): ContactForm['contact'] | undefined => {
  if (!contact) return;

  const map = (fields: BuyerAdditionalField): ContactForm['contact'] =>
    fields.reduce<ContactForm['contact']>((total, field) => {
      if (isHtmlField(field)) {
        // HTML field is used only for html output. No input needed
        return total;
      }
      return {
        ...total,
        [field.id]:
          isWaiverField(field) || isCheckboxField(field) ? Boolean(field.value) : field.value || '',
      };
    }, {});

  const basicInformation = map(contact.basicInformation);

  let result = basicInformation;

  if (!contact.additionalInformation) return result;

  contact.additionalInformation.forEach((field) => {
    if (isBuyerAdditionalField(field)) {
      result = {
        ...result,
        ...map(field),
      };
    } else {
      result = {
        ...basicInformation,
        ...Object.entries(field).reduce<ContactForm['contact']>(
          (allFields, [, attendeeFields]) => ({
            ...allFields,
            ...map(attendeeFields),
          }),
          {}
        ),
      };
    }
  });

  return result;
};
