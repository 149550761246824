/** This function iterates the html from the inner web component to the
 * outer shadow dom and returns its host: the first shadow host of html.
 * It is used to render elements that need to be in light dom (outside
 * of any shadow dom).
 */
export const getOutterShadowHost = (element: HTMLElement) => {
  let currentElement: Node = element;
  let outterHost: Node | undefined = undefined;

  while (currentElement?.parentNode) {
    currentElement = (currentElement?.getRootNode() as ShadowRoot).host;
    if (!currentElement) continue;
    outterHost = currentElement;
  }

  return outterHost ?? currentElement;
};
