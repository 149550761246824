type FeatureMap = { [feature: string]: boolean };

/**
 * Takes the map of features with its corresponding default enabled state and overrides them
 * with the provided space separated lists
 * enable="X" disable="Y Z"
 * {
 *   [Feature.V]: true -> true
 *   [Feature.X]: false -> true
 *   [Feature.Y]: true -> false
 *   [Feature.Z]: true -> false
 * }
 */
export function getFeatureMap(
  defaultFeatureMap: FeatureMap,
  disableFeatures: string[] = [],
  enableFeatures: string[] = []
) {
  return Object.entries(defaultFeatureMap).reduce((map, [key, isEnabled]) => {
    if (disableFeatures.includes(key)) isEnabled = false;
    if (enableFeatures.includes(key)) isEnabled = true;
    map[key] = isEnabled;
    return map;
  }, {} as FeatureMap);
}
