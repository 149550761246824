import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '@eo-locale/react';
import { green } from '@mui/material/colors';
import LockOutlined from '@mui/icons-material/LockOutlined';

import { Stepper } from '../../Stepper';
import { StyledContainer } from '../../StyledSharedComponents';
import { useCartApi } from '../../contexts/apiContext/cart/useCartApi';
import { useContactApi } from '../../contexts/apiContext/contact/useContactApi';

import { Navigation } from './Navigation';

const SECONDS_TO_REDIRECT = 5;
const SECONDS_TO_WARNING = 2;

export interface CheckoutCountDownProps {
  bookingLink: string;
}

export const CheckoutCountDown = ({ bookingLink }: CheckoutCountDownProps) => {
  const [seconds, setSeconds] = useState(SECONDS_TO_REDIRECT);
  const [progressValue, setProgressValue] = useState(1);
  const [isRedirectWarning, setIsRedirectWarning] = useState(false);
  const cartApi = useCartApi();
  const contactApi = useContactApi();

  const redirectToCheckout = () => {
    cartApi.destroy.mutate();
    contactApi.destroy();
    window.location.href = bookingLink;
  };

  useEffect(() => {
    if (seconds > 0) {
      const timeout = setTimeout(() => setSeconds(Math.max(seconds - 1, 0)), 1000);
      return () => clearTimeout(timeout);
    }

    redirectToCheckout();

    setTimeout(() => setIsRedirectWarning(true), SECONDS_TO_WARNING * 1000);
  }, [seconds]);

  useEffect(() => {
    //Triggers CSS transition of Circular Progress for exactly SECONDS_TO_REDIRECT seconds
    setProgressValue(100);
  }, []);

  return (
    <div>
      <Stepper />
      <Container>
        <Progress size={100} thickness={4} variant="determinate" value={progressValue} />
        <Typography>
          {isRedirectWarning ? (
            <Text id="if-not-redirected-click-the-button" />
          ) : (
            <Text id="you-will-be-redirected-to-the-payment-page-in-a-while" />
          )}
        </Typography>
        <SecurePayments>
          <LockOutlined />
          <Text id={'payments-are-secure'} />
        </SecurePayments>
      </Container>
      <Navigation seconds={seconds} action={redirectToCheckout} />
    </div>
  );
};

const Container = styled(StyledContainer)(({ theme: { spacing } }) => ({
  alignItems: 'center',
  margin: spacing(10, 5, 10, 5),
  '& *': {
    textAlign: 'center',
  },
}));

const Progress = styled(CircularProgress)(({ theme: { spacing } }) => ({
  '& .MuiCircularProgress-circle': {
    transitionDuration: `${SECONDS_TO_REDIRECT}s`,
    transitionTimingFunction: 'linear',
  },
  marginBottom: spacing(5),
}));

const SecurePayments = styled(Typography)(({ theme: { spacing } }) => ({
  color: green['700'],
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: spacing(1),
  },
}));
