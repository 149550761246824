import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const NoTicketIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_2730_112263)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7025 2.2925L5.41 4L21.0834 19.679C21.4113 20.0711 21.3899 20.654 21.0025 21.0225C20.6125 21.4125 19.9825 21.4125 19.5925 21.0225L18.5712 20H4C2.9 20 2 19.1 2 18V14C3.11 14 4 13.1 4 12C4 10.9 3.11 10 2.01 10V6C2.01 5.29517 2.36885 4.67905 2.91245 4.32316L2.2925 3.7025C1.9025 3.3125 1.9025 2.6825 2.2925 2.2925C2.6825 1.9025 3.3125 1.9025 3.7025 2.2925ZM13 17.5H11V15.5H13V17.5Z"
      />
      <path d="M22 17.59V14C20.9 14 20 13.1 20 12C20 10.9 20.9 10 22 10V6C22 4.89 21.1 4 20 4H8.41L10.91 6.5H13V8.59L22 17.59Z" />
    </g>
    <defs>
      <clipPath id="clip0_2730_112263">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default NoTicketIcon;
