import { datadogRum } from '@datadog/browser-rum';

import type { Tag } from './customerTrackingCreator';
import { GA4Event, PurchaseEcommerceGtm } from './types';

/** It manages datadot custom conversion events, using google anaytics data structure and event names */
export const datadog = (): Tag => ({
  isAvailable: () => !!datadogRum.getInternalContext(),

  viewProduct: (product) => {
    datadogRum.addAction(GA4Event.product, {
      ecommerce: product,
    });
  },

  addToCart: (cart) => {
    const { value, currency, ga4_items } = cart;

    datadogRum.addAction(GA4Event.add, {
      ecommerce: {
        value,
        currency,
        items: ga4_items,
      },
    });
  },

  removeFromCart: (cart) => {
    const { value, currency, ga4_items } = cart;

    datadogRum.addAction(GA4Event.remove, {
      ecommerce: {
        value,
        currency,
        items: ga4_items,
      },
    });
  },

  checkout: (checkoutData) => {
    const { value, currency, ga4_items } = checkoutData;

    datadogRum.addAction(GA4Event.checkout, {
      ecommerce: {
        currency,
        value,
        items: ga4_items,
      },
    });
  },
});
