import EventIcon from '@mui/icons-material/Event';
import TodayIcon from '@mui/icons-material/Today';
import GroupIcon from '@mui/icons-material/Group';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Typography } from '@mui/material';
import { styled } from '@guest-widgets/core';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { useCalendarLanguage } from '@guest-widgets/shared/src/components/Calendar/useCalendarLanguage';
import { addDays, differenceInCalendarDays, format } from 'date-fns';
import { mapMomentToDateFormat } from '@guest-widgets/shared/src/mappers/momentToDateFnsMapper';

import { Appointment, AppointmentDayTicket, DatetimeRange } from '../../contexts/cartContext/cart';
import { useSettings } from '../../contexts/settingsContext/settingsContext';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { AllocationType } from '../../contexts/productContext/product';
import { useProduct } from '../../contexts/productContext/productContext';

import { getAppointmentStartDatetime } from './itemChecker';
import { Container } from './ItemInfo';
import { ItemTimeUnit } from './ItemTimeUnit';

export const ItemDetails = ({ cartItem }: { cartItem?: CartItem }) => {
  const { locale } = useSettings();
  const calendarLanguage = useCalendarLanguage(locale);
  const { companyInformation, allowBookingMultiDays } = useProduct();

  if (!cartItem) return null;

  const { start, end, guestTypes } = cartItem;
  const appointment: Appointment = {
    datetimeRange: { startDatetime: new Date(start), endDatetime: new Date(end) },
  };
  const guestTypesKeys = Object.keys(guestTypes);

  if (!start || !guestTypesKeys) return null;

  return (
    <Container>
      <div className={booking.product.datetime}>
        {isAppointmentDay(appointment) ? (
          <>
            <IconText variant="body2" className={booking.product.date}>
              <TodayIcon />{' '}
              {format(
                appointment.datetimeRange.startDatetime,
                mapMomentToDateFormat(companyInformation?.locale?.dateFormatMoment!),
                {
                  locale: calendarLanguage,
                }
              )}
              {allowBookingMultiDays &&
                ` - ${format(
                  appointment.datetimeRange.startDatetime,
                  mapMomentToDateFormat(companyInformation?.locale?.timeFormatMoment!),
                  { locale: calendarLanguage }
                )}`}
            </IconText>
            {showEndDate(cartItem.product.allocationType, appointment.datetimeRange) && (
              <IconText variant="body2" className={booking.product.date}>
                <EventIcon />{' '}
                {format(
                  showOneDayBeforeWhenAllocationTypeIsDay(
                    cartItem.product.allocationType,
                    appointment.datetimeRange.endDatetime
                  ),
                  mapMomentToDateFormat(companyInformation?.locale?.dateFormatMoment!),
                  {
                    locale: calendarLanguage,
                  }
                )}
                {allowBookingMultiDays &&
                  ` - ${format(
                    appointment.datetimeRange.endDatetime,
                    mapMomentToDateFormat(companyInformation?.locale?.timeFormatMoment!),
                    { locale: calendarLanguage }
                  )}`}
              </IconText>
            )}
          </>
        ) : (
          <>
            <IconText variant="body2" className={booking.product.time}>
              <ScheduleIcon />
              <ItemTimeUnit
                appointment={appointment}
                locale={calendarLanguage}
                providerLocale={companyInformation?.locale}
              />
            </IconText>
            <IconText variant="body2" className={booking.product.date}>
              <TodayIcon />{' '}
              {format(
                getAppointmentStartDatetime(appointment),
                mapMomentToDateFormat(companyInformation?.locale?.dateFormatMoment!),
                {
                  locale: calendarLanguage,
                }
              )}
            </IconText>
          </>
        )}
        <IconText variant="body2">
          <GroupIcon />
          {guestTypesKeys.map(
            (guestType: string) =>
              !!guestTypes[guestType] && (
                <TextFirstUppercase
                  key={guestType}
                  variant="body2"
                  className={booking.cart.productDesc}
                >
                  {`${guestTypes[guestType]} x ${guestType}`}
                </TextFirstUppercase>
              )
          )}
        </IconText>
      </div>
    </Container>
  );
};

const isAppointmentDay = (appointment: AppointmentDayTicket) => {
  const { startDatetime, endDatetime } = appointment.datetimeRange;
  return differenceInCalendarDays(endDatetime, startDatetime) > 0;
};

/** It fixes the view of 'endDate' when allocationType is 'day'
 * It is a temporary solution, because API is not returning the correct data
 * TODO: remove this when API is fixed
 */
const showEndDate = (allocationType: AllocationType, dateRange: DatetimeRange) =>
  allocationType !== 'day' ||
  differenceInCalendarDays(dateRange.endDatetime, dateRange.startDatetime) > 1;

/** It fixes the view of 'endDate' when allocationType is 'day'
 * It is a temporary solution, because API is not returning the correct data
 * TODO: remove this when API is fixed
 */
const showOneDayBeforeWhenAllocationTypeIsDay = (allocationType: AllocationType, date: Date) =>
  allocationType === 'day' ? addDays(date, -1) : date;

// todo: #6E6E6E find a best way to define this color and reuse it. Search with this color code in entire booking widget.
const IconText = styled(Typography)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
  marginBottom: spacing(2),
  color: '#6E6E6E',
  '&:last-child': {
    marginBottom: 0,
  },
}));

const TextFirstUppercase = styled(Typography)({
  textTransform: 'capitalize',
});
