import { isPossiblePhoneNumber } from 'react-phone-number-input';
import isPast from 'date-fns/isPast';
import type { TranslationsContextProps } from '@eo-locale/react';

type translateFn = TranslationsContextProps['translator']['translate'];

/** It validates whether has more than the specied number of chars */
const minChars = (translate: translateFn, minimumChar: number) => {
  const MIN_TWO_CHAR_REGEX = new RegExp(`\\w{${minimumChar},}|^$`);

  return [
    MIN_TWO_CHAR_REGEX,
    translate('must-have-at-least-variable-characters', { minimumChar }),
  ] as const;
};

/** It validates whether has more than 2 chars */
export const minTwoChars = (translate: translateFn) => minChars(translate, 2);

/** It validates whether has more than 4 chars */
export const minFourChars = (translate: translateFn) => minChars(translate, 4);

/** It validates phone structure */
export const telephoneSchema = (translate: translateFn) => {
  return [
    'is-phone',
    () => translate('please-provide-a-valid-phone-number'),
    (value?: string) => !value || isPossiblePhoneNumber(value),
  ] as const;
};

/** It validates whether date is in the past */
export const dateIsInThePast = (translate: translateFn) => {
  return [
    'is-date-in-past',
    translate('date-can-not-be-in-the-future'),
    (date?: string) => {
      return !date || isPast(new Date(date));
    },
  ] as const;
};
