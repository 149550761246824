import React from 'react';
import MuiSkeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { StyledSkeletonContainer } from '../../StyledSharedComponents';

export const Skeleton = () => {
  return (
    <StyledSkeletonContainer>
      <StyledRow>
        <MuiSkeleton variant="text" width={100} height={40} />
        <MuiSkeleton variant="text" width={100} height={40} />
      </StyledRow>
    </StyledSkeletonContainer>
  );
};

const StyledRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
