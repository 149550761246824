import { useMemo } from 'react';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { SvgIconTypeMap, SxProps, TypographyVariant } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@guest-widgets/core';
import SellIcon from '@mui/icons-material/Sell';

import { useCart } from '../../contexts/cartContext/cartContext';
import { cartId } from '../../contexts/apiContext/cart/session/useSession';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { CartDeposit, CartItem, DepositOption } from '../../contexts/apiContext/cart/cart';
import { useUpsellInSelection } from '../../Upsell/upsellInSelectionContext';
import { UpsellItem, UpsellWithPricing } from '../../contexts/upsellContext/upsell';
import { getParentItem } from '../../contexts/apiContext/upsell/getParentItem';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import { Tax } from '../../contexts/cartContext/cart';

export interface TotalRow {
  title: string;
  variable?: Object;
  icon?: OverridableComponent<SvgIconTypeMap>;
  amount?: number;
  currencyCode: string;
  render: boolean;
  formatType?: 'negative' | 'positive';
  variant?: TypographyVariant;
  sx?: SxProps;
  withColor?: boolean;
}

interface UseTotalResult {
  totalRows: TotalRow[];
}

export const useTotal = (): UseTotalResult => {
  const { cartWithSteps } = useCart();
  const { currentStep } = useRouter();
  const { upsells } = useUpsell();
  const [[discountCode]] = useLocalStorage(`${cartId}-discounts`, []);
  const [deposit] = useLocalStorage(`${cartId}-deposit`, {} as CartDeposit);
  const { formatPrice } = useNumberFormatter();
  const { upsellApi } = useUpsellInSelection();
  const isCart = currentStep === 'cart';
  const isUpsell = currentStep === 'upsell';
  const isPayDepositSelected = deposit?.option === DepositOption.DEPOSIT;

  const totalRows: TotalRow[] = useMemo(() => {
    if (!cartWithSteps.data?.cart) return [];

    const { items, totals } = cartWithSteps.data.cart;
    const { subtotal, grand, deposits, discount, ibaFee, taxes } = totals;
    const depositInfo = deposits?.find((deposit) => deposit.option === DepositOption.DEPOSIT);
    // TODO: Remove this to implement deposit optinal section once the pay option can be passed to API.
    const isOptional = false;
    // const isOptional = deposits?.every((deposit) => !!deposit.price.amount);
    const highlightDeposit = isOptional ? isPayDepositSelected : !!depositInfo?.price?.amount;
    // TODO: Remove this to implement discount section once the discount is passed from API.
    const isDiscountShown = false;

    const { price: depositPrice } = depositInfo || { price: { amount: 0, currencyCode: 'EUR' } };

    return [
      {
        title: 'subtotal',
        amount: isUpsell
          ? getUpsellsPricing(items, upsells, upsellApi.data)?.subtotal?.amount
          : subtotal.gross.amount!,
        currencyCode: subtotal.gross.currencyCode!,
        render: true,
      },
      {
        title: discountCode,
        icon: FlippedSellIcon,
        // TODO: Include correct discount amount when Checkfront provides it.
        amount: subtotal.gross.amount! - discount?.amount!,
        currencyCode: grand?.gross.currencyCode!,
        formatType: 'negative' as TotalRow['formatType'],
        render: isCart && !!discountCode && !!discount && isDiscountShown,
      },
      ...taxes.map(mapTaxToTotalRow),
      {
        title: 'currency-adjustment',
        amount: ibaFee?.gross.amount!,
        currencyCode: ibaFee?.gross.currencyCode!,
        render: !!ibaFee,
      },
      {
        title: 'grand-total',
        amount: isUpsell
          ? getUpsellsPricing(items, upsells, upsellApi.data)?.total.amount
          : grand?.gross.amount!,
        currencyCode: grand?.gross.currencyCode!,
        variant:
          isCart && highlightDeposit ? ('body2' as TypographyVariant) : ('h2' as TypographyVariant),
        sx: { fontWeight: 'bold' },
        withColor: true,
        render: true,
      },
      {
        title: 'due-now',
        amount: depositPrice.amount,
        currencyCode: depositPrice.currencyCode,
        variant: highlightDeposit ? ('h2' as TypographyVariant) : ('body2' as TypographyVariant),
        sx: { fontWeight: 'bold' },
        withColor: true,
        render: isCart && highlightDeposit,
      },
      {
        title: 'pay-variable-deposit-today-with-variable-due-later',
        variable: {
          deposit: formatPrice(depositPrice.amount, depositPrice.currencyCode),
          due: formatPrice(grand?.gross.amount - depositPrice.amount!, depositPrice.currencyCode),
        },
        amount: undefined,
        currencyCode: grand?.gross.currencyCode!,
        render: isCart && !isOptional && !!depositInfo?.price?.amount,
      },
    ];
  }, [
    cartWithSteps?.data?.cart?.totals.subtotal.gross.amount,
    deposit?.option,
    upsellApi?.data?.totals.subtotal.amount,
    upsells[0]?.parent.lineId,
  ]);

  return {
    totalRows,
  };
};

const getUpsellsPricing = (
  cartStateItems: CartItem[],
  upsells: UpsellItem[],
  upsellsFromApi?: UpsellWithPricing
) => {
  const hasUpsellAdded = upsells.some((upsell) => upsell.totalQuantity);

  if (!upsellsFromApi || !hasUpsellAdded) {
    const parentItem = getParentItem(cartStateItems, upsells[0]);
    return parentItem?.price;
  }

  return upsellsFromApi.totals;
};

const mapTaxToTotalRow = (tax: Tax): TotalRow => ({
  title: `${tax.name}${
    tax.type === 'percent' || tax.type === 'percentInitialCustomer'
      ? ` (${tax.amountPercent}%)`
      : ''
  }`,
  amount: tax.amount.amount,
  currencyCode: tax.amount.currencyCode,
  render: true,
});

const FlippedSellIcon = styled(SellIcon)({
  transform: 'scaleX(-1)',
  paddingRight: 0,
  paddingLeft: 4,
}) as OverridableComponent<SvgIconTypeMap>;
