import { createContext, useContext, useState, PropsWithChildren } from 'react';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import type { TimeRange as BaseTimeRange } from '../contexts/timeslotsContext/timeslots';
import { useUpsell } from '../contexts/upsellContext/upsellContext';
import { UpsellItem, UpsellWithPricing } from '../contexts/upsellContext/upsell';
import { useUpsellApi } from '../contexts/apiContext/upsell/useUpsellApi';
import { queryKeys } from '../queryKeys';
import { useSession } from '../contexts/apiContext/cart/session/useSession';

export type TimeRange = Partial<BaseTimeRange>;

interface UpsellInSelectionContext {
  upsellItem?: UpsellItem;
  upsellApi: UseQueryResult<UpsellWithPricing>;
  handleQuantityChange: (id: string, productId: string, quantity: number) => void;
}

const UpsellInSelectionContext = createContext({} as UpsellInSelectionContext);

export const UpsellInSelectionProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { upsells, setUpsells, currentItem } = useUpsell()!;
  const { generateUpsellId } = useSession();
  const [upsellItem, setUpsellItem] = useState<UpsellItem>();
  const queryClient = useQueryClient();
  const isDateTimeSelectionComplete = !!upsellItem?.parent.start && !!upsellItem?.parent.end;

  const setQuantityHandler = (parentLineId: string, productId: string, quantity: number) => {
    const upsellIndex = upsells.findIndex(
      (upsell) => upsell.parent.lineId === parentLineId && upsell.product.productId === productId
    );

    if (upsellIndex < 0) return;

    if (quantity === 0) {
      queryClient.removeQueries({ queryKey: [queryKeys.UPSELL], exact: true });
      // Reset the upsell to its original state, the state mapped from the API response
      const resetedUpsell = currentItem?.upsells?.find(
        (upsell) => upsell.parent.lineId === parentLineId && upsell.product.productId === productId
      );
      upsells[upsellIndex] = { ...resetedUpsell!, guestTypes: { ...resetedUpsell!.guestTypes } };

      return setUpsells([...upsells]);
    }

    updateUpsell(upsells[upsellIndex], quantity);
    setUpsells([...upsells]);
    setUpsellItem({ ...upsells[upsellIndex] });
  };

  const updateUpsell = (upsell: UpsellItem, quantity: number) => {
    if (!upsell.id) {
      upsell.id = upsell.id ?? generateUpsellId(upsell.parent.lineId);
      upsell.start = upsell.parent.start;
      upsell.end = upsell.parent.end;
    }

    const guestTypeKey = Object.keys(upsell.guestTypes)[0];
    upsell.guestTypes[guestTypeKey] = quantity;
    upsell.totalQuantity = quantity;
    upsell.error = undefined;
  };

  const upsellsToValidate: UpsellItem[] = upsells.filter((upsell) => !!upsell.totalQuantity) || [];

  const upsellApi = useUpsellApi({ upsells: upsellsToValidate });

  const value: UpsellInSelectionContext = {
    upsellItem,
    upsellApi,
    handleQuantityChange: setQuantityHandler,
  };

  return (
    <UpsellInSelectionContext.Provider value={value}>{children}</UpsellInSelectionContext.Provider>
  );
};

export const useUpsellInSelection = () => {
  const context = useContext(UpsellInSelectionContext);
  if (context === undefined) {
    throw new Error('useUpsellInSelection must be used within a UpsellInSelectionProvider');
  }
  return context;
};
