import { differenceInCalendarDays, isBefore, isEqual } from 'date-fns';
import { useTranslator } from '@eo-locale/react';
import { UseQueryResult } from '@tanstack/react-query';

import { useTimeslots } from '../../../../../contexts/timeslotsContext/timeslotsContext';
import { useProduct } from '../../../../../contexts/productContext/productContext';
import type { Timeslots } from '../../../../../contexts/timeslotsContext/timeslots';

import { getRulesByType } from './utils';

interface TooltipTitleProps {
  from?: Date;
  to?: Date;
  isSelected: boolean;
  isDisabled: boolean;
}

const getRules = (timeslot: UseQueryResult<Timeslots, unknown>, date: Date) => {
  return (
    timeslot.data?.days?.find(({ day }) => isEqual(day, date))?.availabilityTimes?.[0]?.rules || {}
  );
};

export const useTooltipHandler = (date: Date) => {
  const { timeslot } = useTimeslots();
  const { translate } = useTranslator();
  const { allocationType: type } = useProduct();
  const rules = getRules(timeslot, date);
  const { minDuration, maxDuration } = rules;

  const getTooltipTitle = ({ from, to, isSelected, isDisabled }: TooltipTitleProps) => {
    if (!from || isDisabled || type === 'flextime') return '';

    // Compare the hovered date depending on the selected range and the hovered relative position to range
    const selectedDayToBeConsidered = isBefore(date, from) && !!to ? to : from;

    if (maxDuration && isOverMaxDuration(date, selectedDayToBeConsidered) && !isSelected) {
      return translate(`${type}-maximum`, { day: maxDuration });
    }

    if (minDuration && isUnderMinDuration(date, selectedDayToBeConsidered)) {
      return translate(`${type}-minimum`, { day: minDuration });
    }

    if (!minDuration) return '';

    return translate('unavailable-days-in-range');
  };

  const isOverMaxDuration = (date: Date, selectedDayToBeConsidered: Date) => {
    const { maxDuration: maxDurationFromRules } = getRulesByType(type, rules) || {};

    return (
      maxDurationFromRules &&
      Math.abs(differenceInCalendarDays(date, selectedDayToBeConsidered)) + 1 > maxDurationFromRules
    );
  };

  const isUnderMinDuration = (date: Date, selectedDayToBeConsidered: Date) => {
    const { minDuration: minDurationFromRules } = getRulesByType(type, rules) || {};

    return (
      minDurationFromRules &&
      Math.abs(differenceInCalendarDays(date, selectedDayToBeConsidered)) + 1 < minDurationFromRules
    );
  };

  return { getTooltipTitle };
};
