import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useSettings } from '../../settingsContext/settingsContext';
import { useSession } from '../cart/session/useSession';
import { CartItem } from '../cart/cart';
import { UpsellItem, UpsellWithPricing } from '../../upsellContext/upsell';
import { guestApi } from '../product/guestApi';
import { mapToRequestItems } from '../product/mapToPricing';
import { queryKeys } from '../../../queryKeys';

import { mapToUpsellsFromPricing } from './mapToUpsellsFromPricing';
import { getParentItem } from './getParentItem';

interface UpsellApiProps {
  upsells: UpsellItem[];
}

export const useUpsellApi = ({ upsells }: UpsellApiProps): UseQueryResult<UpsellWithPricing> => {
  const { locale, customerCode } = useSettings();
  const { cartState } = useSession();

  const ids = upsells.reduce((acc, upsell) => `${acc}_${upsell.id}`, '');
  const quantities = upsells.reduce((acc, upsell) => `${acc}_${upsell.totalQuantity}`, '');

  const queryDeps = [queryKeys.UPSELL, ids, quantities];

  const hasParameters = Boolean(locale && customerCode && !!ids && !!quantities);

  const queryFn = async () => {
    const response = await guestApi.getPricing(customerCode, {
      lineItems: [
        ...mapToRequestItems([getParentItem(cartState.items, upsells[0]) ?? ({} as CartItem)]),
        ...mapToRequestItems((upsells as unknown) as CartItem[]),
      ],
    });

    return mapToUpsellsFromPricing(upsells, response);
  };

  return useQuery({
    queryKey: queryDeps,
    queryFn,
    enabled: hasParameters,
  });
};
