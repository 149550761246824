import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { CountrySelectItem } from '@guest-widgets/shared/src/types/CountrySelectItem';
import { getNames, registerLocale } from 'i18n-iso-countries';
import enMessages from 'i18n-iso-countries/langs/en.json';
import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';
import { config } from '@guest-widgets/shared/src/config';

import { useSettings } from '../settingsContext/settingsContext';

import { namesMapper, LanguageMap, LanguageCode } from './namesMapper';

export interface CountryContext {
  countries: CountrySelectItem[];
}

const countryContext = createContext<CountryContext>({ countries: [] });

export const CountryContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { locale } = useSettings();

  const language = getAvailableLanguage(languageMap, locale);
  const [countries, setCountries] = useState<CountrySelectItem[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      registerLocale(await languageMap[language]());
      setCountries(
        Object.entries(getNames(language)).map(([value, label]) => ({
          label,
          value,
        }))
      );
    };

    if (language) {
      fetchCountries();
    }
  }, [language]);

  return <countryContext.Provider value={{ countries }}>{children}</countryContext.Provider>;
};

export const useCountry = () => useContext(countryContext);

const languageMap: LanguageMap = {
  en: () => Promise.resolve(enMessages),
  ...namesMapper,
};

export const getAvailableLanguage = (
  languageMap: LanguageMap,
  locale: LanguageLocaleCode
): LanguageCode => {
  const languageCode = locale.split('-')[0];
  if (languageCode in languageMap) return languageCode as LanguageCode;

  //otherwise fallbacks to default language
  return config.fallbackLanguage.split('-')[0] as LanguageCode;
};
