import {
  getCountries,
  getCountryCallingCode as baseGetCountryCallingCode,
} from 'react-phone-number-input/input';
import type { CountryCode } from 'libphonenumber-js/core';

/** Returns a list of objects with code, name and international phone code of a country,
 * sorted by name in the current locale.
 */
export const getSortedCountries = (language?: string) => {
  if (!language) return [];

  const countries = getCountries().map((code) => {
    const countryName = languageCountryName(language).of(code) ?? '';

    return {
      code,
      name: addEllipsis(countryName, MAX_COUNTRY_NAME_LENGTH),
      callingCode: getCountryCallingCode(code),
    };
  });

  countries.sort((a, b) => (a.name > b.name ? 1 : -1));

  return countries;
};

const languageCountryName = (language: string) =>
  new Intl.DisplayNames([language], { type: 'region' });

/** Returns the international phone code of a country. */
export const getCountryCallingCode = (code?: CountryCode) =>
  code ? `+${baseGetCountryCallingCode(code)}` : '';

const addEllipsis = (str: string, maxLength: number) =>
  str.length > maxLength ? `${str.slice(0, maxLength)}…` : str;

const MAX_COUNTRY_NAME_LENGTH = 25;
