import { ApiInvalidStructureException } from '../apiContext';
import {
  createAxiosInstance,
  unwrap,
  getApiErrorMessage,
  createAxiosInstanceGuest,
} from '../axiosApiHelpers';
import { isNextGenCheckoutEnabled } from '../../../common/utils';

import type {
  ValidateResponse,
  Booking,
  BookingCreateResponse,
  ContactField,
  ContactFieldsResponse,
  BookingPaymentResponse,
} from './dtos/response';
import type {
  BookingCreateRequest,
  ItemRequest,
  ValidateRequest,
  PricingRequest,
} from './dtos/request';

export const guestApi = {
  async getPricing(customerCode: string, request: PricingRequest): Promise<ValidateResponse> {
    return this.validate(
      {
        lineItems: [...request.lineItems],
        discountCodes: request.discountCodes,
      },
      customerCode
    );
  },
  async createBooking(customerCode: string, request: BookingCreateRequest): Promise<Booking> {
    const axiosInstance = createAxiosInstance(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.post<BookingCreateResponse>(`booking-create`, request)
      );

      const { code, cfx, total } = response.data;
      return {
        code,
        cfx,
        total,
      };
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
  async getBookingPaymentId(customerCode: string, bookingCode: string): Promise<string> {
    if (!isNextGenCheckoutEnabled()) return Promise.resolve('');

    const axiosInstance = createAxiosInstance(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.get<BookingPaymentResponse>(`bookings/${bookingCode}/payment`)
      );

      return response.data.paymentId;
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
  async validate(
    request: ValidateRequest,
    partnerCode: string,
    params?: string[]
  ): Promise<ValidateResponse> {
    const axiosInstance = createAxiosInstanceGuest(partnerCode);

    try {
      return await unwrap(
        axiosInstance.post<ValidateResponse>(
          `cart/validate`,
          {
            ...request,
          },
          {
            params: {
              with: ['pricing', ...(params ?? [])],
            },
          }
        )
      );
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
  validateContact(request: BookingCreateRequest, partnerCode: string) {
    return this.validate(
      {
        lineItems: request.cart.lineItems,
        bookingFields: request.cart.bookingFields,
      },
      partnerCode
    );
  },

  validateItem(item: ItemRequest, partnerCode: string) {
    return this.validate(
      {
        lineItems: [item],
      },
      partnerCode
    );
  },
  validateCart(
    { discountCodes, items }: { items: ItemRequest[]; discountCodes?: string[] },
    partnerCode: string
  ) {
    return this.validate(
      {
        lineItems: items,
        discountCodes,
      },
      partnerCode,
      ['upsells']
    );
  },
  async getContactFields(customerCode: string, productIds: string[]): Promise<ContactField[]> {
    const axiosInstance = createAxiosInstance(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.get<ContactFieldsResponse>('form-fields', {
          params: {
            applyTo: productIds,
            formType: 'booking',
            archived: false,
          },
        })
      );

      return response.data;
    } catch (e: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(e));
    }
  },
};
