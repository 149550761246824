import { useMutation } from '@tanstack/react-query';

import { AddToCartParams } from './useAddToCart';

interface ProductContactParams extends AddToCartParams {}

export const useSetProductContact = ({ cartSession }: ProductContactParams) => {
  return useMutation({
    mutationFn: async ({
      productId,
      contactData,
    }: {
      productId: string;
      contactData: Record<string, string>;
    }) => {
      cartSession.setProductContact(productId, contactData);
      return cartSession.getShoppingCart();
    },
  });
};
