import React from 'react';
import { Box, Skeleton as MuiSkeleton } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';

import { StyledSkeletonContainer } from '../StyledSharedComponents';

export const Skeleton = () => {
  return (
    <StyledSkeletonContainer className={classes.booking.loading}>
      <Box sx={{ width: '100%', mx: 'auto', mt: 4 }} px={4}>
        <MuiSkeleton variant="text" width={280} height={48} />

        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <MuiSkeleton variant="text" width={60} />
          <MuiSkeleton variant="text" width={60} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={4}>
          <MuiSkeleton variant="text" width={90} height={48} />
          <MuiSkeleton variant="text" width={90} height={48} />
        </Box>

        <MuiSkeleton variant="rectangular" width="100%" height={100} />
      </Box>
    </StyledSkeletonContainer>
  );
};

const SkeletonCard = () => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center' }} mt={6} mb={4}>
      <MuiSkeleton variant="rectangular" width={96} height={80} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: 80,
        }}
        ml={4}
      >
        <MuiSkeleton variant="text" width="60%" />
        <MuiSkeleton variant="text" width="40%" />
      </Box>
      <MuiSkeleton sx={{ alignSelf: 'flex-end' }} variant="rectangular" width={80} height={40} />
    </Box>
    <Box mb={6}>
      <MuiSkeleton variant="text" width="100%" />
      <MuiSkeleton variant="text" width="80%" />
    </Box>
  </>
);
