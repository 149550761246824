import { useWidget } from '@guest-widgets/core';
import { useEffect } from 'react';

import { useTimeslots } from '../../../../contexts/timeslotsContext/timeslotsContext';

/** It gets based on available space, the number of months that the calendar should render */
export const useCalendarNumberOfMonths = (limitMonths?: number) => {
  const { setFilters } = useTimeslots();
  const { proportionalWidgetArea } = useWidget();
  const numberOfMonthsBasedOnSize = proportionalWidgetArea.basedOnSize(1, 2, 3);
  const numberOfMonths = Math.min(limitMonths || Infinity, numberOfMonthsBasedOnSize);

  useEffect(() => {
    // API only supports request up to 3 months
    const calculatedMonths = numberOfMonths + 1 > 3 ? 3 : numberOfMonths + 1;
    setFilters({ numberOfMonths: calculatedMonths });
  }, [numberOfMonths]);

  return numberOfMonths;
};
