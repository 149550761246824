import React from 'react';
import { Button } from '@mui/material';
import { Text } from '@eo-locale/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { classes } from '@guest-widgets/shared/src/classes';

import { useRouter } from '../contexts/routerContext/routerContext';
import { NavigationContainer } from '../StyledSharedComponents';
import { useCart } from '../contexts/cartContext/cartContext';

export const Navigation = () => {
  const { nextStep, setCurrentStep } = useRouter();
  const { cartWithSteps } = useCart();
  const nextStepHandler = () => {
    if (cartWithSteps.data?.cart?.isValid) {
      nextStep();
    }
  };

  return (
    <NavigationContainer>
      <Button
        color="primary"
        variant="contained"
        size="large"
        fullWidth
        onClick={() => nextStepHandler()}
        className={`${classes.booking.buttons.button} ${classes.booking.buttons.checkout}`}
      >
        <Text id="go-to-checkout" />
      </Button>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ChevronLeftIcon />}
        onClick={() => setCurrentStep('product')}
        fullWidth
        className={classes.booking.buttons.back}
      >
        <Text id="continue-shopping" />
      </Button>
    </NavigationContainer>
  );
};
