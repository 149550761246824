import React, { PropsWithChildren } from 'react';
import { format } from 'date-fns';
import { mapMomentToDateFormat } from '@guest-widgets/shared/src/mappers/momentToDateFnsMapper';
import { CompanyLocaleDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import { Appointment } from '../../contexts/cartContext/cart';
import { defaultProviderLocale } from '../../contexts/productContext/productContext';

import { isAppointmentDayTicket } from './itemChecker';

interface ItemTimeUnitProps {
  appointment: Appointment;
  locale?: Locale;
  providerLocale?: CompanyLocaleDto;
}

export const ItemTimeUnit = ({
  appointment,
  locale,
  providerLocale = defaultProviderLocale,
}: PropsWithChildren<ItemTimeUnitProps>) => {
  if (isAppointmentDayTicket(appointment)) {
    return (
      <>
        {format(
          appointment.datetimeRange.startDatetime,
          mapMomentToDateFormat(providerLocale?.timeFormatMoment!),
          { locale }
        )}
        -
        {format(
          appointment.datetimeRange.endDatetime,
          mapMomentToDateFormat(providerLocale?.timeFormatMoment!),
          { locale }
        )}
      </>
    );
  }

  return (
    <>
      {format(appointment.datetime, mapMomentToDateFormat(providerLocale?.timeFormatMoment!), {
        locale,
      })}
    </>
  );
};
