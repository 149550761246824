import { AllocationType } from '../../../../../contexts/productContext/product';
import { Rules } from '../../../../../contexts/timeslotsContext/timeslots';

/** Increase limit of duration when product is type "night" */
export const getRulesByType = (
  allocationType: AllocationType,
  rules?: Rules
): Rules | undefined => {
  if (!rules) return;

  // Handle undefined or defined values
  let { minDuration, maxDuration } = rules;

  // Apply changes only when product is type "night"
  if (allocationType === 'night') {
    minDuration = minDuration ? minDuration + 1 : 2;
    maxDuration = maxDuration ? maxDuration + 1 : undefined;
  }

  // Ignore rules for days if product is type "flextime"
  if (allocationType === 'flextime') {
    minDuration = 1;
    maxDuration = undefined;
  }

  return { minDuration, maxDuration };
};
