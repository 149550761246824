import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { DayProps, useDayRender } from 'react-day-picker';
import { classes } from '@guest-widgets/shared/src/classes';

export const CalendarDayPicker = (props: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps, activeModifiers, isHidden } = useDayRender(
    props.date,
    props.displayMonth,
    buttonRef
  );

  if (isHidden) return <></>;

  return (
    <StyledButton
      variant="text"
      ref={buttonRef}
      disabled={activeModifiers.disabled}
      {...buttonProps}
      className={classes.booking.dayPicker.dayElement}
    />
  );
};

const StyledButton = styled(Button)(({ theme: { spacing } }) => ({
  minWidth: '3.5ch',
  width: '61%',
  margin: spacing(1.5, 0),
  '& > span': {
    opacity: 0.55,
  },
}));
