import React, { createContext, PropsWithChildren, useContext } from 'react';

import { defaultSharedFeatures, Feature } from '../../features';

import { getFeatureMap } from './getFeatureMap';

interface FeatureProviderProps {
  disable?: string[];
  enable?: string[];
}

export interface FeatureContext<Feature> {
  isEnabled(feature: Feature): boolean;
  isSomeEnabled(features: Feature[]): boolean;
}

const context = createContext<FeatureContext<Feature>>({
  isEnabled: () => false,
  isSomeEnabled: () => false,
});

export const FeatureProvider = ({
  children,
  disable = [],
  enable = [],
}: PropsWithChildren<FeatureProviderProps>) => {
  const map = getFeatureMap(defaultSharedFeatures, disable, enable);

  const value = {
    isEnabled: (feature: Feature) => map[feature],
    isSomeEnabled: (features: Feature[]) => features.some((feature) => map[feature]),
  };

  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useFeature = () => useContext(context);
