import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Text, useTranslator } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';

import { Stepper } from '../Stepper';
import { useContact } from '../contexts/contactContext/contactFormContext';
import { useRouter } from '../contexts/routerContext/routerContext';
import { StyledContainer } from '../StyledSharedComponents';
import { EmptyCart } from '../Cart/EmptyCart';
import { useCart } from '../contexts/cartContext/cartContext';
import { Totals } from '../common/Totals';

import { Navigation } from './Navigation';
import { AdditionalFields } from './AdditionalFields';

export const Contact = () => {
  const { form, contactSubmit } = useContact();
  const { hasItems, cartWithSteps } = useCart();
  const { nextStep } = useRouter();
  const { translate } = useTranslator();

  useEffect(() => {
    if (contactSubmit.isSuccess) {
      nextStep();
      contactSubmit.reset();
    }
  }, [contactSubmit.isSuccess]);

  if (!hasItems && !cartWithSteps.isFetching) return <EmptyCart />;

  return (
    <>
      <Stepper />
      <form
        onSubmit={form?.handleSubmit((contactForm) => {
          contactSubmit.mutate(contactForm);
        })}
        noValidate
      >
        <StyledContainer>
          <Typography variant="h3">
            <Text id="please-provide-your-contact-information" />
          </Typography>
          <AdditionalFields />
          <Typography variant="caption">{`* ${translate('this-is-a-mandatory-field')}`}</Typography>
          <StyledHr />
          <Totals />
        </StyledContainer>
        <Navigation actionStatus={contactSubmit} />
      </form>
    </>
  );
};

const StyledHr = styled('div')(({ theme: { border, spacing } }) => ({
  borderBottom: border,
  marginBottom: spacing(-6),
  marginTop: spacing(-2),
}));
