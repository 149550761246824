import { Box, Typography } from '@mui/material';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { Text } from '@eo-locale/react';

import { Navigation } from '../Navigation';
import { ProductDescription } from '../ProductDescription';
import { mapToPublicMoney } from '../../contexts/apiContext/cart/mapToCart';
import { TimeslotsContextProvider } from '../../contexts/timeslotsContext/timeslotsContext';
import { useInnerProduct } from '../../hooks/product/useInnerProduct';
import { useQuantityGuestRestrictions } from '../../hooks/product/useQuantityGuestRestriction';

import { Time } from './Time';
import { QuantitySelection } from './QuantitySelection';
import { Calendar } from './Calendar';

export const ProductInner = () => {
  const {
    requiresTimeSelection,
    hasError,
    addToCartHandler,
    name,
    quantity,
    total,
    currency,
    addToCartErrors,
    addToCart,
    pricingApi,
    isComplete,
    requiresDateRange,
    setDate,
    guestTypes,
    date,
    error,
    rateLimitError,
  } = useInnerProduct();

  useQuantityGuestRestrictions(guestTypes, date);

  return (
    <TimeslotsContextProvider>
      <Box pt={4} className={booking.steps}>
        <Typography variant="h4" mb={4} component="h3" className={booking.step.title}>
          <Text id="date" />
        </Typography>
        <Calendar
          requiresDateRange={requiresDateRange}
          setDate={setDate}
          date={date}
          hasError={hasError}
          error={error}
        />
        {requiresTimeSelection && <Time hasError={hasError} error={error} />}
      </Box>
      <QuantitySelection />
      <ProductDescription
        name={name}
        price={!!quantity ? mapToPublicMoney(total).amount : 0}
        currency={currency}
        isLoading={pricingApi.isFetching}
      />
      <Navigation
        actionStatus={addToCart}
        isComplete={isComplete}
        onAddToCart={addToCartHandler}
        parentErrors={addToCartErrors}
        disableAddToCard={hasError}
        rateLimitError={rateLimitError}
      />
    </TimeslotsContextProvider>
  );
};
