import React, { PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import { customTheme } from '@guest-widgets/shared/src/components/ConfigurationTheme/customTheme';
import { downloadFont } from '@guest-widgets/shared/src/components/ConfigurationTheme/downloadFont';
import type { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import MuiScopedCssBaseline, {
  ScopedCssBaselineProps as MuiScopedCssBaselineProps,
} from '@mui/material/ScopedCssBaseline';

import { useSettings } from '../settingsContext/settingsContext';

import { bookingTheme } from './bookingTheme';

export const BookingThemeContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const theme = useTheme();
  const settings = useSettings();

  const { configuration } = settings;

  let themeUpdated = configuration ? customTheme(theme, configuration) : theme;

  if (configuration?.fontFamily) {
    downloadFont(configuration.fontFamily);
  } else {
    downloadFont('Roboto');
  }

  return (
    <ThemeProvider theme={bookingTheme(themeUpdated, settings)}>
      <StyledScopedCssBaseline configuration={configuration}>{children}</StyledScopedCssBaseline>
    </ThemeProvider>
  );
};

type WithConfiguration = {
  configuration?: ConfigurationSettingsDto;
} & MuiScopedCssBaselineProps;

const StyledScopedCssBaseline = styled(({ configuration, ...props }: WithConfiguration) => (
  <MuiScopedCssBaseline {...props} />
))(({ configuration, theme: { palette } }) => {
  const contrast = (color?: string) => (color ? palette.getContrastText(color) : undefined);

  const { dayElement, selected } = booking.dayPicker;

  return {
    [`& .${selected} button.${dayElement}, & button.${booking.time.selected}`]: {
      backgroundColor: configuration?.accentColor,
      color: contrast(configuration?.accentColor),
    },

    [`& button.${booking.time.selected} + span`]: {
      borderColor: configuration?.accentColor,
    },
  };
});
