import { Text } from '@eo-locale/react';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLessRounded';
import { styled } from '@guest-widgets/core';

export const LinkWithIcon = ({ showMore }: { showMore: boolean }) => {
  return showMore ? (
    <Container>
      <Text id="show-less" />
      <ExpandLessIcon />
    </Container>
  ) : (
    <Container>
      <Text id="show-more" />
      <ExpandMoreIcon />
    </Container>
  );
};

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 3,
  color: '#155EEF',
  fontWeight: 700,
  textDecoration: 'underline',
});
