import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useCart } from '../../contexts/cartContext/cartContext';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';

import { Optional } from './Optional';
import { SameParentQuantity } from './SameParentQuantity';
import { UpsellInCart } from './UpsellInCart';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const ItemUpsellPrice = ({ item }: ItemUpsellPriceProps) => {
  const { currentStep } = useRouter();
  const { cartWithSteps } = useCart();
  const { items: cartItems } = cartWithSteps.data?.cart ?? {};
  const isCartStep = currentStep === 'cart';
  const isSameParentQuantity =
    item.configuration?.sameParentQuantity ??
    getConfiguration(item, cartItems)?.sameParentQuantity ??
    false;

  if (isCartStep) {
    return <UpsellInCart item={item} />;
  }

  if (isSameParentQuantity) {
    return <SameParentQuantity item={item} />;
  }

  return <Optional item={item} />;
};

const getConfiguration = (upsellItem: UpsellItem, cartItems?: CartItem[]) => {
  return cartItems
    ?.find((cartItem) => cartItem.id.startsWith(`${upsellItem.id}.`))
    ?.upsells?.find((item) => item.id === upsellItem.id)?.configuration;
};
