import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Select, MenuItem } from '@mui/material';
import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';
import { CountryOption } from '@guest-widgets/shared/src/components/CountryAutocomplete';

import { useSettings } from '../../contexts/settingsContext/settingsContext';
import { getFlagSize, StyledRowContainer } from '../../StyledSharedComponents';
import { SupportedCurrencies } from '../../contexts/settingsContext/settings';

import { Skeleton } from './Skeleton';

export const CurrencyAndLanguage = () => {
  const {
    currency,
    locale,
    addSettings,
    availableCurrencies,
    availableLanguages,
    configuration,
  } = useSettings();

  const disableCurrency = true; // Disabled temporary. See REG-14251

  if (!availableCurrencies && !availableLanguages) return null;

  return !availableCurrencies || !availableLanguages ? (
    <Skeleton />
  ) : (
    <Container>
      <Box>
        {availableCurrencies && !disableCurrency && (
          <Select
            variant="standard"
            value={currency}
            onChange={(e) => addSettings({ currency: e.target.value as SupportedCurrencies })}
          >
            {availableCurrencies.map(({ name, code, symbol }) => (
              <MenuItem value={code} key={code}>
                {name} ({symbol})
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      <Box>
        {availableLanguages.length > 1 && (
          <Select
            value={locale}
            variant="standard"
            onChange={(e) => addSettings({ locale: e.target.value as LanguageLocaleCode })}
          >
            {availableLanguages.map(({ name, code, flagCode }) => (
              <MenuItem value={code} key={code}>
                <CountryOption
                  label={name}
                  value={flagCode}
                  flagSize={getFlagSize(configuration?.fontSize)}
                />
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    </Container>
  );
};

const Container = styled(StyledRowContainer)({
  paddingTop: 0,
});
