import { Button, CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import CloseIcon from '@mui/icons-material/Close';

import { LoadingContainer } from '../StyledSharedComponents';
import { useCart } from '../contexts/cartContext/cartContext';
import { ErrorMessage } from '../Error';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  productName: string;
  rateLimitError?: boolean;
};

const ItemDeleteDialog = ({ productName, open, onClose, onConfirm, rateLimitError }: Props) => {
  const { removeItem } = useCart();

  return (
    <StyledDialog
      maxWidth={'sm'}
      open={open}
      onClose={onClose}
      aria-labelledby="delete-item"
      disableEnforceFocus
    >
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon />
      </CloseIconWrapper>
      <CustomDialogContent spacing={4}>
        <DeleteText>
          <Text id="delete-product-text" />
        </DeleteText>
        <DescriptionText>
          <Text
            id="delete-product-description"
            html
            productName={`<strong style ="fontWeight:700;">${productName}</strong>`}
          />
        </DescriptionText>
        <ButtonsWrapper>
          <Button variant="outlined" color="primary" fullWidth onClick={onClose}>
            <Text id="cancel" />
          </Button>
          <Button variant="contained" color="error" fullWidth onClick={onConfirm}>
            <Text id="delete" />
          </Button>
        </ButtonsWrapper>
        {rateLimitError && <ErrorMessage show messageKey={'rate-limited-error'} mt={0} mb={0} />}
      </CustomDialogContent>
      {removeItem.isLoading && (
        <LoadingContainer>
          <CircularProgress size={50} />
        </LoadingContainer>
      )}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(4),
  },
}));

const CloseIconWrapper = styled('div')(({ theme: { spacing, palette } }) => ({
  position: 'absolute',
  top: '7px',
  right: '13px',
  width: spacing(6),
  height: spacing(6),
  color: palette.grey[500],
}));

const CustomDialogContent = styled(Stack)(({ theme: { spacing } }) => ({
  display: 'flex',
  maxWidth: spacing(82),
  minWidth: spacing(62),
  flexDirection: 'column',
  paddingInline: spacing(6),
  paddingBlock: spacing(8),
}));

const ButtonsWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: spacing(4),
  justifyContent: 'center',
  marginBlock: spacing(2),
  '& :not(style) ~ :not(style)': {
    marginTop: 'unset',
  },
  '@media (max-width: 320px)': {
    gridTemplateColumns: '1fr',
  },
}));

const DeleteText = styled(Typography)(({ theme: { spacing, typography, palette } }) => ({
  color: palette.grey[800],
  fontSize: typography.body1.fontSize,
  fontWeight: 500,
  lineHeight: spacing(6),
  textAlign: 'left',
}));

const DescriptionText = styled(Typography)(({ theme: { palette, typography } }) => ({
  color: palette.grey[800],
  fontFamily: typography.fontFamily,
  fontSize: typography.body1.fontSize,
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'left',
}));

export default ItemDeleteDialog;
