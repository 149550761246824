import React, { useEffect, forwardRef, useState } from 'react';
import { TextFieldProps } from '@mui/material';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input/input';
import type { E164Number, CountryCode } from 'libphonenumber-js/core';

import { CountryOptionProps } from '../CountryAutocomplete';

import { getCountryCallingCode } from './getSortedCountries';
import { PhoneElement } from './PhoneElement';

export interface PhoneTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'onBlur'> {
  onChange?: (phone?: E164Number) => void;
  onBlur?: (phone?: E164Number) => void;
  defaultCountry?: CountryCode;
  countriesLanguage: string;
  flagSize: CountryOptionProps['flagSize'];
}

export const PhoneTextField = forwardRef(
  (
    {
      onChange,
      onBlur,
      value = '',
      defaultCountry,
      countriesLanguage,
      flagSize,
      ...props
    }: PhoneTextFieldProps,
    ref
  ) => {
    const [country, setCountry] = useState<CountryCode | undefined>();
    const [phone, setPhone] = useState(value as E164Number);

    const defaultCountryCallingCode = getCountryCallingCode(defaultCountry);

    const updatePhone = (item: E164Number = '') => {
      setCountry(
        item.startsWith(defaultCountryCallingCode)
          ? defaultCountry
          : parsePhoneNumber(item)?.country
      );
      setPhone(item);
    };

    useEffect(() => {
      if (!value && defaultCountry) {
        return updatePhone(defaultCountryCallingCode);
      }
      updatePhone(value as E164Number);
    }, [value]);

    /** Make sure to return some value only if something else, besides default country value, were provided */
    const sanitize = (newPhone: E164Number = '') =>
      newPhone.replace(defaultCountryCallingCode, '') ? newPhone : '';

    return (
      <PhoneInput
        {...props}
        ref={ref}
        value={phone}
        countryValue={country}
        flagSize={flagSize}
        countryNamesLanguage={countriesLanguage}
        onCountryChange={(country?: CountryCode) => {
          setCountry(country);
          setPhone(getCountryCallingCode(country));
        }}
        onChange={(newPhone) => {
          updatePhone(newPhone);
          onChange?.(sanitize(newPhone));
        }}
        onBlur={() => onBlur?.(sanitize(phone))}
        inputComponent={PhoneElement}
      />
    );
  }
);
