import { FetchStatus, QueryStatus, isError } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useLoadingError = (error: unknown, fetchStatus: FetchStatus, status: QueryStatus) => {
  const hasResponseProperty = (error: any): error is { response: any } => {
    return error?.response !== undefined;
  };
  const hasMessageProperty = (error: any): error is { message: any } => {
    return error?.message !== undefined;
  };
  const errorCode = useMemo(() => {
    if (error) {
      if (hasResponseProperty(error)) {
        return error?.response?.status;
      }
      if (hasMessageProperty(error)) {
        return error?.message?.match(/\d+/)?.[0];
      }

      return '400';
    }
  }, [error]);

  return {
    errorCode,
    isLoading: fetchStatus === 'fetching' && status === 'loading',
  };
};
