import {
  differenceInCalendarDays,
  isAfter,
  isBefore,
  eachDayOfInterval,
  addDays,
  isSameDay,
} from 'date-fns';
import { DateRange } from 'react-day-picker';

import { Rules, TimeslotsDay } from '../../../../../contexts/timeslotsContext/timeslots';
import { useProductInSelection } from '../../../../Guest/productInSelectionContext';
import { useAccumulatedTimeslots } from '../useAccumulatedTimeslots';

import { TimeslotDayRange } from './useCalendarRange';
import { getRulesByType } from './utils';

interface SelectCalculationsProps {
  onSelect: (range: TimeslotDayRange) => void;
}

export const useSelectCalculations = ({ onSelect }: SelectCalculationsProps) => {
  const allAvailableDays = useAccumulatedTimeslots();
  const { allocationType: type } = useProductInSelection();

  const isBetween = (selectedDate?: Date, dateFrom?: Date, dateTo?: Date) =>
    selectedDate &&
    dateFrom &&
    dateTo &&
    isAfter(selectedDate, dateFrom) &&
    isBefore(selectedDate, dateTo);

  /** Check if rules of min and max are correctly applied */
  const validateRange = (firstDate: Date, lastDate: Date, isBetween: boolean, rules?: Rules) => {
    if (!rules?.minDuration && !rules?.maxDuration) return true;

    const { minDuration, maxDuration } = getRulesByType(type, rules) || {};
    const duration = differenceInCalendarDays(lastDate, firstDate) + 1;

    if (isBetween) {
      return minDuration ? duration >= minDuration : true;
    }

    return maxDuration ? duration <= maxDuration : true;
  };

  /** Check if all days of the range are available (no unavailable days in range) */
  const isAvailableRange = (firstDate?: Date, lastDate?: Date) => {
    if (!firstDate || !lastDate) return false;

    return !isSameDay(firstDate, lastDate)
      ? eachDayOfInterval({ start: firstDate, end: lastDate }).every((day) =>
          allAvailableDays.find((availableDay) => isSameDay(availableDay.day, day))
        )
      : true;
  };

  /** Includes date range and isAvailable property on "select" */
  const setOnSelect = (date: DateRange | undefined) => {
    const { from, to } = date ?? {};

    if (!from) {
      onSelect({ from: undefined, to: undefined });
      return;
    }

    const dayFrom = getDay(from);
    const dayTo = to ? getDay(to) : selectDayTo(dayFrom);

    onSelect({
      from: dayFrom,
      to: dayTo,
      isAvailable: !!dayTo && isAvailableRange(from, dayTo.day),
    });
  };

  /** Get the Timeslot from an inputed date */
  const getDay = (day: Date) =>
    allAvailableDays.find(({ day: availableDay }) => isSameDay(day, availableDay));

  /** Apply the logic to define the last day of range on "select" */
  const selectDayTo = (timeslotDay?: TimeslotsDay) => {
    if (!timeslotDay) return;

    const { availabilityTimes, day } = timeslotDay;
    const { minDuration } = getRulesByType(type, availabilityTimes[0].rules) || {};
    const dayTo = !!minDuration ? addDays(day, minDuration - 1) : day;

    if (!dayTo) return timeslotDay;

    return getDay(dayTo);
  };

  const getNextValidDay = (
    timeslotDay?: TimeslotsDay,
    counter: number = 0
  ): TimeslotsDay | undefined => {
    if (!timeslotDay || counter > MAX_SEARCH_DAYS) return;

    const { availabilityTimes, day } = timeslotDay;
    const { minDuration } = getRulesByType(type, availabilityTimes[0].rules) || {};
    const possibleDayTo = minDuration ? addDays(day, minDuration - 1) : day;
    const validRange = isAvailableRange(day, possibleDayTo);

    return !possibleDayTo || validRange
      ? timeslotDay
      : getNextValidDay(allAvailableDays[counter + 1], counter + 1);
  };

  return {
    isBetween,
    validateRange,
    isAvailableRange,
    setOnSelect,
    selectDayTo,
    getDay,
    getNextValidDay,
  };
};

const MAX_SEARCH_DAYS = 60;
