import { useState, useEffect } from 'react';
import type { LanguageMap } from '@guest-widgets/shared/src/i18n/i18n';
import { getAvailableLanguage } from '@guest-widgets/shared/src/hooks/useTranslation';

import { dateLocalesMapper } from '../../i18n/dateLocalesMapper';

export const useCalendarLanguage = (locale: string) => {
  const [calendarLanguage, setCalendarLanguage] = useState<undefined | Locale>(undefined);

  useEffect(() => {
    (async () => {
      const language = getAvailableLanguage(languageMap, locale);
      setCalendarLanguage((await languageMap[language]()).default);
    })();
  }, [locale]);

  return calendarLanguage;
};

// Not supported by date-fns: (en_MT, fil_PH)
const languageMap: LanguageMap = dateLocalesMapper;
