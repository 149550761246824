import React from 'react';
import MuiSkeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { classes } from '@guest-widgets/shared/src/classes';

import { StyledSkeletonContainer } from '../StyledSharedComponents';

export const Skeleton = () => {
  return (
    <StyledSkeletonContainer className={classes.booking.loading}>
      <>
        <Box mt={1} mb={2} mx={6} display="flex" justifyContent="space-between">
          <MuiSkeleton variant="circular" width={32} height={32} />
          <MuiSkeleton variant="circular" width={32} height={32} />
          <MuiSkeleton variant="circular" width={32} height={32} />
        </Box>
        <MuiSkeleton variant="text" height={100} />
        <MuiSkeleton variant="text" height={40} />
        <MuiSkeleton variant="text" width={150} height={40} />
        <MuiSkeleton variant="text" height={40} />
        <MuiSkeleton variant="text" width={150} height={40} />
        <MuiSkeleton variant="text" height={40} />
        <MuiSkeleton variant="text" width={150} height={40} />
        <MuiSkeleton variant="text" height={40} />
        <Box my={8} display="flex" justifyContent="flex-end">
          <MuiSkeleton variant="text" width={150} height={40} />
        </Box>
      </>
    </StyledSkeletonContainer>
  );
};
