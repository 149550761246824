import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography as MuiTypography, Grid } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';
import { Text } from '@eo-locale/react';
import isSameHour from 'date-fns/isSameHour';
import isSameMinute from 'date-fns/isSameMinute';

import type { AvailabilityTime, TimeslotsDay } from '../../../contexts/timeslotsContext/timeslots';
import { getTimeslotStartTime } from '../../../contexts/timeslotsContext/timeslotsContext';

import { Period, groupByPeriod } from './TimePeriod';
import { TimePeriodItem } from './TimeButton';

type TimePeriodListProps = {
  selected?: AvailabilityTime;
  onSelect(availabilityTime: AvailabilityTime): void;
  timeslotsDay?: TimeslotsDay;
};

export const TimePeriodList = ({ timeslotsDay, selected, onSelect }: TimePeriodListProps) => {
  const groupedTimes = groupByPeriod(timeslotsDay?.availabilityTimes);

  const isSameTime = (timeslot1: AvailabilityTime, timeslot2?: AvailabilityTime) => {
    const date1 = getTimeslotStartTime(timeslot1);
    const date2 = !!timeslot2 && getTimeslotStartTime(timeslot2);

    return !!date2 && isSameHour(date1, date2) && isSameMinute(date1, date2);
  };

  const getCssClassByPeriod = (period: Period): string => {
    return period in classes.booking.times.periods ? classes.booking.times.periods[period] : '';
  };

  return (
    <>
      {groupedTimes.map(({ period, times }) => (
        <div key={period}>
          <Typography className={`${classes.booking.times.period} ${getCssClassByPeriod(period)}`}>
            <Text id={period}></Text>
          </Typography>
          <Grid container spacing={4} mb={4} className={classes.booking.times.group}>
            {times.map((time: AvailabilityTime) => {
              const isTimeSelected = isSameTime(time, selected);
              return (
                <Grid item key={getTimeslotStartTime(time).toString()}>
                  <TimePeriodItem
                    isSelected={isTimeSelected}
                    availability={time}
                    onSelect={onSelect}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ))}
    </>
  );
};

const Typography = styled(MuiTypography)(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
}));
