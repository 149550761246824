import { ApiInvalidStructureException } from '../apiContext';
import { createAxiosInstance, unwrap, getApiErrorMessage } from '../axiosApiHelpers';

import type { TimeslotResponse } from './dtos/response';
import type { TimeslotRequest } from './dtos/request';

export const timeslotApi = {
  async getDaysWithTimeslots(
    customerCode: string,
    request: TimeslotRequest
  ): Promise<TimeslotResponse> {
    const axiosInstance = createAxiosInstance(customerCode);

    try {
      const response = await unwrap(
        axiosInstance.post<TimeslotResponse>(`availability?with[]=rules`, request)
      );

      return response;
    } catch (error: unknown) {
      throw new ApiInvalidStructureException(getApiErrorMessage(error));
    }
  },
};
