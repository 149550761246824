import { useWidget } from '@guest-widgets/core/src/widgetContext';
import { useEffect, useRef } from 'react';

import { useRouter } from './contexts/routerContext/routerContext';

export const useScrollToTop = () => {
  const { currentStep, stepBefore } = useRouter();
  const previousCurrentStep = useRef([stepBefore, currentStep].join());
  const { containerElement } = useWidget();
  const isNotFirstRender = stepBefore !== currentStep;
  const stepDidChange = previousCurrentStep.current !== [stepBefore, currentStep].join();

  useEffect(() => {
    if (isNotFirstRender && stepDidChange) {
      containerElement.scrollIntoView({ behavior: 'smooth' });
    }
    previousCurrentStep.current = [stepBefore, currentStep].join();
  }, [currentStep]);
};
