import { LanguageMap } from './i18n';

export const dateLocalesMapper: LanguageMap = {
  'en-US': () => import('date-fns/locale/en-US'),
  'de-DE': () => import('date-fns/locale/de'),
  'fr-FR': () => import('date-fns/locale/fr'),
  'it-IT': () => import('date-fns/locale/it'),
  'es-ES': () => import('date-fns/locale/es'),
  'pt-PT': () => import('date-fns/locale/pt'),
  'nl-NL': () => import('date-fns/locale/nl'),
  'hu-HU': () => import('date-fns/locale/hu'),
  'zh-CN': () => import('date-fns/locale/zh-CN'),
  'ja-JP': () => import('date-fns/locale/ja'),
  'sl-SI': () => import('date-fns/locale/sl'),
  'el-GR': () => import('date-fns/locale/el'),
  'hr-HR': () => import('date-fns/locale/hr'),
  'ca-ES': () => import('date-fns/locale/ca'),
  'ar-AE': () => import('date-fns/locale/ar'),
  'bg-BG': () => import('date-fns/locale/bg'),
  'cs-CZ': () => import('date-fns/locale/cs'),
  'da-DK': () => import('date-fns/locale/da'),
  'de-AT': () => import('date-fns/locale/de-AT'),
  'de-CH': () => import('date-fns/locale/de'),
  'de-LI': () => import('date-fns/locale/de'),
  'de-LU': () => import('date-fns/locale/de'),
  'el-CY': () => import('date-fns/locale/el'),
  'en-AU': () => import('date-fns/locale/en-AU'),
  'en-CA': () => import('date-fns/locale/en-CA'),
  'en-GB': () => import('date-fns/locale/en-GB'),
  'en-IE': () => import('date-fns/locale/en-IE'),
  'en-MT': () => import('date-fns/locale/en-US'),
  'en-NZ': () => import('date-fns/locale/en-NZ'),
  'en-ZA': () => import('date-fns/locale/en-ZA'),
  'es-CR': () => import('date-fns/locale/es'),
  'es-MX': () => import('date-fns/locale/es'),
  'es-PR': () => import('date-fns/locale/es'),
  'et-EE': () => import('date-fns/locale/et'),
  'fi-FI': () => import('date-fns/locale/fi'),
  'fil-PH': () => import('date-fns/locale/en-US'),
  'fr-BE': () => import('date-fns/locale/fr'),
  'fr-CA': () => import('date-fns/locale/fr-CA'),
  'fr-CH': () => import('date-fns/locale/fr-CH'),
  'fr-LU': () => import('date-fns/locale/fr'),
  'he-IL': () => import('date-fns/locale/he'),
  'it-CH': () => import('date-fns/locale/it-CH'),
  'lt-LT': () => import('date-fns/locale/lt'),
  'lv-LV': () => import('date-fns/locale/lv'),
  'ms-MY': () => import('date-fns/locale/ms'),
  'mt-MT': () => import('date-fns/locale/mt'),
  'nl-BE': () => import('date-fns/locale/nl-BE'),
  'nl-LU': () => import('date-fns/locale/nl'),
  'no-NO': () => import('date-fns/locale/nb'),
  'pl-PL': () => import('date-fns/locale/pl'),
  'ro-RO': () => import('date-fns/locale/ro'),
  'sk-SK': () => import('date-fns/locale/sk'),
  'sv-SE': () => import('date-fns/locale/sv'),
  'tr-TR': () => import('date-fns/locale/tr'),
  'zh-Hans-HK': () => import('date-fns/locale/zh-HK'),
  'zh-Hant-HK': () => import('date-fns/locale/zh-HK'),
};
