import { format } from 'date-fns';
import { CompanyLocaleDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { mapMomentToDateFormat } from '@guest-widgets/shared/src/mappers/momentToDateFnsMapper';

import { TimeRange } from '../../../contexts/timeslotsContext/timeslots';
import { defaultProviderLocale } from '../../../contexts/productContext/productContext';

interface TimeslotOptionLabelProps {
  timeRange: TimeRange;
  locale?: Locale;
  showEndTimes?: boolean;
  providerLocale?: CompanyLocaleDto;
}

export const getTimeslotOptionLabel = ({
  timeRange,
  locale,
  showEndTimes,
  providerLocale = defaultProviderLocale,
}: TimeslotOptionLabelProps) => {
  const endTime = showEndTimes
    ? ` - ${format(timeRange.endTime, mapMomentToDateFormat(providerLocale?.timeFormatMoment!), {
        locale,
      })}`
    : '';
  return (
    format(timeRange.startTime, mapMomentToDateFormat(providerLocale?.timeFormatMoment!), {
      locale,
    }) + endTime
  );
};
