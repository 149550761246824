import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import { hasFlag } from 'country-flag-icons';
import { Text } from '@eo-locale/react';

import type { CountrySelectItem } from '../types/CountrySelectItem';
import { config } from '../config';

export type FlagSize = 'small' | 'medium' | 'large';

export type CountryOptionProps = Pick<CountrySelectItem, 'value'> & {
  flagSize: FlagSize;
  label?: string;
} & React.HTMLAttributes<HTMLLIElement>;

const localeHasFlag = (locale: string): boolean => locale === 'CA-ES' || hasFlag(locale);

export const CountryOption = ({ label, value, flagSize, ...props }: CountryOptionProps) => {
  const mapFlagSize: Record<FlagSize, number> = {
    small: 20,
    medium: 24,
    large: 28,
  };

  const containsFlag = localeHasFlag(value);
  return (
    <ItemWithFlag {...props}>
      {containsFlag && (
        <img
          alt={label || value}
          width={mapFlagSize[flagSize]}
          loading="lazy"
          src={`${config.sharedResourcesBaseUrl}/flags/${value}.svg`}
        />
      )}
      {label}
      {!label && !containsFlag && value}
    </ItemWithFlag>
  );
};

// until we have the use case, we don't support: Multiple, DisableClearable and FreeSolo autocomplete options
export interface CountryAutocompleteProps
  extends Omit<
    AutocompleteProps<CountrySelectItem, false, false, false>,
    'renderInput' | 'getOptionLabel' | 'renderOption'
  > {
  flagSize: FlagSize;
  textFieldProps?: TextFieldProps;
}

export const CountryAutocomplete = forwardRef(
  (
    {
      textFieldProps = {},
      flagSize,
      noOptionsText = <Text id="no-country-found" />,
      ...props
    }: CountryAutocompleteProps,
    ref
  ) => (
    <Box position="relative">
      <Autocomplete
        {...props}
        ref={ref}
        noOptionsText={noOptionsText}
        getOptionLabel={({ label }) => label}
        renderOption={(props, country) => (
          <CountryOption {...props} {...country} flagSize={flagSize} />
        )}
        renderInput={(params) => (
          <TextFieldWithEllipsis
            {...params}
            variant="outlined"
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </Box>
  )
);

const ItemWithFlag = styled('li')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1.5),
}));

const TextFieldWithEllipsis = styled(TextField)({
  '& div > input': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
