import { ChangeEvent, useState } from 'react';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';

import { useCart } from '../../contexts/cartContext/cartContext';
import { cartId } from '../../contexts/apiContext/cart/session/useSession';

export const useDiscount = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [localDiscountCode, setLocalDiscountCode] = useState<string | undefined>();

  const [[discountCode]] = useLocalStorage<string[]>(`${cartId}-discounts`, []);

  const { applyDiscountCode, removeDiscountCode } = useCart();

  const handleChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    if (isError) setIsError(false);
    setLocalDiscountCode(e.target.value);
  };

  const applyCode = () => {
    if (!localDiscountCode) return;
    applyDiscountCode.mutateAsync({ discountCode: localDiscountCode! }).then((result) => {
      if (result.discountIsValid) {
        setIsError(false);
        setLocalDiscountCode('');
      } else {
        setIsError(true);
      }
    });
  };

  return {
    applyCode,
    isError,
    localDiscountCode,
    discountCode,
    removeDiscountCode: () => removeDiscountCode.mutate(),
    handleChangeCode,
  };
};
