declare global {
  interface Window {
    google_tag_data?: {
      tidr?: {
        container: object;
      };
    };
    GoogleAnalyticsObject?: string;
    google_tag_manager: {
      tidr?: {
        container: object;
      };
    };
  }
}

export const isGTM = (): boolean => {
  const ids = getGAPropertyIds() || [];
  return ids.some((id) => id.includes('GTM-') || id.includes('GT-'));
};

const getGAPropertyIds = (): string[] | undefined => {
  const tagData =
    window.google_tag_data?.tidr?.container || window.google_tag_manager?.tidr?.container;
  return tagData ? Object.keys(tagData) : undefined;
};
