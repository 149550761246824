import { Button } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';
import { Text } from '@eo-locale/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { NavigationContainer } from '../../StyledSharedComponents';
import { useRouter } from '../../contexts/routerContext/routerContext';

export const Navigation = () => {
  const { setCurrentStep } = useRouter();

  return (
    <NavigationContainer>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ChevronLeftIcon />}
        onClick={() => setCurrentStep('product')}
        fullWidth
        className={classes.booking.buttons.back}
      >
        <Text id="continue-shopping" />
      </Button>
    </NavigationContainer>
  );
};
