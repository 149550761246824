import { FBContents, GA4Items } from '@guest-widgets/shared/src/utils/customerTracking/types';
import { VirtualPageProps } from '@guest-widgets/shared/src/utils/customerTracking/customerTrackingCreator';

import { CheckoutStep, ItemArgs, MappedProduct, ViewItemProps } from './trackingInfo';

export const mapGA4Items = ({
  product,
  itemPrice,
  category,
  qty,
  variant,
  price_incl_tax,
  tax,
}: ItemArgs): GA4Items => {
  const { productId, name, brand } = product;

  return {
    item_id: productId.toString(),
    item_name: name,
    item_brand: brand,
    item_category: category,
    item_variant: variant ?? '',
    quantity: qty,
    price: itemPrice ?? 0,
    tax: tax ?? 0,
    price_incl_tax: price_incl_tax ?? 0,
  };
};

export const mapGA4ViewItems = (product: ViewItemProps): GA4Items => {
  return {
    item_id: product.id,
    item_name: product.name,
    item_brand: product.brand,
    item_category: product.category,
    item_variant: '',
    quantity: 0,
    price: product.price, //TODO not available in product
  };
};
export const mapFBContents = (product: MappedProduct, qty: number): FBContents => {
  const { productId, name } = product;
  return {
    id: productId.toString(),
    name: name,
    quantity: qty,
    item_price: 0, // TODO  should be implemented once default price is calculated (REG-16565)
  };
};

export const virtualPageMapper: Record<
  Exclude<CheckoutStep, 'purchaseFailure' | 'purchaseSuccess'>,
  VirtualPageProps
> = {
  upsell: {
    title: 'Product - Upsell',
    path: '/product/upsell',
  },
  cart: {
    title: 'Checkout - Cart',
    path: '/checkout/cart',
  },
  contact: {
    title: 'Checkout - Contact',
    path: '/checkout/contact',
  },
  checkout: {
    title: 'Checkout - Payment',
    path: '/checkout/payment',
  },
};
