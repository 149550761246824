import { classes } from '@guest-widgets/shared/src/classes';
import { styled } from '@mui/material/styles';

import { useCart } from '../contexts/cartContext/cartContext';
import { Stepper } from '../Stepper';
import { StyledContainer } from '../StyledSharedComponents';
import { Discount } from '../Product/Guest/Discount';
import { Totals } from '../common/Totals';

import { Navigation } from './Navigation';
import { EmptyCart } from './EmptyCart';
import { Items } from './Items/Items';
import { Deposit } from './Deposit/Deposit';
import { useTrackingCart } from '../trackingInfo/useTrackingCart';

export const Cart = () => {
  const { hasItems, cartWithSteps } = useCart();
  const isOptionalDeposit = cartWithSteps?.data?.cart?.totals?.deposits?.every(
    (deposit) => !!deposit.price.amount
  );

  // TODO: Remove this to implement deposit optinal section once the pay option can be passed to API.
  const isImplemented = false;

  useTrackingCart();

  if (!hasItems) return <EmptyCart />;

  return (
    <div className={`${classes.booking.checkout.checkout} ${classes.booking.checkout.cart}`}>
      <Stepper />
      <Container className={classes.booking.cart.cart}>
        <Items />
        <Discount />
        {isImplemented && isOptionalDeposit && <Deposit />}
        <Totals />
      </Container>
      <Navigation />
    </div>
  );
};

const Container = styled(StyledContainer)({
  gap: 0,
});
