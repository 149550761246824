import { Box, Typography } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';
import { Text } from '@eo-locale/react';

import { useTotal } from '../hooks/cart/useTotal';
import TotalRow from '../Cart/Items/TotalRow';

export const Totals = () => {
  const { totalRows } = useTotal();

  return (
    <div className={classes.booking.checkout.totals}>
      <Typography mb={4} color="inherit" variant="h3" mt={4}>
        <Text id="price-details" />
      </Typography>
      <Box className={classes.booking.totals.totals}>
        {totalRows
          .filter((totalRow) => totalRow?.render)
          .map((row) => {
            return row ? <TotalRow key={row.title} {...row} /> : null;
          })}
      </Box>
    </div>
  );
};
