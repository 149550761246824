import { useQuery } from '@tanstack/react-query';
import { productApi } from '@guest-widgets/shared/src/apis/guestExperience';

import { useSettings } from '../../settingsContext/settingsContext';
import { SupportedCurrencies } from '../../settingsContext/settings';
import { queryKeys } from '../../../queryKeys';

import { mapToProduct } from './mapToProduct';

export const useProductApi = () => {
  const { locale, customerCode, currency, isLoading, productId } = useSettings();
  const queryDeps = [queryKeys.PRODUCT, locale, customerCode, productId, currency];
  const hasParameters = Boolean(locale && customerCode && productId && currency && !isLoading);

  const queryFn = async () => {
    const response = await productApi.getProduct(productId, customerCode, locale);

    return mapToProduct(response.price.gross.currencyCode as SupportedCurrencies, response);
  };

  return useQuery({ queryKey: queryDeps, queryFn, enabled: hasParameters });
};
