import { MouseEvent } from 'react';
import { DateRange as DateRangePickerType } from 'react-day-picker';
import { isAfter } from 'date-fns';

import type { TimeslotsDay } from '../../../../../contexts/timeslotsContext/timeslots';
import { useTimeslots } from '../../../../../contexts/timeslotsContext/timeslotsContext';
import { useCalendarNumberOfMonths } from '../useCalendarNumberOfMonths';
import { useAccumulatedTimeslots } from '../useAccumulatedTimeslots';

import { useSelectCalculations } from './useSelectCalculations';
import { useHoverCalculations } from './useHoverCalculations';

interface CalendarProps {
  onSelect: (range: TimeslotDayRange) => void;
  selected?: TimeslotDayRange;
  autoSelect?: boolean;
}

export const useCalendarRange = ({ onSelect, selected, autoSelect }: CalendarProps) => {
  const { timeslot, setFilters, filters } = useTimeslots();
  const allAvailableDays = useAccumulatedTimeslots();
  const numberOfMonths = useCalendarNumberOfMonths();
  const {
    isBetween,
    validateRange,
    isAvailableRange,
    setOnSelect,
    selectDayTo,
    getDay,
    getNextValidDay,
  } = useSelectCalculations({ onSelect });
  const { getSelectedIndex, getRules, getButtonsFromMonth } = useHoverCalculations();
  const firstMonth = timeslot.data?.days?.[0]?.day ?? filters.dateFrom ?? new Date();
  const hasRangeSelected = !!selected?.from?.day && !!selected?.to?.day;

  const handleAutoSelect = () => {
    if (!autoSelect) return;

    if (!allAvailableDays.length) {
      setOnSelect(undefined);
      // Check if loaded timeslots correspond to number of months in calendar
      if (!filters.dateFrom) setFilters({ nextAvailableDay: true });
      return;
    }

    // Check if loaded timeslots correspond to number of months in calendar
    if (firstMonth.getMonth() + numberOfMonths !== (timeslot.data?.range?.to?.getMonth() ?? 0)) {
      setFilters({ dateFrom: allAvailableDays[0]?.day });
    }

    const timeslotFrom = getNextValidDay(allAvailableDays[0]);

    // Check if first available day is in the first month in calendar and jump to it
    if (timeslotFrom?.day.getMonth() !== firstMonth.getMonth()) {
      setFilters({ dateFrom: timeslotFrom?.day });
    }

    setOnSelect({
      from: timeslotFrom?.day,
      to: selectDayTo(timeslotFrom)?.day,
    });
    setFilters({ nextAvailableDay: false });
  };

  const handleSelect = (date: DateRangePickerType | undefined) => {
    const { from, to } = date ?? {};
    const { rules } = selected?.from?.availabilityTimes?.[0] || {};
    const fromLastDayToMinimum = { from: to, to: to && selectDayTo(getDay(to))?.day };
    const fromFirstDayToMinimum = { from, to: from && selectDayTo(getDay(from))?.day };

    if (hasRangeSelected && isBetween(to, selected?.from?.day, selected?.to?.day)) {
      return validateRange(selected.from?.day!, to!, true, rules)
        ? setOnSelect(date)
        : setOnSelect(fromLastDayToMinimum);
    }

    if (hasRangeSelected && isAfter(to!, selected.to?.day!)) {
      return validateRange(selected.from?.day!, to!, false, rules) && isAvailableRange(from, to)
        ? setOnSelect(date)
        : setOnSelect(fromLastDayToMinimum);
    }

    if (hasRangeSelected) {
      return validateRange(from!, selected.to?.day!, false, rules) && isAvailableRange(from, to)
        ? setOnSelect(date)
        : setOnSelect(fromFirstDayToMinimum);
    }

    setOnSelect(date);
  };

  const handleHover = (hoveredDay: Date, event: MouseEvent) => {
    const { currentButton, buttons } = getButtonsFromMonth(event);

    if (!buttons?.length || !selected?.from) return;

    const currentIndex = buttons?.indexOf(currentButton);
    const selectedIndex = getSelectedIndex(hoveredDay, buttons, selected.from, selected.to);
    const start = Math.min(currentIndex, selectedIndex);
    const end = Math.max(currentIndex, selectedIndex);
    const hoveredDays = buttons?.slice(start < 0 ? 0 : start, end + 1);
    const isNotValidRange = !!hoveredDays?.find((button) => button.disabled);

    if (isNotValidRange) {
      hoveredDays.forEach((button) => button.classList.add('rdp-day_invalid_hover'));
      return;
    }

    buttons?.forEach((button, index) => {
      if (index < start || index > end) return;

      const { minDuration, maxDuration } = getRules(button, allAvailableDays) || {};
      const isValidRange =
        (!minDuration || end - start + 1 >= minDuration) &&
        (!maxDuration || end - start + 1 <= maxDuration);

      button.classList.toggle(
        'rdp-day_hover_middle',
        isValidRange && index !== start && index !== end && start !== end
      );
      button.classList.toggle('rdp-day_hover_start', isValidRange && index === start);
      button.classList.toggle('rdp-day_hover_end', isValidRange && index === end && start !== end);
    });
  };

  const handleMouseLeave = (event: MouseEvent) => {
    const { buttons } = getButtonsFromMonth(event);

    buttons?.forEach((item) => {
      item.classList.remove('rdp-day_invalid_hover');
      item.classList.remove('rdp-day_hover_middle');
      item.classList.remove('rdp-day_hover_start');
      item.classList.remove('rdp-day_hover_end');
    });
  };

  return { handleAutoSelect, handleSelect, handleHover, handleMouseLeave };
};

export type TimeslotDayRange = {
  from?: TimeslotsDay;
  to?: TimeslotsDay;
  /** Indicates when date range provided, whether all the days within the range are available */
  isAvailable?: boolean;
  /** Indicates when selection is done by Calendar or Time selectors */
  isCalendarSelection?: boolean;
};
