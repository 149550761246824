export type LanguageCode = keyof typeof namesMapper | 'en';
export type LanguageMap = Record<LanguageCode, () => Promise<any>>;

// Not supported by i18n-iso-countries: (fil, mt)
export const namesMapper = {
  ar: async () => (await import('i18n-iso-countries/langs/ar.json')).default,
  bg: async () => (await import('i18n-iso-countries/langs/bg.json')).default,
  ca: async () => (await import('i18n-iso-countries/langs/ca.json')).default,
  cs: async () => (await import('i18n-iso-countries/langs/cs.json')).default,
  da: async () => (await import('i18n-iso-countries/langs/da.json')).default,
  de: async () => (await import('i18n-iso-countries/langs/de.json')).default,
  el: async () => (await import('i18n-iso-countries/langs/el.json')).default,
  es: async () => (await import('i18n-iso-countries/langs/es.json')).default,
  et: async () => (await import('i18n-iso-countries/langs/et.json')).default,
  fi: async () => (await import('i18n-iso-countries/langs/fi.json')).default,
  fr: async () => (await import('i18n-iso-countries/langs/fr.json')).default,
  he: async () => (await import('i18n-iso-countries/langs/he.json')).default,
  hr: async () => (await import('i18n-iso-countries/langs/hr.json')).default,
  hu: async () => (await import('i18n-iso-countries/langs/hu.json')).default,
  it: async () => (await import('i18n-iso-countries/langs/it.json')).default,
  ja: async () => (await import('i18n-iso-countries/langs/ja.json')).default,
  lt: async () => (await import('i18n-iso-countries/langs/lt.json')).default,
  lv: async () => (await import('i18n-iso-countries/langs/lv.json')).default,
  ms: async () => (await import('i18n-iso-countries/langs/ms.json')).default,
  nl: async () => (await import('i18n-iso-countries/langs/nl.json')).default,
  no: async () => (await import('i18n-iso-countries/langs/no.json')).default,
  pl: async () => (await import('i18n-iso-countries/langs/pl.json')).default,
  pt: async () => (await import('i18n-iso-countries/langs/pt.json')).default,
  ro: async () => (await import('i18n-iso-countries/langs/ro.json')).default,
  sk: async () => (await import('i18n-iso-countries/langs/sk.json')).default,
  sl: async () => (await import('i18n-iso-countries/langs/sl.json')).default,
  sv: async () => (await import('i18n-iso-countries/langs/sv.json')).default,
  tr: async () => (await import('i18n-iso-countries/langs/tr.json')).default,
  zh: async () => (await import('i18n-iso-countries/langs/zh.json')).default,
};
