import { widgetEditorApi } from '@guest-widgets/shared/src/apis/widgetEditor';
import type { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';

import { SettingsApiProps } from './useSettingsApi';

/**It requests in case of existing widgetID widget-editor API, otherwise fallback to default passed values */
export const loadWidgetSettings = async ({ widgetId, ...defaultValues }: SettingsApiProps) => {
  if (!widgetId) return defaultValues;

  const response = (
    await widgetEditorApi.getWidgetConfiguration({
      id: widgetId!,
    })
  ).data;

  return {
    ...response.settings,
    configuration: response.configuration,
    productId: response.settings.productId as string | undefined, // TODO: correct the typing within guest widgets
    locale: (response.settings.locale as unknown) as LanguageLocaleCode,
    customerCode: response.settings.companyCode,
  };
};
