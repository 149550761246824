import { Box, Typography } from '@mui/material';
import InformationIcon from '@mui/icons-material/InfoRounded';
import { Text } from '@eo-locale/react';

export const SameParentQuantity = () => {
  return (
    <Box sx={{ display: 'flex', opacity: 0.6 }} mt={1}>
      <InformationIcon sx={{ fontSize: '1.25em', marginTop: '0.1em' }} />
      <Typography variant="caption" ml={1}>
        <Text id="add-on-quantity-matching-the-number-of-tickets" />
      </Typography>
    </Box>
  );
};
