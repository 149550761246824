import { useQuery } from '@tanstack/react-query';

import type { Settings } from '../../settingsContext/settings';
import { queryKeys } from '../../../queryKeys';

import { loadWidgetSettings } from './loadWidgetSettings';

export type SettingsApiProps = Partial<
  Pick<Settings, 'widgetId' | 'locale' | 'customerCode' | 'productId'>
>;

export const useSettingsApi = ({ widgetId, locale, customerCode, productId }: SettingsApiProps) => {
  const queryDeps = [queryKeys.SETTINGS, widgetId, locale, customerCode, productId];
  const hasParameters = Boolean(widgetId || (locale && customerCode && productId));

  const queryFn = async (): Promise<Partial<Settings>> => {
    const widgetSettings = await loadWidgetSettings({
      widgetId,
      locale,
      customerCode,
      productId,
    });

    return {
      ...widgetSettings,
    };
  };

  return useQuery({ queryKey: queryDeps, queryFn, enabled: hasParameters });
};
