/** Setters and Getters return number because of their incremental nature
 * Generators return string because of type consistency for 'id' 'lineId' properties,
 * and to avoid changes of floatings ending with '0' (e.g. 1.10 becaming 1.1)
 */
const lastCartItemIdKey = 'cart-guest-v1-last-item-id';
const lastUpsellItemIdKey = 'cart-guest-v1-last-upsell-id';

const setLastCartItemId = (id: number) => {
  window.localStorage.setItem(lastCartItemIdKey, id.toString());
};

const setLastUpsellItemId = (id: number) => {
  window.localStorage.setItem(lastUpsellItemIdKey, id.toString());
};

export const getLastCartItemId = (): number => {
  const lastItemId = window.localStorage.getItem(lastCartItemIdKey);
  return lastItemId !== null ? parseInt(lastItemId) : 0;
};

export const getLastUpsellItemId = (): number => {
  const lastUpsellItemId = window.localStorage.getItem(lastUpsellItemIdKey);
  return lastUpsellItemId !== null ? parseInt(lastUpsellItemId) : 0;
};

export const generateCartItemId = (): string => {
  const newLastItemId = getLastCartItemId() + 1;
  setLastCartItemId(newLastItemId);
  return newLastItemId.toString();
};

export const generateUpsellItemId = (parentLineId: string): string => {
  const increasedId = getLastUpsellItemId() + 1;
  const newLastUpsellItemId = `${parentLineId}.${increasedId}`;
  setLastUpsellItemId(increasedId);
  return newLastUpsellItemId;
};

export const resetLastCartItemId = () => {
  window.localStorage.removeItem(lastCartItemIdKey);
};

export const resetLastUpsellItemId = () => {
  window.localStorage.removeItem(lastUpsellItemIdKey);
};
