import { object, string, StringSchema } from 'yup';
import type { TranslationsContextProps } from '@eo-locale/react';

import { minFourChars, minTwoChars, telephoneSchema } from '../fieldValidation';

import { InvoiceFattura, InvoiceFatturaFromItaly, InvoiceNormal } from './invoice';
import type { SupportedInvoices } from './contact';
import { isInvoiceFattura, isInvoiceNormal } from './typeGuards';

/** Schema validation for invoice form */
export const invoiceSchema = (
  translate: translateFn,
  isInvoiceVisible: boolean,
  invoice?: SupportedInvoices
) => {
  if (!invoice || !isInvoiceVisible) return object().shape({});

  const baseSchema = {
    postalCode: string()
      .required(translate('please-provide-your-postal-code'))
      .matches(...minFourChars(translate)),
    street: string().required(translate('please-provide-your-street-and-house-number')),
    city: string().required(translate('please-provide-the-city-name')),
    vatCode: string(),
    country: string().required(translate('please-select-the-country-name')),
    name: string()
      .required(translate('please-fill-name-on-tax-receipt'))
      .matches(...minTwoChars(translate)),
  };

  const requiredField = string().required(translate('this-is-required-field'));

  if (isInvoiceNormal(invoice)) {
    return object().shape<Record<keyof InvoiceNormal, StringSchema>>({
      ...baseSchema,
      company: string(),
    });
  }

  if (isInvoiceFattura(invoice)) {
    const invoiceFromItaly = (country: string) => country === 'IT';

    return object().shape<
      Record<keyof InvoiceFattura | keyof InvoiceFatturaFromItaly, StringSchema>
    >({
      ...baseSchema,
      telephone: string().test(...telephoneSchema(translate)),
      region: requiredField,
      vatCode: invoice.type === 'company' ? requiredField : baseSchema.vatCode,
      destinationCode: string(),
      type: requiredField,
      fiscalCode: string().when('country', {
        is: invoiceFromItaly,
        then: string().when('type', {
          is: (type: InvoiceFattura['type']) => type === 'company',
          then: string(),
          otherwise: requiredField,
        }),
      }),
      pec: string(),
    });
  }
  return object().shape({});
};

type translateFn = TranslationsContextProps['translator']['translate'];
