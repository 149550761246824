import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { parseISO, isAfter, isBefore } from 'date-fns';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';

import { useProduct } from '../../productContext/productContext';
import { useSettings } from '../../settingsContext/settingsContext';
import { cartId, useSession } from '../cart/session/useSession';
import { CartItem } from '../cart/cart';
import { queryKeys } from '../../../queryKeys';

import { guestApi } from './guestApi';
import { mapToPricing, mapToRequestItems } from './mapToPricing';
import { Quantity } from './dtos/request';
import { useGuestTypes } from './useGuestTypes';
import type { Pricing } from './product';

interface PricingApiProps {
  experience: CartItem;
  quantity?: Quantity;
  isDateTimeSelectionComplete: boolean;
}

export const usePricingApi = ({
  experience,
  quantity,
  isDateTimeSelectionComplete,
}: PricingApiProps): UseQueryResult<Pricing> => {
  const [discountCodes] = useLocalStorage(`${cartId}-discounts`, []);
  const { locale, customerCode, isLoading } = useSettings();
  const { cartState, setErrors, getLastItemId } = useSession();
  const { guestTypes: productGuestTypes } = useProduct()!;
  const {
    guestTypes,
    start,
    end,
    product: { productId },
    discount,
  } = experience;

  const guestTypesWithQuantity = useGuestTypes({ guestTypes: productGuestTypes });
  const quantities = Object.values(quantity ?? {}).reduce((a, b) => `${a}${b}`, '');
  const queryDeps = [queryKeys.PRICING, productId, quantities, start, end, discount?.code];

  const hasParameters = Boolean(
    locale && customerCode && productId && isDateTimeSelectionComplete && !isLoading
  );

  const queryFn = async () => {
    const checkedItemId = (getLastItemId() + 1).toString();

    const response = await guestApi.getPricing(customerCode, {
      discountCodes,
      lineItems: [
        ...mapToRequestItems(getSimilarItems(cartState.items, experience)),
        {
          lineId: checkedItemId,
          productId: Number(productId),
          start,
          end,
          guestTypes: quantity ?? guestTypes,
        },
      ],
    });
    const { lineItems } = response.data;
    const error = lineItems.find((item) => item.lineId === checkedItemId)?.error?.message;
    setErrors(error ? [error] : [], response.data.isValid);
    return mapToPricing(guestTypesWithQuantity, response, quantity);
  };

  return useQuery({
    queryKey: queryDeps,
    queryFn,
    enabled: quantity ? hasParameters && !!quantity : hasParameters,
  });
};

const getSimilarItems = (cartStateItems: CartItem[], experience: CartItem) => {
  const experienceStart = parseISO(experience.start);
  const experienceEnd = parseISO(experience.end);

  return cartStateItems.filter((item: CartItem) => {
    if (item.product.productId !== experience.product.productId) return false;

    const itemStart = parseISO(item.start);
    const itemEnd = parseISO(item.end);

    return isBefore(itemStart, experienceEnd) && isAfter(itemEnd, experienceStart);
  });
};
