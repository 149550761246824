import type {
  DetailProductDto,
  ReviewListDto,
  ProductListDto,
  CrossSellingProductDto,
  FilterAttributeDto,
  LocationSuggestionsDto,
  ProductApiFindAllRequest,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import axios, { AxiosResponse } from 'axios';

import { LanguageLocaleCode } from '../i18n/i18n';

import { createHeaders } from './createHeaders';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_GUEST_EXPERIENCE_URL + '/api/v1',
});

export type GetProductsParams = Omit<
  ProductApiFindAllRequest,
  'xTenant' | 'xApplicationId' | 'xPartnerCode' | 'xLocale'
>;

export const productApi = {
  getProducts(customerCode: string, locale: LanguageLocaleCode, params?: GetProductsParams) {
    const headers = createHeaders(customerCode, locale);
    return unwrap(
      axiosInstance.get<ProductListDto>('products', {
        headers,
        params: {
          ...params,
        },
      })
    );
  },

  getProduct(id: string, customerCode: string, locale: LanguageLocaleCode) {
    const headers = createHeaders(customerCode, locale);
    return unwrap(
      axiosInstance.get<DetailProductDto>(`products/${id}`, {
        headers,
      })
    );
  },

  getReviews(
    id: string,
    customerCode: string,
    locale: LanguageLocaleCode,
    params?: { limit?: number; offset?: number }
  ) {
    const headers = createHeaders(customerCode, locale);
    return unwrap(
      axiosInstance.get<ReviewListDto>(`products/${id}/reviews`, { headers, params })
    );
  },

  getCrossSelling(id: string, customerCode: string, locale: LanguageLocaleCode) {
    const headers = createHeaders(customerCode, locale);
    return unwrap(
      axiosInstance.get<CrossSellingProductDto[]>(`products/${id}/cross-selling`, { headers })
    );
  },

  getFilterAttributes(
    customerCode: string,
    locale: LanguageLocaleCode,
    params?: GetProductsParams
  ) {
    const headers = createHeaders(customerCode, locale);
    return unwrap(
      axiosInstance.get<FilterAttributeDto[]>(`products/filter-attributes`, {
        headers,
        params,
      })
    );
  },
};

export const locationApi = {
  getSuggestedLocation(customerCode: string, locale: LanguageLocaleCode, keyword: string) {
    const headers = createHeaders(customerCode, locale);
    return unwrap(
      axiosInstance.get<LocationSuggestionsDto>(`locations/suggest`, {
        headers,
        params: {
          keyword,
        },
      })
    );
  },
};

export const regionApi = {
  getRegions(countryCode: string) {
    return unwrap(
      axiosInstance.get<string[]>(`locations/regions`, { params: { countryCode } })
    );
  },
};

export const unwrap = async <T>(responsePromise: Promise<AxiosResponse<T>>): Promise<T> =>
  (await responsePromise).data;
