import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { usePrevious } from '@guest-widgets/shared/src/hooks/usePrevious';

import { Step } from './router';
import { useRouteFromQueryString } from './useRouteFromQueryString';

const initialSteps: Array<Step> = [
  'product',
  'upsell',
  'contact',
  'cart',
  'checkout',
  'purchaseFailure',
  'purchaseSuccess',
];

const initialValue: RouterContext = {
  currentStep: 'product',
  steps: initialSteps,
  setSteps: () => undefined,
  setCurrentStep: () => undefined,
  nextStep: () => undefined,
  previousStep: () => undefined,
};

const routerContext = createContext(initialValue);

export interface RouterContext {
  currentStep: Step;
  stepBefore?: Step;
  steps: Array<Step>;
  setSteps: (steps: Array<Step>) => void;
  setCurrentStep: (step: Step) => void;
  nextStep: () => void;
  previousStep: () => void;
}

export interface RouterContextProviderProps {
  initialState?: Partial<Pick<RouterContext, 'currentStep' | 'steps'>>;
}

export const RouterContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<RouterContextProviderProps>) => {
  const [steps, setSteps] = useState(initialState?.steps || initialSteps);
  const queryRoute = useRouteFromQueryString(steps);
  const isUpsellImplemented = import.meta.env.VITE_SHOW_UPSELLS === 'true';

  const [currentStep, setCurrentStep] = useState(
    queryRoute || initialState?.currentStep || steps[0]
  );
  const stepBefore = usePrevious<Step>(currentStep);

  useEffect(() => {
    if (queryRoute) {
      setCurrentStep(queryRoute);
    }
  }, [queryRoute]);

  const changeStep = (direction: 'next' | 'previous') => {
    const factor = direction === 'next' ? 1 : -1;
    setCurrentStep((prev) => steps[steps.indexOf(prev) + factor]);
  };

  const value: RouterContext = {
    currentStep,
    stepBefore,
    steps,
    setSteps,
    setCurrentStep,
    nextStep: () =>
      !isUpsellImplemented && currentStep === 'product'
        ? setCurrentStep('contact')
        : changeStep('next'),
    previousStep: () =>
      !isUpsellImplemented && currentStep === 'contact'
        ? setCurrentStep('product')
        : changeStep('previous'),
  };

  return <routerContext.Provider value={value}>{children}</routerContext.Provider>;
};

export const useRouter = () => useContext(routerContext);
