import { Typography, Grid } from '@mui/material';
import { styled } from '@guest-widgets/core';

import { CartItem } from '../../contexts/apiContext/cart/cart';

import { ItemInfo } from './ItemInfo';
import { ItemRemoved } from './ItemRemoved';

export interface ItemProps {
  cartItem: CartItem;
}

export const Item = ({ cartItem }: ItemProps) => {
  return cartItem.isAvailable ? (
    <>
      <Grid item xs={12}>
        <Info>
          <ItemInfo item={cartItem} />
        </Info>
      </Grid>
    </>
  ) : (
    <ItemRemoved cartItem={cartItem} />
  );
};

const Info = styled(Typography)(({ theme: { spacing, palette } }) => ({
  paddingLeft: 0,
  borderRadius: spacing(2),
  border: `1px solid ${palette.grey[400]}`,
  padding: spacing(2),
}));
