import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useWidget } from '@guest-widgets/core';
import uniqueId from 'lodash/uniqueId';
import { styled } from '@mui/material/styles';
import { getOutterShadowHost } from '@guest-widgets/shared/src/utils/getOutterShadowHost';

import { Stepper } from '../../Stepper';
import { StyledContainer } from '../../StyledSharedComponents';

import { Navigation } from './Navigation';

interface NppCheckoutAttributes {
  paymentRequesterReference?: string;
  checkoutConfiguration?: string;
  customerDetails?: string;
  squareCustomConfiguration?: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'npp-checkout': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> &
        NppCheckoutAttributes;
    }
  }
}

type SuccessEvent = Event & {
  detail: {
    paymentReference: string;
    paymentRequesterReference: string;
  };
};

type RedirectEvent = Event & {
  detail: {
    paymentReference: string;
    paymentStatus: string;
    redirectLink: string;
  };
};

// todo Add support of different Rezdy environments
const addRezdyCheckoutScript = (callback: () => void) => {
  if (window.customElements.get('npp-checkout')) {
    callback();
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('src', `${import.meta.env.VITE_REZDY_CHECKOUT_URL}/checkout.min.js`);
  script.addEventListener('load', callback);
  document.body.appendChild(script);
};

export interface PaymentProps {
  paymentId: string;
  successHandler: () => void;
  redirectHandler: (url: string) => void;
  failureHandler: () => void;
}

export const Payment = ({
  paymentId,
  successHandler,
  failureHandler,
  redirectHandler,
}: PaymentProps) => {
  const { containerElement } = useWidget();

  const [initialized, setInitialized] = useState(false);

  const outerShadowHost = useMemo<Node>(() => getOutterShadowHost(containerElement), [
    containerElement,
  ]);

  useEffect(() => {
    addRezdyCheckoutScript(() => setInitialized(true));
  }, []);

  const onPaymentSuccess: EventListener = (e) => {
    if ((e as SuccessEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    successHandler();
  };

  const onPaymentRedirect: EventListener = (e) => {
    redirectHandler((e as RedirectEvent).detail.redirectLink);
  };

  const onPaymentFailure: EventListener = () => {
    failureHandler();
  };

  useEffect(() => {
    document.addEventListener('npp-payment-success', onPaymentSuccess);
    document.addEventListener('npp-redirect-payment', onPaymentRedirect);

    //todo These events present in documentation and examples, but aren't reachable when testing failed payments
    document.addEventListener('npp-payment-cancellation', onPaymentFailure);
    document.addEventListener('npp-customer-details-validation-failure', onPaymentFailure);

    return () => {
      document.removeEventListener('npp-payment-success', onPaymentSuccess);
      document.removeEventListener('npp-redirect-payment', onPaymentRedirect);
      document.removeEventListener('npp-payment-cancellation', onPaymentFailure);
      document.removeEventListener('npp-customer-details-validation-failure', onPaymentFailure);
    };
  }, []);

  const slotName = uniqueId('npp-checkout');

  return (
    <div>
      <Stepper />
      {initialized && (
        <Container>
          {createPortal(
            <div slot={slotName}>
              <npp-checkout paymentRequesterReference={paymentId}></npp-checkout>
            </div>,
            outerShadowHost as Element
          )}
          <slot name={slotName}></slot>
        </Container>
      )}
      <Navigation />
    </div>
  );
};

const Container = styled(StyledContainer)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
  marginBottom: spacing(2),
}));
