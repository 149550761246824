import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NoImageIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9375 45.933C7.9375 54.8443 11.4775 63.3906 17.7787 69.6918C24.0799 75.993 32.6262 79.533 41.5375 79.533C50.4488 79.533 58.9951 75.993 65.2963 69.6918C71.5975 63.3906 75.1375 54.8443 75.1375 45.933C75.1375 37.0217 71.5975 28.4754 65.2963 22.1742C58.9951 15.873 50.4488 12.333 41.5375 12.333C32.6262 12.333 24.0799 15.873 17.7787 22.1742C11.4775 28.4754 7.9375 37.0217 7.9375 45.933Z"
        fill="white"
      />
      <path
        d="M41.5388 79.5324C47.945 79.5334 54.218 77.7033 59.6187 74.2578C65.0194 70.8122 69.3227 65.8947 72.0216 60.0847C61.5905 51.4327 47.2844 52.1736 37.5135 62.3662L25.1445 75.2677C30.1527 78.0728 35.7986 79.5414 41.5388 79.5324Z"
        fill="#E2E8F0"
      />
      <path
        d="M72.0212 60.0847C61.5901 51.4327 47.284 52.1736 37.5131 62.3662L27.2441 73.0795"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.201 65.6535C30.6918 58.863 20.8957 58.9193 14.4428 65.7829L14.4336 65.793C18.3746 71.1631 23.8205 75.243 30.0818 77.5159C36.343 79.7888 43.138 80.1525 49.6061 78.561L37.201 65.6535Z"
        fill="#E2E8F0"
      />
      <path
        d="M49.6061 78.5576L37.201 65.6535C30.6918 58.863 20.8957 58.9193 14.4428 65.7829L14.4336 65.793"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7964 55.8064C23.7964 57.3948 22.9362 58.6825 21.8753 58.6825C20.8144 58.6825 19.9551 57.3948 19.9551 55.8064C19.9551 54.6749 20.9278 52.2204 21.4889 50.8999C21.5212 50.8242 21.575 50.7596 21.6437 50.7141C21.7124 50.6687 21.793 50.6445 21.8753 50.6445C21.9577 50.6445 22.0382 50.6687 22.1069 50.7141C22.1756 50.7596 22.2294 50.8242 22.2617 50.8999C22.8228 52.2204 23.7964 54.6757 23.7964 55.8064Z"
        fill="#E2E8F0"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.875 58.6826V61.169"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7152 55.8064C31.7152 57.3948 30.855 58.6825 29.7941 58.6825C28.7332 58.6825 27.873 57.3948 27.873 55.8064C27.873 54.6757 28.8466 52.2204 29.4077 50.8999C29.44 50.8242 29.4938 50.7596 29.5625 50.7141C29.6312 50.6687 29.7118 50.6445 29.7941 50.6445C29.8765 50.6445 29.957 50.6687 30.0257 50.7141C30.0944 50.7596 30.1483 50.8242 30.1805 50.8999C30.745 52.2204 31.7152 54.6749 31.7152 55.8064Z"
        fill="#E2E8F0"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.793 58.6826V61.1304"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.8472 47.5324C64.8472 49.7164 63.987 51.4913 62.9269 51.4913C61.8669 51.4913 61.0059 49.7189 61.0059 47.5324C61.0059 45.9893 61.9618 42.6604 62.5254 40.8267C62.552 40.7411 62.6053 40.6662 62.6774 40.6131C62.7496 40.5599 62.8369 40.5312 62.9265 40.5312C63.0161 40.5312 63.1034 40.5599 63.1756 40.6131C63.2478 40.6662 63.301 40.7411 63.3276 40.8267C63.8913 42.6554 64.8472 45.9893 64.8472 47.5324Z"
        fill="#E2E8F0"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.9277 51.4912V55.1418"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.808 44.2682C17.808 43.7335 17.5956 43.2207 17.2176 42.8427C16.8395 42.4646 16.3267 42.2522 15.792 42.2522C15.6794 42.2526 15.567 42.2627 15.456 42.2824C15.3539 42.3006 15.2487 42.2804 15.1606 42.2257C15.0725 42.1709 15.0078 42.0855 14.9789 41.9859C14.7783 41.288 14.3561 40.6743 13.776 40.2375C13.196 39.8007 12.4895 39.5645 11.7634 39.5645C11.0372 39.5645 10.3308 39.8007 9.75074 40.2375C9.17068 40.6743 8.74847 41.288 8.54787 41.9859C8.51899 42.0855 8.45427 42.1709 8.36618 42.2257C8.27809 42.2804 8.17286 42.3006 8.07075 42.2824C7.95983 42.2627 7.84741 42.2526 7.73475 42.2522C7.20007 42.2522 6.6873 42.4646 6.30922 42.8427C5.93115 43.2207 5.71875 43.7335 5.71875 44.2682C5.71875 44.8029 5.93115 45.3156 6.30922 45.6937C6.6873 46.0718 7.20007 46.2842 7.73475 46.2842H15.7987C16.3323 46.2824 16.8433 46.0692 17.2199 45.6913C17.5966 45.3135 17.808 44.8017 17.808 44.2682Z"
        fill="white"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.2885 29.9879C78.2885 29.4532 78.0761 28.9405 77.698 28.5624C77.32 28.1843 76.8072 27.9719 76.2725 27.9719C76.1598 27.9723 76.0474 27.9824 75.9365 28.0021C75.8344 28.0204 75.7292 28.0001 75.6411 27.9454C75.553 27.8907 75.4883 27.8052 75.4594 27.7056C75.2588 27.0077 74.8366 26.3941 74.2565 25.9572C73.6764 25.5204 72.97 25.2842 72.2439 25.2842C71.5177 25.2842 70.8113 25.5204 70.2312 25.9572C69.6511 26.3941 69.2289 27.0077 69.0283 27.7056C68.9993 27.8051 68.9345 27.8903 68.8464 27.9449C68.7584 27.9995 68.6532 28.0196 68.5512 28.0013C68.4403 27.9816 68.3279 27.9715 68.2152 27.9711C67.6805 27.9711 67.1678 28.1835 66.7897 28.5615C66.4116 28.9396 66.1992 29.4524 66.1992 29.9871C66.1992 30.5217 66.4116 31.0345 66.7897 31.4126C67.1678 31.7907 67.6805 32.0031 68.2152 32.0031H76.2792C76.8126 32.0013 77.3235 31.7882 77.7001 31.4105C78.0767 31.0328 78.2883 30.5213 78.2885 29.9879Z"
        fill="white"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3379 23.7171C16.3379 24.9672 16.8345 26.166 17.7184 27.0499C18.6023 27.9338 19.8011 28.4304 21.0511 28.4304C22.3012 28.4304 23.5 27.9338 24.3839 27.0499C25.2678 26.166 25.7644 24.9672 25.7644 23.7171C25.7644 22.4671 25.2678 21.2683 24.3839 20.3844C23.5 19.5005 22.3012 19.0039 21.0511 19.0039C19.8011 19.0039 18.6023 19.5005 17.7184 20.3844C16.8345 21.2683 16.3379 22.4671 16.3379 23.7171Z"
        fill="white"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0527 17.1188V15.7051"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7168 19.0523L26.7164 18.0527"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6504 23.7178H29.0641"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7168 28.3838L26.7164 29.3834"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0527 30.3164V31.7301"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3872 28.3838L15.3867 29.3834"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4547 23.7178H13.041"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3872 19.0523L15.3867 18.0527"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6928 46.503C51.6928 47.039 51.4798 47.553 51.1008 47.9321C50.7218 48.3111 50.2078 48.524 49.6717 48.524H46.6402C46.1042 48.524 45.5901 48.3111 45.2111 47.9321C44.8321 47.553 44.6191 47.039 44.6191 46.503V41.9561H51.6928V46.503Z"
        fill="#E2E8F0"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1035 41.9561H53.2079"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6913 41.9563L52.8589 38.4199H43.4492L44.6177 41.9563H51.6913Z"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7923 20.2311C51.6436 26.3715 50.7893 32.4744 49.2462 38.4196H47.0622C45.5202 32.4743 44.6671 26.3713 44.5195 20.2311C44.5195 10.1906 45.9988 4.4668 48.1576 4.4668C50.3164 4.4668 51.7923 10.1906 51.7923 20.2311Z"
        fill="#E2E8F0"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.6217 4.4794C47.7897 4.4668 47.9745 4.4668 48.156 4.4668C45.9972 4.4668 44.5204 10.1906 44.5204 20.2311C44.6691 26.3715 45.5234 32.4744 47.0665 38.4196H45.2571C42.3961 34.4666 38.4531 26.6327 38.4531 20.2311C38.4548 10.5056 42.1416 4.83052 47.6217 4.4794Z"
        fill="white"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2572 38.4197H43.4503C38.8085 34.4666 32.3926 26.6328 32.3926 20.2312C32.3926 10.3847 38.5397 4.68529 47.6218 4.47949C42.1416 4.83061 38.4549 10.5057 38.4549 20.2312C38.4549 26.6328 42.3961 34.4666 45.2572 38.4197Z"
        fill="#E2E8F0"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6877 4.4794C48.5197 4.4668 48.3357 4.4668 48.1543 4.4668C50.3123 4.4668 51.7915 10.1906 51.7915 20.2311C51.6429 26.3715 50.7886 32.4744 49.2455 38.4196H51.0523C53.9142 34.4666 57.8563 26.6327 57.8563 20.2311C57.8546 10.5056 54.1687 4.83052 48.6877 4.4794Z"
        fill="white"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.0541 38.4197H52.8609C57.5044 34.4667 63.9195 26.6328 63.9195 20.2312C63.9195 10.3847 57.7715 4.68529 48.6895 4.47949C54.1705 4.83061 57.8564 10.5057 57.8564 20.2312C57.8564 26.6328 53.9159 34.4667 51.0541 38.4197Z"
        fill="#E2E8F0"
        stroke="#8999AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default NoImageIcon;
