import React from 'react';
import { styled } from '@mui/material/styles';

type LineClampProps = {
  lines: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const LineClamp = styled(({ lines, ...props }: LineClampProps) => <div {...props} />)(
  ({ lines, theme }) => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  })
);
