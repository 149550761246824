import { useEffect, useState } from 'react';
import { ImageDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { customerTracking } from '@guest-widgets/shared/src/utils/customerTracking/customerTracking';

import { useCart } from '../../contexts/cartContext/cartContext';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import { useUpsellInSelection } from '../../Upsell/upsellInSelectionContext';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { useProduct } from '../../contexts/productContext/productContext';
import { getItemSimilarityKey } from '../../contexts/apiContext/cart/session/addToCart';
import { getAddToCart } from '../../trackingInfo/cartHelpers';

export const useInnerUpsell = () => {
  const [addToCartErrors, setAddToCartErrors] = useState<string[]>([]);
  const { addToCart } = useCart();
  const { nextStep } = useRouter();
  const { companyInformation, currency } = useProduct();
  const { upsells, setUpsells } = useUpsell();
  const { upsellApi, upsellItem } = useUpsellInSelection();

  const isSelectedUpsell = (item: UpsellItem) =>
    item.product.productId === upsellItem?.product.productId;

  useEffect(() => {
    const upsellsFromApi = upsellApi.data;

    if (!upsellsFromApi || !upsellApi.isSuccess || upsellApi.isFetching) return;

    const newUpsells = upsells.map((item) => {
      const newUpsell = upsellsFromApi.upsells.find((upsellItem) => item.id === upsellItem.id);
      return !!newUpsell ? newUpsell : item;
    });
    setUpsells(newUpsells);
  }, [upsellApi.isSuccess, upsellApi.isFetching]);

  useEffect(() => {
    const hasUpsellToAdd = upsells.some((upsell) => upsell.totalQuantity);
    const addedItemError = addToCart.data?.addedItemError;
    setAddToCartErrors(addedItemError ? [addedItemError] : []);

    if (addToCart.isSuccess && !addedItemError && hasUpsellToAdd) {
      nextStep();
      addToCart.reset();
    }
  }, [addToCart.isSuccess]);

  const addToCartHandler = () => {
    if (!upsells.length) return;

    const cartUpsells = upsells.reduce((acc, upsell) => {
      if (upsell.totalQuantity) return [...acc, createCartItem(upsell)];
      return acc;
    }, [] as CartItem[]);

    if (!cartUpsells.length) return nextStep();

    addToCart.mutateAsync(cartUpsells).then(({ cartWithSteps }) => {
      cartUpsells.forEach((cartUpsell) => {
        const targetedItem = cartWithSteps.cart.items.find(
          (item) => getItemSimilarityKey(item) === getItemSimilarityKey(cartUpsell)
        );
        const itemToSend = { ...targetedItem!, guestTypes: cartUpsell.guestTypes };
        const addedItem = getAddToCart(itemToSend, currency);
        if (addedItem) customerTracking().addToCart?.(addedItem);
      });
    });
  };

  const createCartItem = (upsellItem: UpsellItem): CartItem => ({
    id: upsellItem.id!,
    start: upsellItem.parent.start,
    end: upsellItem.parent.end,
    product: {
      name: upsellItem.product.name,
      productId: upsellItem.product.productId,
      allocationType: upsellItem.product.allocationType,
      brand: companyInformation?.name || '',
      image: upsellItem.product.image as ImageDto,
    },
    guestTypes: upsellItem.guestTypes,
    price: {
      total: {
        amount: upsellItem.price.total.amount || 0,
        currencyCode: upsellItem.price.total.currencyCode,
      },
    },
    totalQuantity: upsellItem.totalQuantity,
  });

  return {
    addToCartHandler,
    isSelectedUpsell,
    upsellStatus: upsellApi,
    addToCartErrors,
  };
};
