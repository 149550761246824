import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { useWidget } from '@guest-widgets/core';
import { booking } from '@guest-widgets/shared/src/classes/booking';

export const WidgetContainer = ({ children }: PropsWithChildren<{}>) => {
  const { containerElement, widgetArea } = useWidget();

  const bookingWidth = containerElement.clientWidth;
  const screenWidth = document.documentElement.clientWidth;

  return (
    <Box
      px={bookingWidth === screenWidth ? widgetArea.basedOnSize(4, 6, 8) : 0}
      className={booking.widget}
    >
      {children}
    </Box>
  );
};
