import React from 'react';
import { TextField as MuiTextField } from '@mui/material';

import { BasicField as BasicFieldType } from '../../contexts/contactContext/contact';
import { useContact } from '../../contexts/contactContext/contactFormContext';

export type TextAreaViewViewProps = {
  field: BasicFieldType;
};

export const TextAreaView = ({
  field: { required, label, id, tip, value, hidden },
}: TextAreaViewViewProps) => {
  const {
    form: { formState, getValues, setValue },
  } = useContact();

  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message || tip;

  if (hidden) return null;

  return (
    <MuiTextField
      multiline
      minRows={3}
      error={error}
      helperText={helperText}
      variant="outlined"
      required={required}
      label={label}
      value={getValues(`contact.${id}`)}
      defaultValue={value}
      onChange={(e) => setValue(`contact.${id}`, e.target.value, { shouldValidate: true })}
    />
  );
};
