import React, { forwardRef } from 'react';
import { Box, Divider, InputAdornment, TextField } from '@mui/material';
import { styled } from '@guest-widgets/core';

import { SelectCountry } from './SelectCountry';

export const PhoneElement = forwardRef(
  (
    { onCountryChange, countryValue, countryNamesLanguage, flagSize, ...props }: any,
    ref: React.Ref<any>
  ) => (
    <StyledTextField
      {...props}
      inputRef={ref}
      fullWidth
      variant="outlined"
      InputProps={{
        startAdornment: (
          <AdornmentContainer>
            <StyledInputAdornment position="start">
              <SelectCountry
                value={countryValue}
                onChange={onCountryChange}
                size={props.size}
                flagSize={flagSize}
                language={countryNamesLanguage}
              />
            </StyledInputAdornment>
            <Divider orientation="vertical" flexItem />
          </AdornmentContainer>
        ),
      }}
    />
  )
);

const StyledTextField = styled(TextField)({
  '& > div': {
    alignItems: 'stretch',
  },
  '& input': {
    paddingLeft: 16,
  },
});

const AdornmentContainer = styled(Box)({
  display: 'flex',
  alignItems: 'stretch',
  '& > hr': {
    borderStyle: 'solid',
    borderWidth: 1,
  },
});

const StyledInputAdornment = styled(InputAdornment)({
  maxHeight: 'none',
  height: 'unset',
  '& > div > div': {
    display: 'flex',
    marginRight: 8,
  },
  '& > div:before': {
    border: 'none',
  },
});
