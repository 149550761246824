import type { GuestRate } from '../../../Product/Guest/productInSelectionContext';
import type { CartItem } from '../cart/cart';

import type { Quantity } from './dtos/request';
import type { Pricing, PricingError, ErrorType } from './product';
import type { GuestTypeWithQuantity } from './useGuestTypes';
import { ErrorContext, ErrorResponse, ValidateResponse } from './dtos/response';

export const mapToPricing = (
  guestTypesWithQuantity: GuestTypeWithQuantity[],
  response: ValidateResponse,
  quantity?: Quantity
): Pricing => {
  const { lineItems, isValid } = response?.data || {};
  const lineItem = lineItems?.pop();

  if (!lineItem) return {} as Pricing;

  const guestMapRates = lineItem.pricing?.baseRates.guestRates;
  const productRate = lineItem.pricing?.baseRates.productRate;

  const guestRates = guestTypesWithQuantity.map((guestType) => {
    const rate = guestType.id === 'qty' ? productRate : guestMapRates?.[guestType.id];

    return {
      ...guestType,
      rate,
    };
  });

  return {
    guestRates,
    guestTypes: lineItem?.guestTypes,
    total: lineItem?.pricing?.subTotal || 0,
    error: isValidationNeeded(lineItem.error?.context, quantity)
      ? mapToError(lineItem.error)
      : undefined,
    isValid,
  };
};

export const mapToQuantity = (guestTypes: GuestTypeWithQuantity[]): Quantity => {
  return guestTypes.reduce((acc, current) => {
    return { ...acc, [current.id]: current.quantity };
  }, {} as Quantity);
};

export const mapToInitialQuantity = (guestRates?: GuestRate[]): Quantity => {
  if (!guestRates) return {} as Quantity;
  return guestRates.reduce((acc, current) => {
    return { ...acc, [current.id]: 0 };
  }, {} as Quantity);
};

export const mapToRequestItems = (items: CartItem[]) => {
  if (!items.length) return [];
  return items.map((item) => ({
    lineId: item.id,
    productId: Number(item.product.productId),
    start: item.start,
    end: item.end,
    guestTypes: item.guestTypes,
    ...(item.discount ? { discountCodes: [item.discount.code] } : {}),
  }));
};

export const mapToError = (error?: ErrorResponse): PricingError | undefined => {
  if (!error) return;
  return {
    errorType: getErrorType(error),
    message: error?.message || '',
  };
};

export const isValidationNeeded = (context?: ErrorContext, quantity?: Quantity): boolean => {
  if (!context) return false;

  const { guestMin } = context;
  if (guestMin && !quantity) return false;

  return true;
};

const getErrorType = (error: ErrorResponse): ErrorType => {
  const { durationUnit } = error.context;
  if (mapErrorType[error.code] === 'dateOrTime') {
    return durationUnit ? (durationUnit === 'days' ? 'date' : 'time') : 'date';
  }

  return 'quantity';
};

const mapErrorType: Record<number, string> = {
  200: 'quantity',
  201: 'quantity',
  202: 'quantity',
  203: 'quantity',
  204: 'quantity',
  205: 'quantity',
  400: 'dateOrTime',
  401: 'dateOrTime',
  402: 'dateOrTime',
  500: 'dateOrTime',
  600: 'dateOrTime',
};
