import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { useProductApi } from '../apiContext/product/useProductApi';
import { customerCodeToUrlRegistry } from '../apiContext/axiosApiHelpers';
import { useSettings } from '../settingsContext/settingsContext';
import { useLoadingError } from '../../hooks/useLoadingError';

import { GuestProduct } from './product';

const productData = {} as GuestProduct;

const initialState: ProductContext = {
  ...productData,
  sellAsGift: false,
  setSellAsGift: () => undefined,
  viewedProduct: null,
  setViewedProduct: () => undefined,
};

const productContext = createContext<ProductContext>(initialState);

export interface ProductContext extends GuestProduct {
  sellAsGift?: boolean;
  viewedProduct: string | null;
  setViewedProduct: (item: string) => void;
  setSellAsGift: (value: boolean) => void;
}

export interface ProductContextProviderProps {
  initialState?: GuestProduct;
}
// default company locale utilized when the provider locale is not provided by the API.
// This value is arbitrary and can be changed if necessary.
export const defaultProviderLocale = {
  id: 'en_US',
  language: 'en',
  timezone: 'Canada/Central',
  dateFormat: '%Y-%m-%d',
  timeFormat: '%I:%M %P',
  dateFormatMoment: 'YYYY-MM-DD',
  timeFormatMoment: 'hh:mm a',
};

export const ProductContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<ProductContextProviderProps>) => {
  const { customerCode } = useSettings();
  const { data, isError, fetchStatus, status, error } = useProductApi();
  const [isGift, setIsGift] = useState(false);
  // used for ga4 view item purpose only (ensure that view_item event is triggering only when new product is loaded)
  const [viewedProduct, setViewedProduct] = useState<string | null>(null);
  const product = (initialState || data) as GuestProduct;

  const { isLoading, errorCode } = useLoadingError(error, fetchStatus, status);
  const value: ProductContext = {
    ...(product as GuestProduct),
    viewedProduct,
    setViewedProduct,
    trafficLights: product?.trafficLights ?? { highDemandThreshold: 0.2 },
    sellAsGift: isGift,
    setSellAsGift: (option) => setIsGift(option),
  };

  // TODO: Temporary solution
  // Remove after all requests to Checkfront API will be migrated to guest-experience-api
  customerCodeToUrlRegistry[customerCode] = product?.companyInformation?.url as string;

  return (
    <productContext.Provider value={value}>
      <LoadingErrorWrapper
        isLoading={isLoading || !customerCodeToUrlRegistry[customerCode]}
        isError={isError}
        errorCode={errorCode}
      >
        {children}
      </LoadingErrorWrapper>
    </productContext.Provider>
  );
};

export const useProduct = () => useContext(productContext);
