import { styled } from '@guest-widgets/core';
import { Stack, Typography } from '@mui/material';
import { LineClamp } from '@guest-widgets/shared/src/components/LineClamp';

import { ItemCartPrice } from '../Cart/Items/ItemCartPrice';
import { ItemUpsellPrice } from '../Upsell/ItemUpsellPrice/ItemUpsellPrice';
import { UpsellItem } from '../contexts/upsellContext/upsell';
import { CartItem } from '../contexts/apiContext/cart/cart';

import { ThumbWithFallback } from './ThumbWithFallback';

export interface ItemContainerProps {
  item: CartItem | UpsellItem;
}

export const InfoContainer = ({ item }: ItemContainerProps) => {
  const isCartItem = !!item.id && !item.id?.includes('.');

  return (
    <Container>
      <ImageContainer>
        <ThumbWithFallback
          src={item.product.image?.thumbnailUrl ?? ''}
          alt={item.product.image?.alt}
        />
      </ImageContainer>
      <Typography variant="h5">
        <LineClamp lines={2}> {item.product.name} </LineClamp>
      </Typography>
      {isCartItem ? (
        <ItemCartPrice item={item as CartItem} />
      ) : (
        <ItemUpsellPrice item={item as UpsellItem} />
      )}
    </Container>
  );
};

const Container = styled(Stack)(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '96px auto',
  gridTemplateRows: 'auto auto',
  columnGap: spacing(2),
  borderRadius: 8,
  height: 80,
  '&:before': {
    border: 'none',
  },
  '& h3': {
    alignSelf: 'stretch',
  },
  '& .MuiTypography-caption': {
    justifySelf: 'end',
    alignSelf: 'end',
    padding: spacing(0.5, 0),
  },
}));

const ImageContainer = styled('div')({
  gridRowStart: 1,
  gridRowEnd: 'span 2',
  borderRadius: 4,
});
