import { Box, Divider } from '@mui/material';
import { styled } from '@guest-widgets/core';

import { useCart } from '../contexts/cartContext/cartContext';
import { CartItem } from '../contexts/apiContext/cart/cart';

import { SoldItem } from './SoldItem';

interface SoldItemUpsellsProps {
  item: CartItem;
}

export const SoldItemUpsells = ({ item }: SoldItemUpsellsProps) => {
  const { cartWithSteps } = useCart();
  const upsells =
    cartWithSteps.data?.cart?.items?.filter(
      (cartItem) => cartItem.id.includes('.') && cartItem.id.split('.')[0] === item.id
    ) ?? [];

  if (upsells.length === 0) {
    return null;
  }

  return (
    <>
      {upsells.map((upsellItem) => (
        <UpsellContainer key={upsellItem.id}>
          <StyledDivider />
          <SoldItem item={upsellItem} />
        </UpsellContainer>
      ))}
    </>
  );
};

const UpsellContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const StyledDivider = styled(Divider)(({ theme: { border, spacing }, widgetArea }) => ({
  border,
  margin: spacing(
    8,
    widgetArea.isSmall ? spacing(6) : spacing(8),
    0,
    widgetArea.isSmall ? 0 : spacing(8)
  ),
}));
