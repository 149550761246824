import React, { useEffect } from 'react';
import { useWidget } from '@guest-widgets/core';

import { useCheckout } from '../../contexts/apiContext/checkout/checkoutContext';
import { useBookingCreate } from '../../contexts/apiContext/checkout/mutations/useBookingCreate';
import { useCart } from '../../contexts/cartContext/cartContext';

import { Payment } from './Payment';
import { Skeleton } from './Skeleton';

export const Checkout = () => {
  const { paymentId, setPaymentId } = useCheckout();
  const { instanceId } = useWidget();
  const bookingCreate = useBookingCreate();
  const cart = useCart();

  const paymentSuccessHandler = () => {
    const successUrl = new URL(window.location.href);
    successUrl.searchParams.set('rwid', String(instanceId));
    successUrl.searchParams.set('rwstep', 'purchaseSuccess');
    window.location.href = successUrl.toString();
  };

  const paymentFailureHandler = () => {
    const successUrl = new URL(window.location.href);
    successUrl.searchParams.set('rwid', String(instanceId));
    successUrl.searchParams.set('rwstep', 'purchaseFailure');
    window.location.href = successUrl.toString();
  };

  const paymentRedirectHandler = (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    bookingCreate.mutate();
  }, []);

  useEffect(() => {
    if (bookingCreate.data?.bookingId) {
      cart.setBookingId.mutate(bookingCreate.data.bookingId!);
    }
  }, [bookingCreate.data?.bookingId]);

  useEffect(() => {
    if (bookingCreate.isSuccess) {
      if (bookingCreate.data.noPaymentNeeded) {
        paymentSuccessHandler();
      } else {
        setPaymentId(bookingCreate.data.paymentId!);
      }
    } else if (bookingCreate.isError) {
      paymentFailureHandler();
    }
  }, [bookingCreate]);

  if (!paymentId) {
    return <Skeleton />;
  }

  return (
    <Payment
      paymentId={paymentId}
      successHandler={paymentSuccessHandler}
      failureHandler={paymentFailureHandler}
      redirectHandler={paymentRedirectHandler}
    />
  );
};
