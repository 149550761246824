import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CartItem } from '../cart';
import { queryKeys } from '../../../../queryKeys';

import { AddToCartParams } from './useAddToCart';

interface RemoveItemParams extends AddToCartParams {}

export const useRemoveItem = ({ cartSession }: RemoveItemParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (obj: CartItem) => {
      cartSession.clearErrors();
      return await cartSession.removeItem(obj);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.CART]);
    },
  });
};
