import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { FlagSize } from '@guest-widgets/shared/src/components/CountryAutocomplete';

export const LoadingContainer = styled(Box)(({ theme: { palette } }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: palette.common.white,
  opacity: 0.4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const NavigationContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  paddingBottom: spacing(4),
}));

export const EmptyContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(5),
  padding: spacing(15, 0),
  alignItems: 'center',
}));

export const ErrorContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(5),
  padding: spacing(15, 5),
  alignItems: 'center',
}));

export const StyledRowContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: 0,
  paddingRight: 0,
}));

export const StyledErrorContainer = styled(Box)({
  display: 'flex',
  paddingLeft: 0,
  paddingRight: 0,
});

export const StyledSkeletonContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'block',
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: 0,
  paddingRight: 0,
}));

export const StyledContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: 0,
  paddingRight: 0,
}));

/**Defines the flag size proportionally to the font size */
export const getFlagSize = (givenFontSize = 1): FlagSize => {
  enum FontFactor {
    LARGE = 1.2,
    SMALL = 0.9,
  }

  if (givenFontSize >= FontFactor.LARGE) return 'large';
  if (givenFontSize <= FontFactor.SMALL) return 'small';
  return 'medium';
};

export const ERROR_ICON_SIZE = 56;
