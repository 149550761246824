import { MutationKey, useMutation } from '@tanstack/react-query';

import { AddToCartParams } from './useAddToCart';

export const useApplyDiscountCodes = ({ cartSession }: AddToCartParams) => {
  const mutationKey: MutationKey = ['applyDiscount'];

  const mutationFn = async ({ discountCode }: { discountCode: string }) => {
    return await cartSession.applyDiscountCode(discountCode!);
  };

  return useMutation({ mutationKey, mutationFn });
};

export const useRemoveDiscountCode = ({ cartSession }: AddToCartParams) => {
  const mutationKey: MutationKey = ['removeDiscountCode'];

  const mutationFn = async () => {
    return await cartSession.removeDiscountCode();
  };

  return useMutation({ mutationKey, mutationFn });
};
