import { useState } from 'react';
import { Button, Collapse } from '@mui/material';
import { Text } from '@eo-locale/react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { classes } from '@guest-widgets/shared/src/classes';
import { LoadingButton, ICON_BASE_SIZE } from '@guest-widgets/shared/src/components/LoadingButton';

import { useCart } from '../contexts/cartContext/cartContext';
import { useRouter } from '../contexts/routerContext/routerContext';
import { ActionStatus, ApiInvalidStructureException } from '../contexts/apiContext/apiContext';
import { NavigationContainer, StyledErrorContainer } from '../StyledSharedComponents';
import { ErrorMessage } from '../Error';

export interface NavigationProps {
  onAddToCart: () => void;
  isComplete: boolean;
  actionStatus: ActionStatus;
  parentErrors?: string[];
  disableAddToCard?: boolean;
  rateLimitError?: boolean;
}

export const Navigation = ({
  onAddToCart,
  isComplete,
  actionStatus,
  parentErrors,
  disableAddToCard,
  rateLimitError,
}: NavigationProps) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { hasItems } = useCart();
  const { setCurrentStep } = useRouter();

  const handleAddToCart = () => {
    if (!isComplete) {
      setErrorMessages(['please-select-all-information']);
      setTimeout(() => setErrorMessages([]), EXHIBITION_TIME);
      return;
    }
    onAddToCart();
  };
  const renderErrors = () => {
    if (rateLimitError)
      return <ErrorMessage show messageKey={'rate-limited-error'} mt={0} mb={0} />;

    if (errorMessages.length)
      return errorMessages.map((error) => (
        <p key={error}>
          <ErrorMessage
            show={!!error}
            messageKey={error}
            icon={ProductionQuantityLimitsIcon}
            mt={0}
          />
        </p>
      ));
    if (parentErrors?.length)
      return parentErrors.map((error) => (
        <ErrorMessage key={error} show={!!error} message={error} mt={0} mb={0} />
      ));
    if (actionStatus.error instanceof ApiInvalidStructureException)
      return (
        <ErrorMessage show={actionStatus.isError} message={actionStatus.error.message} mt={0} />
      );
    return <ErrorMessage show={actionStatus.isError} messageKey="server-unreachable" mt={0} />;
  };

  return (
    <NavigationContainer>
      <div className={classes.booking.addToCart}>
        <LoadingButton
          color="primary"
          variant="contained"
          size="large"
          iconSize={ICON_BASE_SIZE}
          fullWidth
          loading={actionStatus?.isLoading}
          onClick={handleAddToCart}
          className={`${classes.booking.buttons.button} ${classes.booking.buttons.addToCart}`}
          disabled={disableAddToCard}
        >
          <Text id="add-to-shopping-cart" />
        </LoadingButton>
      </div>
      {(!!errorMessages.length ||
        actionStatus?.isError ||
        !!parentErrors?.length ||
        rateLimitError) && <StyledErrorContainer>{renderErrors()}</StyledErrorContainer>}
      <Collapse in={hasItems} unmountOnExit>
        <Button
          startIcon={<ShoppingCartIcon />}
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => setCurrentStep('contact')}
          className={classes.booking.buttons.goToCart}
        >
          <Text id="go-to-cart" />
        </Button>
      </Collapse>
    </NavigationContainer>
  );
};

const EXHIBITION_TIME = 5000;
