import { Fragment } from 'react';

interface PriceWithBreakLineProps {
  price: string;
}

export const PriceWithBreakLine = ({ price }: PriceWithBreakLineProps) => {
  const splited = price.split(/(\d)/);
  const currency = splited.shift();
  const amount = splited.join('');

  return (
    <Fragment>
      {currency}
      <wbr />
      {amount}
    </Fragment>
  );
};
