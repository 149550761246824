import React from 'react';
import { FormHelperText, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslator } from '@eo-locale/react';
import { classes } from '@guest-widgets/shared/src/classes';

import { useContact } from '../../contexts/contactContext/contactFormContext';
import { DropdownField } from '../../contexts/contactContext/contact';

interface DropdownViewProps {
  field: DropdownField;
}

export const DropdownView = ({
  field: { id, tip, value, label, required, options, onChange, hidden },
}: DropdownViewProps) => {
  const {
    form: { register, formState },
  } = useContact();
  const { translate } = useTranslator();

  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message || tip;

  if (hidden) return null;

  return (
    <Box>
      <FormControl fullWidth variant="outlined" error={error}>
        <InputLabel>
          {translate(label)} {required && '*'}
        </InputLabel>
        <Select
          defaultValue={value ?? ''}
          label={translate(label)}
          required={required}
          {...register(`contact.${id}`, {
            onChange: (event) => {
              // react hook form use classes internally and override 'this'
              // so we need to bind the onChange function to the original 'this'
              onChange?.onChange.bind(onChange)(event.target.value);
            },
          })}
          className={classes.booking.select}
        >
          {options.map(({ value, label }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
