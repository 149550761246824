import { useEffect, useRef, useState } from 'react';
import { styled } from '@guest-widgets/core';
import { Box, Typography, Link, Collapse } from '@mui/material';

import { LinkWithIcon } from './LinkWithIcon';

interface DescriptionProps {
  description?: string;
}

export const Optional = ({ description }: DescriptionProps) => {
  const [showMore, setShowMore] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const twoLinesHeight = useRef<number>(0);

  useEffect(() => {
    if (textRef.current) {
      twoLinesHeight.current = textRef.current.clientHeight;
      setShowButton(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [description]);

  const handleToggleClick = () => {
    if (textRef.current?.classList.contains('clamped')) {
      textRef.current?.classList.remove('clamped');
    } else {
      setTimeout(() => {
        textRef.current?.classList.add('clamped');
      }, 300);
    }
    setShowMore((prev) => !prev);
  };

  return description ? (
    <Box mt={4} display="flex" flexDirection="column" gap={4}>
      <Collapse in={showButton ? showMore : true} collapsedSize={twoLinesHeight.current}>
        <ToggleableLineClamp
          ref={textRef}
          variant="body2"
          className="clamped"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Collapse>
      {showButton && (
        <Link onClick={handleToggleClick}>
          <LinkWithIcon showMore={showMore} />
        </Link>
      )}
    </Box>
  ) : null;
};

const ToggleableLineClamp = styled(Typography)({
  '&.clamped': {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
