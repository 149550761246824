import {
  BoxProps,
  Box as MuiBox,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  TooltipProps,
  lighten,
  styled,
  tooltipClasses,
} from '@mui/material';

interface DayBoxProps extends BoxProps {
  alternativeTitle?: string;
}

export const Box = styled(({ alternativeTitle, ...props }: DayBoxProps) => <MuiBox {...props} />)(
  ({ alternativeTitle, theme: { palette, spacing, shape } }) => {
    const lightPrimary = lighten(palette.primary.light, 0.8);
    const tooltipStyles = {
      [`& .${tooltipClasses.tooltip}`]: {
        visibility: 'hidden',
      },
      [`& .${tooltipClasses.arrow}`]: {
        visibility: 'visible',
      },
    };
    const tooltipAfterStyles = {
      display: 'block',
      content: `"${alternativeTitle}"`,
      visibility: 'visible',
      backgroundColor: '#373737',
      padding: spacing(2),
      borderRadius: shape.borderRadius,
    };

    return {
      marginRight: spacing(-4),
      marginLeft: spacing(-4),
      background: 'transparent',
      transition: 'background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:has(button[class*="rdp-day_hover_start"]), &:has(button[class*="rdp-day_range_start"])': {
        background: `linear-gradient(to right, transparent 50%, ${lightPrimary} 50%)`,
      },
      '&:has(button[class*="rdp-day_hover_end"]), &:has(button[class*="rdp-day_range_end"])': {
        background: `linear-gradient(to left, transparent 50%, ${lightPrimary} 50%)`,
      },
      '&:has(button[class*="rdp-day_hover_middle"]), &:has(button[class*="rdp-day_range_middle"])': {
        background: lightPrimary,
      },
      '&:has(button[class*="rdp-day_range_start"]):has(button[class*="rdp-day_range_end"])': {
        background: 'transparent',
      },
      '&:has(button[class*="rdp-day_hover_start"]), &:has(button[class*="rdp-day_hover_end"]), &:has(button[class*="rdp-day_range_end"])': {
        '& div[role="tooltip"]': {
          opacity: 0,
        },
      },
      '&:has(button[class*="rdp-day_invalid_hover"])': {
        '& div[role="tooltip"]': {
          ...tooltipStyles,
          [`& .${tooltipClasses.tooltip}:after`]: {
            ...tooltipAfterStyles,
            transform: 'translateY(8px)',
          },
        },
        '& div[data-popper-placement="bottom"]': {
          ...tooltipStyles,
          [`& .${tooltipClasses.tooltip}:after`]: {
            ...tooltipAfterStyles,
            transform: 'translateY(-21px)',
          },
        },
      },
    };
  }
);

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme: { palette, spacing, shape } }) => ({
  transition: 'opacity 700ms cubic-bezier(0.4, 0, 0.2, 0.2) 0ms',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#373737',
    color: palette.common.white,
    padding: spacing(2),
    borderRadius: shape.borderRadius,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#373737',
  },
}));

export const Button = styled(MuiButton)(({ theme: { palette } }) => {
  const lightPrimary = lighten(palette.primary.light, 0.8);

  return {
    width: '50%',
    minWidth: '3.5ch',
    fontWeight: 'normal',
    paddingTop: '0.41em',
    paddingBottom: '0.41em',
    '&:hover': {
      boxShadow: `inset 0 0 0 2px ${lightPrimary}`,
      backgroundColor: palette.primary.main,
      color: palette.getContrastText(palette.primary.main),
    },
    '&:hover:not(.rdp-day_selected)': {
      boxShadow: `inset 0 0 0 2px ${palette.primary.main}`,
      backgroundColor: palette.background.paper,
      color: palette.getContrastText(palette.background.paper),
    },
    '&:hover.rdp-day_range_middle': {
      boxShadow: `inset 0 0 0 2px ${palette.primary.main}`,
      backgroundColor: lightPrimary,
      color: palette.getContrastText(palette.background.paper),
    },
  };
});
