import MuiSkeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { classes } from '@guest-widgets/shared/src/classes';

interface SkeletonProps {
  numberOfMonths: number;
}

export const BookingSkeleton = ({ numberOfMonths }: SkeletonProps) => {
  return (
    <div className={classes.booking.widget}>
      <Box pb={1} pt={3}>
        <MuiSkeleton variant="text" width={70} height={30} />
      </Box>

      {/* Calendar */}
      <Box display="flex" gap={12} pb={3}>
        {!!numberOfMonths &&
          Array.from({ length: numberOfMonths }).map((_, index) => (
            <Box key={index} display="flex" flexDirection="column" alignItems="center" width="100%">
              <Box display="flex" justifyContent="center" pb={1}>
                <MuiSkeleton variant="text" width={180} height={44} />
              </Box>
              <MonthSkeleton />
            </Box>
          ))}
      </Box>

      {/* Footer */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <MuiSkeleton variant="text" width={220} height={30} />
        <MuiSkeleton variant="text" width={60} height={30} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} pb={4}>
        <MuiSkeleton variant="text" width={90} height={30} />
      </Box>

      {/* Navigation */}
      <Box pb={4}>
        <MuiSkeleton variant="rectangular" width="100%" height={48} />
      </Box>
    </div>
  );
};

const MonthSkeleton = () => (
  <Box display="flex" flexWrap="wrap" width="100%">
    {/* Weekdays */}
    {Array.from({ length: 7 }).map((_, index) => (
      <Box width={`${100 / 7}%`} key={index} px="2.5%" py={1}>
        <MuiSkeleton variant="text" width="100%" height={30} />
      </Box>
    ))}

    {/* Blank days */}
    <Box width={`${100 / 7}%`} key="placeholder-1" px="2.8%" py={1.5}></Box>
    <Box width={`${100 / 7}%`} key="placeholder-2" px="2.8%" py={1.5}></Box>

    {/* Month days */}
    {Array.from({ length: 30 }).map((_, index) => (
      <Box width={`${100 / 7}%`} key={index} px="2.8%" py={1.5}>
        <MuiSkeleton variant="rectangular" width="100%" height={32} sx={{ borderRadius: '4px' }} />
      </Box>
    ))}
  </Box>
);
