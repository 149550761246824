import { DisplayTime } from '../../../contexts/productContext/product';

export const getTestID = (element: 'button' | 'dropdown', displayTime: DisplayTime | undefined) => {
  if (displayTime?.showEndTimes && displayTime?.showUnavailable)
    return `timeslot-${element}-showEndTimes-showUnavailable`;
  if (displayTime?.showEndTimes) return `timeslot-${element}-showEndTimes`;
  if (displayTime?.showUnavailable) return `timeslot-${element}-showUnavailable`;

  return `timeslot-${element}`;
};
