import { UseQueryResult } from '@tanstack/react-query';

import { CartWithSteps } from './cart';
import { useSession } from './session/useSession';
import { useAddToCart } from './mutations/useAddToCart';
import { useRemoveItem } from './mutations/useRemoveItem';
import { useRefreshCartState } from './mutations/useRefreshCartState';
import { useDestroy } from './mutations/useDestroy';
import { useSetProductContact } from './mutations/useSetProductContact';
import { useApplyDiscountCodes, useRemoveDiscountCode } from './mutations/useHandleDiscountCode';
import { useGetCartWithSteps } from './mutations/useGetCartWithSteps';
import { useSetDeposit } from './mutations/useSetDeposit';
import { useSetBookingId } from './mutations/useSetBookingId';

export interface CartApi {
  cartWithSteps: UseQueryResult<CartWithSteps>;
  addToCart: ReturnType<typeof useAddToCart>;
  removeItem: ReturnType<typeof useRemoveItem>;
  refreshCart: ReturnType<typeof useRefreshCartState>;
  destroy: ReturnType<typeof useDestroy>;
  setProductContact: ReturnType<typeof useSetProductContact>;
  applyDiscountCode: ReturnType<typeof useApplyDiscountCodes>;
  removeDiscountCode: ReturnType<typeof useRemoveDiscountCode>;
  setDeposit: ReturnType<typeof useSetDeposit>;
  setBookingId: ReturnType<typeof useSetBookingId>;
}

export const useCartApi = () => {
  const cartSession = useSession();

  const params = {
    cartSession,
  };
  return {
    cartWithSteps: useGetCartWithSteps(params),
    addToCart: useAddToCart(params),
    removeItem: useRemoveItem(params),
    destroy: useDestroy(params),
    setProductContact: useSetProductContact(params),
    refreshCart: useRefreshCartState(params),
    applyDiscountCode: useApplyDiscountCodes(params),
    removeDiscountCode: useRemoveDiscountCode(params),
    setDeposit: useSetDeposit(params),
    setBookingId: useSetBookingId(params),
  } as CartApi;
};
