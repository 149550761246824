import { Text, useTranslator } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { useDeposit } from '../../hooks/cart/useDeposit';
import { DepositOption } from '../../contexts/apiContext/cart/cart';

import { RadioLabel } from './RadioLabel';

export const Deposit = () => {
  const { setPayOption, payDepositPrice, payFullPrice } = useDeposit();
  const { translate } = useTranslator();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayOption((event.target as HTMLInputElement).value as DepositOption);
  };

  return (
    <DepositContainer>
      <Typography color="inherit" variant="h3">
        <Text id="choose-how-to-pay" />
      </Typography>
      <DepositForm fullWidth>
        <RadioGroup
          aria-labelledby="deposit-options"
          defaultValue="pay-deposit"
          name="deposit-options"
          onChange={handleChange}
        >
          <FormControlLabel
            value="pay-deposit"
            control={<Radio />}
            label={
              <RadioLabel
                text={translate('pay-a-deposit')}
                price={payDepositPrice?.amount || 0}
                currency={payDepositPrice?.currencyCode || 'EUR'}
              />
            }
          />
          <FormControlLabel
            value="pay-full"
            control={<Radio />}
            label={
              <RadioLabel
                text={translate('pay-the-full-amount')}
                price={payFullPrice?.amount || 0}
                currency={payFullPrice?.currencyCode || 'EUR'}
                withVat
              />
            }
          />
        </RadioGroup>
      </DepositForm>
    </DepositContainer>
  );
};

const DepositContainer = styled(Box)(({ theme: { spacing, border } }) => ({
  marginTop: spacing(4),
  paddingBottom: spacing(4),
  borderBottom: border,
}));

const DepositForm = styled(FormControl)({
  marginTop: 8,
  '& .MuiFormControlLabel-root': {
    marginRight: 0,
  },
  '& .MuiFormControlLabel-label': {
    width: '100%',
  },
});
