import React from 'react';
import { lightGreen, amber } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps, Tooltip, Badge, Box, BadgeProps } from '@mui/material';
import { useTranslator } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { Palette } from '@mui/material/styles';
import { Feature } from '@guest-widgets/shared/src/features';
import { useCalendarLanguage } from '@guest-widgets/shared/src/components/Calendar/useCalendarLanguage';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';

import { AvailabilityTime, TrafficLightStatus } from '../../../contexts/timeslotsContext/timeslots';
import { useSettings } from '../../../contexts/settingsContext/settingsContext';
import { useProduct } from '../../../contexts/productContext/productContext';

import { formatTimeUnit, TimeUnit } from './TimeUnit';
import { getTestID } from './getTestId';

export interface TimeButtonProps {
  availability: AvailabilityTime;
  isSelected: boolean;
  onSelect: (availabilityTime: AvailabilityTime) => void;
}

export const TimePeriodItem = ({ availability, isSelected, onSelect }: TimeButtonProps) => {
  const { displayTime } = useProduct();
  const { translate } = useTranslator();
  const { locale } = useSettings();
  const { isEnabled } = useFeature();
  const { availableQuantity, trafficLightStatus = 'available' } = availability;
  const calendarLanguage = useCalendarLanguage(locale);

  const { companyInformation } = useProduct();

  const hint = [
    trafficLightStatus === 'available' && availableQuantity,
    translate(titlePerAvailability[trafficLightStatus]),
  ]
    .filter((i) => !!i)
    .join(' ');

  const timeLength = formatTimeUnit({
    timeslot: availability,
    locale: calendarLanguage,
    showEndTimes: displayTime?.showEndTimes,
    providerLocale: companyInformation?.locale,
  }).length;

  const timeButton = (
    <StyledButton
      disabled={trafficLightStatus === 'sold-out'}
      onClick={() => onSelect(availability)}
      timeLength={timeLength}
      className={classPerAvailability(isSelected, trafficLightStatus)}
      data-testid={getTestID('button', displayTime)}
    >
      <TimeUnit
        availabilityTime={availability}
        locale={calendarLanguage}
        showEndTimes={displayTime?.showEndTimes}
        providerLocale={companyInformation?.locale}
      />
    </StyledButton>
  );

  return (
    <Tooltip enterDelay={600} title={hint} disableInteractive>
      <Box
        sx={{
          display: 'inline-flex',
          opacity: trafficLightStatus === 'sold-out' ? 0.55 : 1,
        }}
      >
        <StyledBadge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          badgeContent={showBadgeConditionally(
            isEnabled(Feature.RemainingTickets),
            availableQuantity
          )}
          trafficLightStatus={trafficLightStatus}
        >
          {timeButton}
        </StyledBadge>
      </Box>
    </Tooltip>
  );
};

interface PropsWithTrafficLight {
  trafficLightStatus: TrafficLightStatus;
}

interface StyledButtonProps extends ButtonProps {
  timeLength: number;
}

const StyledButton = styled(({ timeLength, ...props }: StyledButtonProps) => <Button {...props} />)(
  ({ timeLength, theme: { palette } }) => ({
    fontWeight: 400,
    padding: '3.5px 5.5px',
    minWidth: timeLength > 5 ? '9.5ch' : '7ch',
    borderRadius: 4,

    [`&.${booking.time.soldOut}`]: {
      backgroundColor: getTrafficLightColor('sold-out', palette),
      color: palette.grey[900],
      textDecoration: 'line-through',
    },

    [`&.${booking.time.highDemand}`]: {
      backgroundColor: getTrafficLightColor('high-demand'),
      color: palette.getContrastText(getTrafficLightColor('high-demand')),
      transition:
        'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: palette.primary.main,
        color: palette.getContrastText(palette.primary.main),
        transform: 'scale(1.1)',
        '& + span': {
          borderColor: palette.primary.main,
        },
      },
    },

    [`&.${booking.time.available}`]: {
      backgroundColor: getTrafficLightColor('available'),
      color: palette.getContrastText(getTrafficLightColor('available')),
      transition:
        'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: palette.primary.main,
        color: palette.getContrastText(palette.primary.main),
        transform: 'scale(1.1)',
        '& + span': {
          borderColor: palette.primary.main,
        },
      },
    },

    [`&.${booking.time.selected}`]: {
      color: palette.getContrastText(palette.primary.main),
      fontWeight: 600,
      backgroundColor: palette.primary.main,
    },
  })
);

const StyledBadge = styled(
  ({ trafficLightStatus, ...props }: PropsWithTrafficLight & BadgeProps) => <Badge {...props} />
)(({ trafficLightStatus, theme: { palette } }) => ({
  '& > span': {
    border: `solid 1px ${getTrafficLightColor(trafficLightStatus, palette)}`,
    backgroundColor: palette.common.white,
    color: palette.getContrastText(palette.common.white),
    left: '50%',
  },
}));

const titlePerAvailability: Record<TrafficLightStatus, string> = {
  available: 'available',
  'high-demand': 'high-demand-likely-to-sell-out-soon',
  'sold-out': 'sold-out-might-be-available-on-site',
};

const getTrafficLightColor = (status: TrafficLightStatus, palette?: Palette) => {
  const mapper: Record<TrafficLightStatus, string> = {
    'sold-out': palette?.grey[400] || 'inherit',
    'high-demand': amber[200],
    available: lightGreen[400],
  };
  return mapper[status];
};

const classPerAvailability = (isSelected: boolean, status: TrafficLightStatus) => {
  const mapper: Record<TrafficLightStatus, string> = {
    'sold-out': booking.time.soldOut,
    'high-demand': booking.time.highDemand,
    available: booking.time.available,
  };

  return [booking.time.time, isSelected && booking.time.selected, mapper[status]]
    .filter((i) => !!i)
    .join(' ');
};

/** Provides available quantity only if the feature of showing remaining tickets is enabled or not defined */
const showBadgeConditionally = (featureIsEnabled: boolean, availableQuantity: number | undefined) =>
  featureIsEnabled === false ? undefined : availableQuantity;
