import { isGTM } from './utils/getGAVersion';
import type { PurchaseEcommerceGtm } from './types';
import { ViewEvent, GA4Event } from './types';
import type { Tag } from './customerTrackingCreator';

declare global {
  interface Window {
    dataLayer: any[] | undefined;
  }
}

export const gtm = (): Tag => {
  return {
    isAvailable: () => isGTM(),

    pageView: (title?, virtualPageProps?) => {
      if (virtualPageProps) {
        const { path } = virtualPageProps;

        window.dataLayer?.push({
          event: ViewEvent.virtualPage,
          pagePath: path,
        });
      }
    },

    searchProducts: (contentDescription) => {
      const gtmEvent = {
        event: ViewEvent.search,
        ecommerce: {
          search_term: contentDescription,
        },
      };
      window.dataLayer?.push(gtmEvent);
    },

    selectProduct: (items) => {
      const gtmEvent = {
        event: GA4Event.selectProduct,
        ecommerce: {
          items,
        },
      };

      window.dataLayer?.push(gtmEvent);
    },

    viewProductList: (items) => {
      const gtmEvent = {
        event: GA4Event.viewItemList,
        ecommerce: {
          items,
        },
      };
      window.dataLayer?.push(gtmEvent);
    },

    viewProduct: (product) => {
      const { value, currency, ga4_items } = product;
      const gtmEvent = {
        event: GA4Event.product,
        ecommerce: { value, currency, items: ga4_items },
      };

      window.dataLayer?.push(gtmEvent);
    },

    viewCart: (viewCartData) => {
      const gtmEvent = {
        event: GA4Event.viewCart,
        ecommerce: {
          value: viewCartData.value,
          currency: viewCartData.currency,
          items: viewCartData.items,
        },
      };
      window.dataLayer?.push(gtmEvent);
    },

    addToCart: (cart) => {
      const { value, currency, ga4_items } = cart;
      const gtmEvent = {
        event: GA4Event.add,
        ecommerce: {
          value,
          currency,
          items: ga4_items,
        },
      };
      window.dataLayer?.push(gtmEvent);
    },

    removeFromCart: (cart) => {
      const { value, currency, ga4_items } = cart;

      const gtmEvent = {
        event: GA4Event.remove,
        ecommerce: {
          value,
          currency,
          items: ga4_items,
        },
      };

      window.dataLayer?.push(gtmEvent);
    },

    checkout: (checkoutData) => {
      const { value, currency, ga4_items } = checkoutData;

      const gtmEvent = {
        event: GA4Event.checkout,
        ecommerce: {
          currency,
          value,
          items: ga4_items,
        },
      };

      window.dataLayer?.push(gtmEvent);
    },

    purchase: (purchaseData) => {
      const { transaction_id, revenue, tax, currency, discount, ga4_items } = purchaseData;

      const ecommerce: PurchaseEcommerceGtm = {
        transaction_id,
        value: revenue,
        tax,
        shipping: 0, // not implemented yet
        currency,
        items: ga4_items,
      };
      const gtmEvent = {
        event: GA4Event.purchase,
        ecommerce,
      };
      if (discount) gtmEvent.ecommerce.discount = discount;
      window.dataLayer?.push(gtmEvent);
    },
  };
};
