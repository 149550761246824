import { useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@guest-widgets/core';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { customerTracking } from '@guest-widgets/shared/src/utils/customerTracking/customerTracking';

import ItemDeleteDialog from '../../common/ItemDeleteDialog';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useProduct } from '../../contexts/productContext/productContext';
import { useCart } from '../../contexts/cartContext/cartContext';
import { getRemoveFromCart } from '../../trackingInfo/cartHelpers';
import { useSettings } from '../../contexts/settingsContext/settingsContext';
import { useSession } from '../../contexts/apiContext/cart/session/useSession';

interface ItemCartPriceProps {
  item: CartItem;
}

export const ItemCartPrice = ({ item }: ItemCartPriceProps) => {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [rateLimitError, setRateLimitError] = useState(false);
  const { currency } = useSettings();
  const { removeItem } = useCart();
  const { cartState } = useSession();
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const { price, discount } = item;
  const itemPrice = formatPrice(price.total.amount, price.total.currencyCode);

  const getDiscountData = useMemo(() => {
    if (discount?.adjustmentType === 'percent' && discount.amount) {
      return `-${discount.amount * 100}%`;
    }
    if (discount?.adjustmentType === 'fixed' && discount.amount) {
      return `-${formatPrice(discount.amount, price.total.currencyCode)}`;
    }
  }, [price.total.currencyCode, discount?.adjustmentType, discount?.amount]);

  const onOpenRemoveDialog = () => {
    setOpenRemoveDialog(true);
  };
  const onCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const onDeleteCartItem = () => {
    removeItem
      .mutateAsync(item)
      .then(() => {
        onCloseRemoveDialog();

        const itemsToRemove = cartState.items.filter(
          (cartItem) => cartItem.id === item.id || cartItem.id.startsWith(`${item.id}.`)
        );
        itemsToRemove.forEach((item) => {
          const removedItem = getRemoveFromCart(item, currency);
          if (removedItem) customerTracking().removeFromCart?.(removedItem);
        });
      })
      .catch((error) => {
        if (error.message.includes('429')) {
          setRateLimitError(true);
        }
      });
  };

  return (
    <PriceWrapper>
      {discount ? (
        <DiscountWrapper>
          <DiscountText variant="h6">{getDiscountData}</DiscountText>
          <PriceText>{itemPrice} </PriceText>
        </DiscountWrapper>
      ) : (
        <PriceText>{itemPrice} </PriceText>
      )}
      <Button
        variant="outlined"
        size="small"
        onClick={onOpenRemoveDialog}
        sx={{ minWidth: 'unset', padding: '6px', borderWidth: 1 }}
      >
        <DeleteIcon sx={{ fontSize: 18 }} />
      </Button>
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={onDeleteCartItem}
        productName={item?.product.name ?? ''}
        rateLimitError={rateLimitError}
      />
    </PriceWrapper>
  );
};

const DiscountWrapper = styled('span')({
  display: 'flex',
});

const PriceWrapper = styled(Box)({
  display: 'flex',
  alignSelf: 'end',
  justifyContent: 'space-between',
  alignItems: 'end',
});

const DiscountText = styled(Typography)(({ theme: { spacing } }) => ({
  alignSelf: 'end',
  lineHeight: 1,
  fontWeight: 500,
  backgroundColor: '#64B473',
  borderRadius: spacing(2),
  padding: spacing(0.5, 1),
  marginRight: spacing(2),
}));

const PriceText = styled(Typography)({
  lineHeight: 1,
  fontWeight: 700,
});
