import type { ListProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import { GA4Items, FBContents, Legacy, GA4ViewCartData } from './types';

interface ViewProductProps {
  id: string;
  name: string;
  brand: string | undefined;
  category: string | undefined;
  price: number;
  value: number;
  currency: string;
  ga4_items: GA4Items[];
}

/** Common inforamtion to be tracked from cart events */
export interface CartProps {
  value: number;
  value_incl_tax: number;
  currency: string;
  ga4_items: GA4Items[];
  fb_contents: FBContents[];
}

/** Special case without facebook info tracked */
export type RemoveCartProps = Omit<CartProps, 'fb_contents'>;

export interface CheckoutProps extends CartProps {
  checkout_step: number;
}

export interface PurchaseProps extends Omit<CartProps, 'value_incl_tax'> {
  transaction_id: string;
  revenue: number;
  tax: number;
  discount: number;
  legacy: Legacy;
  tracking: any;
}

interface IsAvailable {
  isAvailable: () => boolean;
}

export interface VirtualPageProps {
  title: string;
  path: string;
}

interface TrackingEvents {
  pageView: (title?: string, virtualPageProps?: VirtualPageProps) => void;
  searchProducts: (contentDescription: string, products: ListProductDto[]) => void;
  viewProductList: (products: GA4Items[]) => void;
  viewProduct: (product: ViewProductProps) => void;
  selectProduct: (product: GA4Items[]) => void;
  viewCart: (cartData: GA4ViewCartData) => void;
  addToCart: (cart: CartProps) => void;
  removeFromCart: (cart: RemoveCartProps) => void;
  checkout: (checkoutData: CheckoutProps) => void;
  purchase: (purchaseData: PurchaseProps) => void;
}

export type Tag = Partial<TrackingEvents> & IsAvailable;

export const customerTrackingCreator = (tags: Tag[]): Partial<TrackingEvents> => ({
  pageView: (...args) => tags.forEach((s) => s.pageView?.(...args)),
  searchProducts: (...args) => tags.forEach((s) => s.searchProducts?.(...args)),
  viewProductList: (...args) => tags.forEach((s) => s.viewProductList?.(...args)),
  viewProduct: (...args) => tags.forEach((s) => s.viewProduct?.(...args)),
  selectProduct: (...args) => tags.forEach((s) => s.selectProduct?.(...args)),
  viewCart: (...args) => tags.forEach((s) => s.viewCart?.(...args)),
  addToCart: (...args) => tags.forEach((s) => s.addToCart?.(...args)),
  removeFromCart: (...args) => tags.forEach((s) => s.removeFromCart?.(...args)),
  checkout: (...args) => tags.forEach((s) => s.checkout?.(...args)),
  purchase: (...args) => tags.forEach((s) => s.purchase?.(...args)),
});
