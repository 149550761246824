import React from 'react';
import { Box } from '@mui/material';

import { HtmlField } from '../../contexts/contactContext/contact';

interface HtmlViewProps {
  field: HtmlField;
}

export const HtmlView = ({ field: { value, hidden } }: HtmlViewProps) => {
  if (hidden) return null;
  return <Box dangerouslySetInnerHTML={{ __html: value as string }} />;
};
