import { MutationKey, useMutation } from '@tanstack/react-query';

import { CartDeposit } from '../cart';

import { AddToCartParams } from './useAddToCart';

export const useSetDeposit = ({ cartSession }: AddToCartParams) => {
  const mutationKey: MutationKey = ['deposit'];

  const mutationFn = async (deposit: CartDeposit) => {
    return cartSession.setDeposit(deposit);
  };

  return useMutation({ mutationKey, mutationFn });
};
