import { useEffect } from 'react';
import { getHours, getMinutes, set } from 'date-fns';
import { Typography, styled } from '@mui/material';
import { Text } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';

import { TimeRange, useProductInSelection } from '../../Guest/productInSelectionContext';
import {
  AvailabilityTimeDayTicket,
  TimeslotsDay,
} from '../../../contexts/timeslotsContext/timeslots';

import { TimeDropdown } from './TimeDropdown';
import { TimePeriodList } from './TimePeriodList';

export const Timeslot = ({ dateFrom }: { dateFrom: TimeslotsDay }) => {
  const { date, time = {}, setTime, displayTime } = useProductInSelection();
  const availabilities = date?.from?.availabilityTimes as Array<AvailabilityTimeDayTicket>;

  useEffect(() => {
    if (!availabilities?.length) return;

    const isSelectedTimeAvailable = !!time.startTime
      ? availabilities.some(
          (item) =>
            `${item.timeRange.startTime.getHours()}:${item.timeRange.startTime.getMinutes()}` ===
            `${time.startTime!.getHours()}:${time.startTime!.getMinutes()}`
        )
      : false;

    // If it is available, set time with new day
    if (isSelectedTimeAvailable) {
      return setTime(mapToTimeRange(date?.from?.day, time));
    }

    // Select the first available time
    const available = availabilities.find((item) => item.trafficLightStatus !== 'sold-out');
    const { startTime, endTime } = available?.timeRange || {};

    setTime({
      startTime,
      endTime,
    });
  }, [availabilities]);

  return (
    <>
      <Title variant="h4" className={booking.step.title}>
        <Text id="time" />
      </Title>
      {displayTime?.type === 'dropdown' ? (
        <TimeDropdown />
      ) : (
        <TimePeriodList
          timeslotsDay={dateFrom}
          selected={time?.startTime && { time: time?.startTime }}
          onSelect={(selected) =>
            setTime({
              startTime: (selected as AvailabilityTimeDayTicket).timeRange.startTime,
              endTime: (selected as AvailabilityTimeDayTicket).timeRange.endTime,
            })
          }
        />
      )}
    </>
  );
};

const mapToTimeRange = (fromDay?: Date, time?: TimeRange): TimeRange => {
  if (!fromDay || !time?.startTime) return { startTime: undefined, endTime: undefined };

  const { startTime, endTime } = time;

  return {
    startTime: set(fromDay, {
      hours: getHours(startTime),
      minutes: getMinutes(startTime),
    }),
    endTime: endTime
      ? set(fromDay, {
          hours: getHours(endTime),
          minutes: getMinutes(endTime),
        })
      : undefined,
  };
};

const Title = styled(Typography)(({ theme: { spacing, border } }) => ({
  borderTop: border,
  paddingTop: spacing(4),
  marginTop: spacing(4),
  marginBottom: spacing(4),
}));
