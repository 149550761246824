import React, { useState, createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { LanguageLocaleCode, formatLocaleForI18n } from '@guest-widgets/shared/src/i18n/i18n';
import { objectsMerge } from '@guest-widgets/shared/src/utils/objectsMerge';
import type { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';
import { useWidget } from '@guest-widgets/core';

import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { useSettingsApi } from '../apiContext/settings/useSettingsApi';
import { useLoadingError } from '../../hooks/useLoadingError';
import { WidgetContainer } from '../../WidgetContainer';

import { Settings } from './settings';
import { useQueryStringSettings } from './useQueryStringSettings';
import { initialSettings } from './initialSettings';

export interface SettingsProps {
  productId?: string;
  customerCode?: string;
  locale?: LanguageLocaleCode;
  subId?: string;
  widgetId?: string;
  disableFeatures?: string[];
  enableFeatures?: string[];
  configuration?: ConfigurationSettingsDto;
}

const settingsContext = createContext({} as SettingsContext);

interface SettingsLoadable extends Settings {
  isLoading: boolean;
}

export interface SettingsContext extends SettingsLoadable {
  addSettings: (settings: Partial<Settings>) => void;
}

export const SettingsProvider = ({
  children,
  ...settingsFromProps
}: PropsWithChildren<SettingsProps>) => {
  const [settings, setSettings] = useState(
    objectsMerge<SettingsLoadable>(settingsFromProps, initialSettings, { isLoading: true })
  );
  const { setSizeFactor } = useWidget();
  const { configuration } = settingsFromProps;
  const queryString = useQueryStringSettings();
  const { data: settingsFromBackend, isError, fetchStatus, status, error } = useSettingsApi(
    settings
  );

  useEffect(() => {
    if (configuration) setSettings((current) => ({ ...current, configuration }));
  }, [configuration]);

  useEffect(() => {
    if (!settingsFromBackend) return;

    const loadingState = {
      isLoading: false,
    };

    const mergedSettings = objectsMerge<SettingsLoadable>(
      loadingState,
      settingsFromProps,
      settingsFromBackend,
      settings
    );

    setSettings({
      ...mergedSettings,
      customerCode: applyCustomerCode(
        settingsFromBackend.customerCode,
        mergedSettings.customerCode
      ),
    });
  }, [settingsFromBackend]);

  useEffect(() => {
    const newSizeFactor = settings.configuration?.fontSize || 1;
    setSizeFactor(newSizeFactor);
  }, [settings.configuration?.fontSize]);

  const { isLoading, errorCode } = useLoadingError(error, fetchStatus, status);

  const value: SettingsContext = {
    ...settings,
    locale: formatLocaleForI18n(settings.locale) as LanguageLocaleCode,
    queryString,
    addSettings: (partial) => setSettings((prev) => ({ ...prev, ...partial })),
  };

  return (
    <settingsContext.Provider value={value}>
      <WidgetContainer>
        <LoadingErrorWrapper isError={isError} isLoading={isLoading} errorCode={errorCode}>
          {children}
        </LoadingErrorWrapper>
      </WidgetContainer>
    </settingsContext.Provider>
  );
};

export const SettingsConsumer = settingsContext.Consumer;
export const useSettings = () => useContext(settingsContext);

/**Apply customer code giving priority to the backend settings, it's needed in case of widgets generated
 * with partner code provided, on these cases we need to know the operator code instead (provided by the backend)
 */
const applyCustomerCode = (fromBackend?: string, fromMergedSettings?: string) =>
  (fromBackend || fromMergedSettings)!;
