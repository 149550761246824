import { useState } from 'react';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { Box, Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { customerTracking } from '@guest-widgets/shared/src/utils/customerTracking/customerTracking';

import ItemDeleteDialog from '../../common/ItemDeleteDialog';
import { useProduct } from '../../contexts/productContext/productContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useCart } from '../../contexts/cartContext/cartContext';
import { getRemoveFromCart } from '../../trackingInfo/cartHelpers';
import { CartItem } from '../../contexts/apiContext/cart/cart';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const UpsellInCart = ({ item }: ItemUpsellPriceProps) => {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [rateLimitError, setRateLimitError] = useState(false);

  const { companyInformation, currency } = useProduct();
  const { removeItem } = useCart();

  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const itemPrice = formatPrice(item.price.total.amount, item.price.total.currencyCode);

  const onOpenRemoveDialog = () => {
    setOpenRemoveDialog(true);
  };

  const onCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const onDeleteCartItem = () => {
    removeItem
      .mutateAsync(item as CartItem)
      .then(() => {
        onCloseRemoveDialog();
        const removedItem = getRemoveFromCart(item as CartItem, currency);
        if (removedItem) customerTracking().removeFromCart?.(removedItem);
      })

      .catch((err) => {
        if (err.message.includes('429')) {
          setRateLimitError(true);
        }
      });
  };

  return (
    <PriceWrapper>
      <Box display="flex" flexDirection="column">
        {!!item.totalQuantity && (
          <Typography variant="caption" sx={{ opacity: 0.6, fontWeight: 700 }}>
            <Text id="for-variable-guests" quantity={item.totalQuantity} />
          </Typography>
        )}
        <PriceText variant="body2">{itemPrice} </PriceText>
      </Box>
      <Button
        variant="outlined"
        size="small"
        onClick={onOpenRemoveDialog}
        sx={{ minWidth: 'unset', padding: '6px', borderWidth: 1 }}
      >
        <DeleteIcon sx={{ fontSize: 18 }} />
      </Button>
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={onDeleteCartItem}
        productName={item?.product.name ?? ''}
        rateLimitError={rateLimitError}
      />
    </PriceWrapper>
  );
};

const PriceWrapper = styled(Box)({
  display: 'flex',
  alignSelf: 'end',
  justifyContent: 'space-between',
  alignItems: 'end',
});

const PriceText = styled(Typography)({
  lineHeight: 1,
  fontWeight: 700,
});
