import React from 'react';
import MuiSkeleton from '@mui/material/Skeleton';
import { classes } from '@guest-widgets/shared/src/classes';
import { Box } from '@mui/material';

import { StyledSkeletonContainer } from '../../StyledSharedComponents';

export const Skeleton = () => {
  return (
    <StyledSkeletonContainer className={classes.booking.loading}>
      <>
        <Box pt={1} pb={6} display="flex" justifyContent="space-between">
          <MuiSkeleton variant="circular" width={32} height={32} />
          <MuiSkeleton variant="circular" width={32} height={32} />
          <MuiSkeleton variant="circular" width={32} height={32} />
        </Box>
        <MuiSkeleton variant="text" width={250} height={36} />
        <Box pt={4} pb={4}>
          <MuiSkeleton variant="text" width={100} height={36} />
          <MuiSkeleton variant="rectangular" height={46} />
        </Box>
        <Box pb={4}>
          <MuiSkeleton variant="text" width={100} height={36} />
          <MuiSkeleton variant="rectangular" height={46} />
        </Box>
        <Box pb={4}>
          <MuiSkeleton variant="text" width={100} height={36} />
          <MuiSkeleton variant="rectangular" height={46} />
        </Box>
        <Box pb={4}>
          <MuiSkeleton variant="text" width={100} height={36} />
          <MuiSkeleton variant="rectangular" height={46} />
        </Box>
        <Box pb={4}>
          <MuiSkeleton variant="text" width={100} height={36} />
          <MuiSkeleton variant="rectangular" height={46} />
        </Box>
      </>
    </StyledSkeletonContainer>
  );
};
