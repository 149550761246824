import { useEffect } from 'react';
import { customerTracking } from '@guest-widgets/shared/src/utils/customerTracking/customerTracking';

import { useProduct } from '../contexts/productContext/productContext';
import { useRouter } from '../contexts/routerContext/routerContext';
import { useCart } from '../contexts/cartContext/cartContext';

import { getCartItems } from './cartHelpers';

export const useTrackingCart = () => {
  const { currentStep } = useRouter();
  const { cartWithSteps } = useCart();
  const { currency } = useProduct();

  // TODO: Taoufik please check this, items are undefined and throwing an error
  useEffect(() => {
    if (currentStep === 'cart' && cartWithSteps.data?.cart?.items !== undefined) {
      const cartInfo = getCartItems(cartWithSteps.data?.cart.items ?? [], currency);
      if (cartInfo) customerTracking().viewCart?.(cartInfo);
    }
  }, [cartWithSteps.data?.cart?.items, currentStep]);
};
