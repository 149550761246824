import React from 'react';
import { Box, FormControlLabel, TextField, Checkbox, styled } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';
import { Text } from '@eo-locale/react';

import { useContact } from '../../contexts/contactContext/contactFormContext';
import { WaiverField } from '../../contexts/contactContext/contact';

interface WaiverViewProps {
  field: WaiverField;
}

export const WaiverView = ({
  field: { id, tip, value, label, waiverText, hidden, required },
}: WaiverViewProps) => {
  const {
    form: { register, formState },
  } = useContact();

  const errorStatus = {
    error: Boolean(formState.errors.contact?.[id]),
    helperText: formState.errors.contact?.[id]?.message || tip,
  };

  if (hidden) return null;

  return (
    <WaiverContainer>
      <TextField
        maxRows={5}
        fullWidth
        variant="outlined"
        label={label}
        multiline
        InputProps={{
          readOnly: true,
          inputProps: {
            className: classes.booking.input,
          },
        }}
        value={waiverText}
        {...errorStatus}
      />
      <Box>
        <FormControlLabel
          control={
            <Checkbox color="secondary" defaultChecked={value} {...register(`contact.${id}`)} />
          }
          label={<Text id="i-agree-to-the-above-waiver" />}
        />
      </Box>
    </WaiverContainer>
  );
};

const WaiverContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
}));
