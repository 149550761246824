import { Collapse } from '@mui/material';

import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { ErrorMessage } from '../ErrorMessage';

import { Optional } from './Optional';
import { SameParentQuantity } from './SameParentQuantity';

interface DescriptionProps {
  item: UpsellItem;
}

export const Description = ({ item }: DescriptionProps) => {
  const {
    totalQuantity,
    error,
    configuration: { sameParentQuantity },
    product,
  } = item;

  return (
    <>
      {error?.message && <ErrorMessage message={error.message} />}
      <Collapse in={sameParentQuantity && !!totalQuantity && !error}>
        <SameParentQuantity />
      </Collapse>
      <Collapse in={(!sameParentQuantity || !totalQuantity) && !error}>
        <Optional description={product.description} />
      </Collapse>
    </>
  );
};
