import { useEffect } from 'react';
import { customerTracking } from '@guest-widgets/shared/src/utils/customerTracking/customerTracking';

import { useProduct } from '../contexts/productContext/productContext';
import { useSettings } from '../contexts/settingsContext/settingsContext';
import { useRouter } from '../contexts/routerContext/routerContext';

import { getProduct } from './getProduct';

export const useTrackingProductInfo = () => {
  const { currentStep } = useRouter();
  const product = useProduct();
  const settings = useSettings();

  useEffect(() => {
    const productVaribales = `${product.productId}-${settings.productId}-${currentStep}`;
    if (product.viewedProduct === productVaribales) {
      return;
    }

    if (currentStep === 'product' && product && settings && !settings.isLoading) {
      product.setViewedProduct(productVaribales);
      const productInfo = getProduct(product, settings);
      if (productInfo) customerTracking().viewProduct?.(productInfo);
    }
  }, [product.productId, settings.productId, currentStep]);
};
