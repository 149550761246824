import React from 'react';

import { useProduct } from '../contexts/productContext/productContext';

import { CurrencyAndLanguage } from './CurrencyAndLanguage/CurrencyAndLanguage';
import { Unavailable } from './Unavailable';
import { GuestProduct } from './Guest/GuestProduct';

export const Product = () => {
  const product = useProduct();

  if (!product.isAvailable) return <Unavailable />;

  return (
    <>
      {product && <GuestProduct />}
      <CurrencyAndLanguage />
    </>
  );
};
