import { styled } from '@guest-widgets/core';
import { Box, Typography, Button } from '@mui/material';
import { Text } from '@eo-locale/react';
import { QuantitySelector } from '@guest-widgets/shared/src/components/QuantitySelector/QuantitySelector';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { SelectorSwitcher } from '../SelectorSwitcher';
import { useProduct } from '../../contexts/productContext/productContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const Optional = ({ item }: ItemUpsellPriceProps) => {
  const { companyInformation } = useProduct();
  const { handleQuantityChange } = useUpsellInSelection();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const itemPrice = formatPrice(item.price.total.amount, item.price.total.currencyCode);

  const FirstElement = () => (
    <Button
      variant="outlined"
      size="small"
      onClick={() => handleQuantityChange(item.parent.lineId, item.product.productId, 1)}
      sx={{ fontSize: 14, borderWidth: 1 }}
    >
      <Text id="select" />
    </Button>
  );

  const SecondElement = () => (
    <QuantitySelector
      quantity={item.totalQuantity}
      handleQuantityChange={(qty) =>
        handleQuantityChange(item.parent.lineId, item.product.productId, qty)
      }
    />
  );

  return (
    <PriceWrapper>
      <PriceText variant="body2">
        <PriceWithBreakLine price={itemPrice} />
      </PriceText>
      <SelectorSwitcher
        quantity={item.totalQuantity}
        FirstElement={FirstElement}
        SecondElement={SecondElement}
      />
    </PriceWrapper>
  );
};

const PriceWrapper = styled(Box)({
  display: 'flex',
  alignSelf: 'end',
  justifyContent: 'space-between',
  alignItems: 'end',
});

const PriceText = styled(Typography)({
  lineHeight: 1,
  fontWeight: 700,
});
