import { useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Text } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { styled } from '@guest-widgets/core';

import { useUpsell } from '../contexts/upsellContext/upsellContext';
import { useRouter } from '../contexts/routerContext/routerContext';
import { Totals } from '../common/Totals';
import { InfoContainer } from '../common/InfoContainer';
import { useInnerUpsell } from '../hooks/upsell/useInnerUpsell';
import { LoadingContainer } from '../StyledSharedComponents';

import { Navigation } from './Navigation';
import { Description } from './Description/Description';
import { UpsellInSelectionProvider } from './upsellInSelectionContext';

const UpsellInner = () => {
  const { upsells, cartStatus, hasUpsells, hasError } = useUpsell();
  const { nextStep, previousStep, stepBefore } = useRouter();
  const { addToCartHandler, isSelectedUpsell, upsellStatus, addToCartErrors } = useInnerUpsell();

  useEffect(() => {
    const { isSuccess, isFetching } = cartStatus;
    const hasNoUpsellsToShow = isSuccess && !isFetching && !hasUpsells;

    // If the cart is successfully updated and there are no upsells to show, go to the next step
    if (hasNoUpsellsToShow) {
      stepBefore === 'product' ? nextStep() : previousStep();
    }
  }, [cartStatus.isSuccess, cartStatus.isFetching]);

  const rateLimitError = (upsellStatus?.error as Error)?.message.includes('429');

  return !!upsells.length ? (
    <Box pt={4} className={`${booking.upsell.root}`}>
      <Typography variant="h2" mb={4}>
        <Text id="complete-your-booking" />
      </Typography>
      <Grid container spacing={4}>
        {upsells.map((item) => (
          <Grid key={item.product.productId} item xs={12} sx={{ position: 'relative' }}>
            <Info>
              <InfoContainer item={item} />
              <Description item={item} />
            </Info>
            {isSelectedUpsell(item) && upsellStatus.isFetching && (
              <LoadingContainer>
                <CircularProgress size={50} />
              </LoadingContainer>
            )}
          </Grid>
        ))}
      </Grid>
      <Totals />

      <Navigation
        actionStatus={[cartStatus, upsellStatus]}
        onAddToCart={addToCartHandler}
        parentErrors={addToCartErrors}
        disabled={hasError}
        rateLimitError={rateLimitError}
      />
    </Box>
  ) : null;
};

const Info = styled(Box)(({ theme: { spacing, palette } }) => ({
  overflowX: 'hidden',
  paddingLeft: 0,
  borderRadius: spacing(2),
  border: `1px solid ${palette.grey[400]}`,
  padding: spacing(2),
}));

export const Upsell = () => {
  return (
    <UpsellInSelectionProvider>
      <UpsellInner />
    </UpsellInSelectionProvider>
  );
};
