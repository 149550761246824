import parseISO from 'date-fns/parseISO';

import type { Timeslots, TimeslotsDay, TimeslotsRange } from '../../timeslotsContext/timeslots';
import { ApiInvalidStructureException } from '../apiContext';
import { removeTimezone } from '../product/formatDate';

import { TimeslotResponse } from './dtos/response';

export const mapToTimeslot = (
  response: TimeslotResponse | ErrorResponse,
  range: TimeslotsRange
): Timeslots => {
  if (isErrorResponse(response)) throw new ApiInvalidStructureException(response.message);

  const { data = [] } = response;

  return {
    days: data[0]?.availabilityDates?.map(({ date, timeslots, isAvailable }) => ({
      day: parseISO(date),
      availabilityTimes: timeslots.map(({ available, start, end, rules }) => {
        const baseProps = {
          availableQuantity: available <= 0 ? 0 : available,
          trafficLightStatus: available <= 0 ? 'sold-out' : 'available',
          rules,
        };

        if (!end) {
          return {
            ...baseProps,
            time: new Date(removeTimezone(start)),
          };
        }

        return {
          ...baseProps,
          timeRange: {
            startTime: new Date(removeTimezone(start)),
            endTime: new Date(removeTimezone(end)),
          },
        };
      }),
      trafficLightStatus: isAvailable ? 'available' : 'sold-out',
    })) as TimeslotsDay[],
    range: {
      from: range.from,
      to: range.to,
    },
  };
};

type ErrorResponse = { message: string };

export const isErrorResponse = (
  response: TimeslotResponse | ErrorResponse
): response is ErrorResponse => !!(response as ErrorResponse).message;
