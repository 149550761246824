import { PublicMoney, PublicPrice, Tax } from '../../cartContext/cart';
import {
  LineItem,
  Pricing,
  ValidateData,
  ValidatePricing,
  ValidatePricingTax,
} from '../product/dtos/response';
import { removeTimezone } from '../product/formatDate';
import { mapToUpsells } from '../upsell/mapToUpsells';

import { CartDeposit, CartItem, CartItemPrice, DepositOption } from './cart';

export const mapToTotals = (objData: ValidateData, currency: string) => {
  return {
    grand: mapToPublicPrice(objData.pricing?.total, currency),
    subtotal: mapToPublicPrice(objData.pricing?.subTotal, currency),
    taxes: mapToTaxes(objData.pricing?.taxes || [], currency),
    discount:
      objData?.lineItems.find((lineItem) => lineItem.pricing?.discount)?.pricing?.discount ??
      undefined,
    deposits: mapToDeposit(objData.pricing, currency),
  };
};

export const mapToPublicMoney = (item?: number, currency?: string): PublicMoney => ({
  amount: item ? item / 100 : 0,
  currencyCode: currency ? currency : 'EUR',
});

export const mapToPublicPrice = (amount?: number, currency?: string): PublicPrice => ({
  gross: mapToPublicMoney(amount, currency),
});

export const mapToItem = (lineItem: LineItem, cartItem: CartItem, currency: string): CartItem => {
  return {
    id: lineItem.lineId,
    product: {
      ...cartItem.product,
    },
    start: removeTimezone(lineItem.start),
    end: removeTimezone(lineItem.end),
    price: mapToItemPrice(lineItem.pricing, currency),
    guestTypes: !!Object.keys(lineItem.guestTypes).length
      ? lineItem.guestTypes
      : cartItem.guestTypes, // Covers the case where the guestTypes are not returned in validateCart (qty case)
    isAvailable: lineItem.isAvailable,
    totalQuantity: lineItem.guestCount,
    ...(lineItem.error ? { error: lineItem.error?.message } : {}),
    ...(lineItem.pricing?.discount
      ? {
          discount: {
            code: cartItem.discount?.code ?? '',
            name: lineItem.pricing?.discount?.name,
            amount: lineItem.pricing?.discount?.amount,
            adjustmentType: lineItem.pricing?.discount?.adjustmentType,
            discountType: lineItem.pricing?.discount?.discountType,
          },
        }
      : {}),
    ...(lineItem.upsells?.length ? { upsells: mapToUpsells(lineItem) } : {}),
  };
};

export const mapToItemPrice = (pricing?: Pricing, currency?: string): CartItemPrice => ({
  subtotal: mapToPublicMoney(pricing?.subTotal, currency),
  total: mapToPublicMoney(pricing?.subTotal, currency),
  tax: mapToPublicMoney(pricing?.taxTotal, currency),
  inclusiveTaxTotal: mapToPublicMoney(pricing?.inclusiveTaxTotal, currency),
});

const mapToDeposit = (pricing?: ValidatePricing, currency?: string): CartDeposit[] => [
  {
    option: DepositOption.DEPOSIT,
    price: mapToPublicMoney(pricing?.depositCents, currency),
  },
  {
    option: DepositOption.FULL,
    price: mapToPublicMoney(pricing?.balanceDueCents ?? 0, currency),
  },
];

const mapToTaxes = (responseTaxes: ValidatePricingTax[], currency: string): Tax[] =>
  responseTaxes
    .filter((tax) => !tax.inclusive)
    .sort((a, b) => a.weight - b.weight)
    .map(({ name, amountCents, amountPercent, type }) => ({
      name,
      amount: mapToPublicMoney(amountCents, currency),
      amountPercent,
      type,
    }));
