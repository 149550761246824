import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ContactForm } from './contact';
import { useValidation } from './useValidation';
import { getContactDefaultValues } from './getContactDefaultValues';
import type { ContactContext } from './types';
import { useContactContext } from './contactContext';

const contactContext = createContext({} as ContactContext);

export const ContactFormContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const contactApi = useContactContext();

  const contactData = contactApi.contact.data?.contact;

  const [isInvoiceVisible, setIsInvoiceVisible] = useState(
    Boolean(contactData?.invoice?.requested)
  );

  const schema = useValidation(isInvoiceVisible, contactData);

  useEffect(() => {
    Object.entries(getContactDefaultValues(contactData) || {}).forEach(([key, value]) =>
      form.setValue(`contact.${key}`, value)
    );

    Object.entries(contactData?.invoice?.content || {}).forEach(([key, value]) =>
      form.setValue(`invoice.${key}`, value)
    );

    setIsInvoiceVisible(Boolean(contactData?.invoice?.requested));
  }, [contactData]);

  const form = useForm<ContactForm>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const { additionalInformation, basicInformation, invoice } = contactData || {};

  const value: ContactContext = {
    ...contactApi,
    additionalFields: additionalInformation,
    basicInformation,
    invoice,
    isInvoiceVisible,
    setIsInvoiceVisible,
    form: {
      register: form.register,
      formState: form.formState,
      handleSubmit: (handler) =>
        form.handleSubmit((contactForm) => {
          handler(isInvoiceVisible ? contactForm : { ...contactForm, invoice: undefined });
        }),
      setValue: form.setValue,
      getValues: form.getValues,
      watch: form.watch,
      unregister: form.unregister,
    },
  };

  return <contactContext.Provider value={value}>{children}</contactContext.Provider>;
};

export const useContact = () => useContext(contactContext);
