import { getCountryForTimezone } from 'countries-and-timezones';

/**It gets user country based on the user browser timezone or product locale */
export const getUserTimezone = (locale: string) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = getCountryForTimezone(timezone)?.id;

  if (!country) {
    const productLocale = locale.split('-')[1];
    return productLocale;
  }

  return country;
};
