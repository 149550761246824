export class PaymentNotDefinedException extends Error {
  constructor() {
    super('please-select-payment-method');
  }
}

export class ApiStripeException extends Error {
  constructor(errorMessage?: string) {
    super(errorMessage);
  }
}

export class ContactSubmitException extends Error {
  constructor(errorMessage?: string) {
    super(errorMessage);
  }
}
