import { useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Text } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';

import { useProductInSelection } from '../../Guest/productInSelectionContext';
import { useFlextime } from '../../../hooks/product/useFlextime';

import { SelectFlextime } from './SelectFlextime';

export const Flextime = () => {
  const { date = {}, time } = useProductInSelection();
  const {
    startTimes,
    endTimes,
    handleAutoSelect,
    startTimeChange,
    endTimeChange,
    isSelectedStartTimeAvailable,
    isSelectedEndTimeAvailable,
  } = useFlextime();

  useEffect(handleAutoSelect, [
    startTimes[0].timeRange.startTime.valueOf(),
    endTimes[0]?.timeRange.endTime.valueOf(),
  ]);

  if (!startTimes) return null;

  return (
    <>
      <Title variant="h4" className={booking.step.title}>
        <Text id="time" />
      </Title>
      <Box display="flex" alignItems="flex-end" flexWrap="wrap" pb={1.5} gap={4}>
        <SelectFlextime
          availabilities={startTimes}
          timeSelected={
            isSelectedStartTimeAvailable && date.isCalendarSelection === false ? time : undefined
          }
          timeChange={startTimeChange}
          period="startTime"
        />
        <SelectFlextime
          availabilities={endTimes}
          timeSelected={
            isSelectedEndTimeAvailable && date.isCalendarSelection === false ? time : undefined
          }
          timeChange={endTimeChange}
          period="endTime"
        />
      </Box>
    </>
  );
};

const Title = styled(Typography)(({ theme: { spacing, border } }) => ({
  borderTop: border,
  paddingTop: spacing(4),
  marginTop: spacing(4),
  marginBottom: spacing(4),
}));
