import React, { useRef } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { DayProps, useDayRender, DateRange as DateRangePickerType } from 'react-day-picker';
import { useTranslator } from '@eo-locale/react';

import { useTooltipHandler } from './useTooltipHandler';
import { Box, Tooltip, Button } from './DayPickerStyledComponents';

export const CalendarRangeDayPicker = ({ date, displayMonth }: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps, isHidden, selectedDays, activeModifiers } = useDayRender(
    date,
    displayMonth,
    buttonRef
  );
  const { getTooltipTitle } = useTooltipHandler(date);
  const { translate } = useTranslator();
  const to = (selectedDays as DateRangePickerType)?.to;
  const from = (selectedDays as DateRangePickerType)?.from;
  const disabled = isDisabled(date, activeModifiers);
  const selected = isSelected(date, from, to);

  if (isHidden) return <></>;

  const title = getTooltipTitle({
    from,
    to,
    isSelected: selected,
    isDisabled: disabled,
  });

  return (
    <Box alternativeTitle={translate('unavailable-days-in-range')}>
      <Tooltip arrow placement="top" title={title} disableInteractive>
        <Button
          color={selected ? 'primary' : 'secondary'}
          variant={selected ? 'contained' : 'text'}
          ref={buttonRef}
          {...buttonProps}
          disabled={disabled}
          disableElevation
        />
      </Tooltip>
    </Box>
  );
};

const isDisabled = (date: Date, activeModifiers: any) => {
  const today = new Date();
  return !activeModifiers.available || differenceInCalendarDays(date, today) < 0;
};

const isSelected = (date: Date, from: Date | undefined, to: Date | undefined) => {
  return (
    (!!from && differenceInCalendarDays(date, from) === 0) ||
    (!!to && differenceInCalendarDays(date, to) === 0)
  );
};
