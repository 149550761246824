import {
  CartProps,
  RemoveCartProps,
} from '@guest-widgets/shared/src/utils/customerTracking/customerTrackingCreator';
import { GA4ViewCartData } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { CartItem, CartItemPrice, CartState } from '../contexts/apiContext/cart/cart';
import { SupportedCurrencies } from '../contexts/settingsContext/settings';
import { Quantity } from '../contexts/apiContext/product/dtos/request';

import { mapFBContents, mapGA4Items } from './mappers';

export const getChangedItems = (first: CartState, second: CartState): CartItem[] => {
  return second.items.reduce((acc, secondItem) => {
    const comparableItem = first.items.find((firstItem) => firstItem.id === secondItem.id);

    const guestTypesDiff = comparableItem
      ? getGuestTypesDiff(comparableItem, secondItem)
      : secondItem.guestTypes;

    const qtyDiffTotal = Object.values(guestTypesDiff).reduce((sum, qty) => sum + qty, 0);

    const priceDiff: CartItemPrice = comparableItem
      ? getPriceDiff(comparableItem, secondItem)
      : secondItem.price;

    if (qtyDiffTotal > 0) {
      return [
        ...acc,
        {
          ...secondItem,
          guestTypes: guestTypesDiff,
          price: priceDiff,
        },
      ];
    }

    return acc;
  }, [] as CartItem[]);
};

const getGuestTypesDiff = (firstItem: CartItem, secondItem: CartItem): Quantity => {
  return Object.entries(secondItem.guestTypes).reduce((acc, [key, value]) => {
    acc[key] = value - (firstItem.guestTypes[key] ?? 0);
    return acc;
  }, {} as Quantity);
};

type Entries<T> = [keyof T, T[keyof T]][];

const getPriceDiff = (firstItem: CartItem, secondItem: CartItem): CartItemPrice => {
  return (Object.entries(secondItem.price) as Entries<CartItemPrice>).reduce(
    (acc, [priceKey, value]) => {
      if (firstItem.price[priceKey] && secondItem.price[priceKey]) {
        acc[priceKey] = {
          ...secondItem.price[priceKey]!,
          amount: value!.amount - firstItem.price[priceKey]!.amount,
        };
      }
      return acc;
    },
    {} as CartItemPrice
  );
};

export const getAddToCart = (
  addedItem: CartItem,
  currency: SupportedCurrencies
): CartProps | undefined => getCartItemsInfo(addedItem, currency);

export const getRemoveFromCart = (
  removedItem: CartItem,
  currency: SupportedCurrencies
): RemoveCartProps | undefined => {
  const itemsInfo = getCartItemsInfo(removedItem, currency);

  return itemsInfo
    ? {
        currency: itemsInfo.currency,
        value: itemsInfo.value,
        value_incl_tax: itemsInfo.value_incl_tax,
        ga4_items: itemsInfo.ga4_items,
      }
    : undefined;
};

const getCartItemsInfo = (item: CartItem, currency: SupportedCurrencies): CartProps => {
  const qty = Object.values(item.guestTypes).reduce((sum, qty) => sum + qty, 0);

  const {
    product,
    guestTypes,
    end,
    start,
    price: {
      total: { amount: total },
    },
  } = item;

  const tax = item.price.tax?.amount || 0;
  const inclusiveTaxTotal = item.price.inclusiveTaxTotal?.amount || 0;
  // total is already include the inclusiveTaxTotal so net amount equal total - inclusiveTaxTotal
  const value = total - inclusiveTaxTotal;
  const value_incl_tax = total + tax;
  const totalTaxes = inclusiveTaxTotal + tax;
  return {
    currency,
    value,
    value_incl_tax,
    ga4_items: [
      ...Object.keys(guestTypes)
        .filter((guestTypeKey) => guestTypes[guestTypeKey] !== 0)
        .map((guestType) => {
          return mapGA4Items({
            product,
            itemPrice: Number((value / qty).toFixed(2)),
            category: guestType,
            qty: guestTypes[guestType],
            variant: `${start}-${end}`,
            price_incl_tax: Number((value_incl_tax / qty).toFixed(2)),
            tax: Number((totalTaxes / qty).toFixed(2)),
          });
        }),
    ],
    fb_contents: [mapFBContents(product, qty)],
  };
};
export const getCartItems = (items: CartItem[], currency: SupportedCurrencies): GA4ViewCartData => {
  const GA4itemsInfos = items
    .map((item) => {
      return getCartItemsInfo(item, currency);
    })
    .flat();
  const value = GA4itemsInfos.reduce((acc, item) => {
    return (acc += item.value);
  }, 0);

  // TODO: Taoufik please check this, currency are undefined and throwing an error
  return {
    value,
    currency: GA4itemsInfos[0]?.currency as SupportedCurrencies,
    items: GA4itemsInfos.map((item) => {
      return item.ga4_items;
    }).flat(),
  };
};
