import { AvailabilityTime, TimePeriod } from '../../../contexts/timeslotsContext/timeslots';
import { getTimeslotStartTime } from '../../../contexts/timeslotsContext/timeslotsContext';

interface PeriodWithAvailabilityTimes {
  period: Period;
  times: AvailabilityTime[];
}

export type Period = 'night' | 'morning' | 'afternoon' | 'evening';

const timePeriods: Array<TimePeriod> = [
  { fromHour: 0, toHour: 5, period: 'night' },
  { fromHour: 6, toHour: 11, period: 'morning' },
  { fromHour: 12, toHour: 17, period: 'afternoon' },
  { fromHour: 18, toHour: 24, period: 'evening' },
];

const isTimeWithinPeriod = (time: AvailabilityTime, period: TimePeriod['period']) => {
  const startTime = getTimeslotStartTime(time);
  const periodBaseOnTime = timePeriods.find(
    (timePeriod) =>
      startTime.getHours() >= timePeriod.fromHour && startTime.getHours() <= timePeriod.toHour
  );

  return Boolean(periodBaseOnTime && periodBaseOnTime.period === period);
};

export const groupByPeriod = (
  availabilityTimes?: AvailabilityTime[]
): PeriodWithAvailabilityTimes[] => {
  return timePeriods.reduce<PeriodWithAvailabilityTimes[]>((acc, timePeriod) => {
    const times = availabilityTimes?.filter((availabilityTime) =>
      isTimeWithinPeriod(availabilityTime, timePeriod.period)
    );
    return times?.length ? [...acc, { period: timePeriod.period, times }] : acc;
  }, []);
};
