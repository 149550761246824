import React from 'react';
import { classes } from '@guest-widgets/shared/src/classes';
import { useTranslator } from '@eo-locale/react';
import { PhoneTextField } from '@guest-widgets/shared/src/components/PhoneTextField/PhoneTextField';
import { getUserTimezone } from '@guest-widgets/shared/src/utils/getUserTimezone';
import { CountryCode } from 'libphonenumber-js/core';

import { BasicField as BasicFieldType } from '../../contexts/contactContext/contact';
import { useContact } from '../../contexts/contactContext/contactFormContext';
import { getFlagSize } from '../../StyledSharedComponents';
import { useSettings } from '../../contexts/settingsContext/settingsContext';

export type TextViewProps = {
  field: BasicFieldType;
};

export const TelephoneView = ({
  field: { required, id, tip, value, hidden, label },
}: TextViewProps) => {
  const {
    form: { formState, register, setValue },
  } = useContact();

  const { configuration, locale } = useSettings();

  const { translate } = useTranslator();

  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message || tip;

  const ref = register(`contact.${id}`).ref;

  const language = locale.split('-')[0];
  const country = getUserTimezone(locale) as CountryCode;

  if (hidden) return null;

  return (
    <PhoneTextField
      ref={ref}
      defaultCountry={country}
      countriesLanguage={language}
      onBlur={(phone = '') => setValue(`contact.[${id}]`, phone, { shouldValidate: true })}
      value={value}
      error={error}
      helperText={helperText}
      required={required}
      label={translate(label)}
      flagSize={getFlagSize(configuration?.fontSize)}
      className={classes.booking.field.field}
      InputProps={{
        inputProps: {
          className: classes.booking.input,
        },
      }}
    />
  );
};
