import { Typography, Box, Collapse } from '@mui/material';
import { styled } from '@guest-widgets/core';
import { booking } from '@guest-widgets/shared/src/classes/booking';

import { useContact } from '../contexts/contactContext/contactFormContext';
import {
  isBuyerAdditionalField,
  isPerAttendeeAdditionalField,
} from '../contexts/contactContext/typeGuards';

import { Field } from './Field';

export const AdditionalFields = () => {
  const { additionalFields } = useContact();

  return (
    <Collapse in={!!additionalFields?.length}>
      {additionalFields?.map((field, index) => (
        <Container key={index}>
          {isBuyerAdditionalField(field) &&
            field.map((item) => <Field key={item.id} field={item} />)}
          {isPerAttendeeAdditionalField(field) &&
            Object.entries(field).map(([key, items]) => (
              <Container key={key}>
                <Typography className={booking.field.title} variant="h3">
                  {key}
                </Typography>
                {items.map((item) => (
                  <Field key={item.id} field={item} />
                ))}
              </Container>
            ))}
        </Container>
      ))}
    </Collapse>
  );
};

const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
}));
