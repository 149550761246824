import React, { ElementType, useEffect, useState } from 'react';
import { Collapse, Typography, TypographyProps, styled } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Text } from '@eo-locale/react';

interface ErrorMessageProps extends TypographyProps {
  show: boolean;
  message?: string;
  messageKey?: string;
  icon?: ElementType;
}

export const ErrorMessage = ({
  message,
  messageKey,
  show,
  icon: Icon,
  ...props
}: ErrorMessageProps) => {
  const [isShown, setIsShown] = useState(show);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  if (!message && !messageKey) return null;

  return (
    <Collapse in={isShown} unmountOnExit>
      <ErrorContainer color="error" mt={4} mb={4} {...props}>
        {Icon ? <Icon /> : <ErrorOutlineIcon />}
        {messageKey ? <Text id={messageKey} /> : message}
      </ErrorContainer>
    </Collapse>
  );
};

const ErrorContainer = styled(Typography)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(1),
  textAlign: 'left',
}));
