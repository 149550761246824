import React, { useEffect, useState } from 'react';
import { useTranslator } from '@eo-locale/react';
import { classes } from '@guest-widgets/shared/src/classes';
import { CountryAutocomplete } from '@guest-widgets/shared/src/components/CountryAutocomplete';
import { CountrySelectItem } from '@guest-widgets/shared/src/types/CountrySelectItem';

import { useContact } from '../../contexts/contactContext/contactFormContext';
import { CountryField } from '../../contexts/contactContext/contact';
import { getFlagSize } from '../../StyledSharedComponents';
import { useCountry } from '../../contexts/countryContext/countryContext';
import { useSettings } from '../../contexts/settingsContext/settingsContext';

interface CountryViewProps {
  field: CountryField;
}

export const CountryView = ({
  field: { id, tip, label, required, onChange, hidden },
}: CountryViewProps) => {
  const [selected, setSelected] = useState<CountrySelectItem | null>(null);
  const {
    form: { register, formState, getValues, setValue },
  } = useContact();
  const { countries } = useCountry();
  const { configuration } = useSettings();
  const { translate } = useTranslator();
  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message || tip;
  const ref = register(`contact.${id}`).ref;

  useEffect(() => {
    setSelected(countries.find((item) => item.value === getValues(`contact.${id}`)) ?? null);
  }, [getValues(`contact.${id}`)]);

  if (hidden) return null;

  return (
    <CountryAutocomplete
      options={countries}
      flagSize={getFlagSize(configuration?.fontSize)}
      value={selected}
      onChange={(_, country) => {
        setValue(`contact.${id}`, country?.value || '', { shouldValidate: true });
        // react hook form use classes internally and override 'this'
        // so we need to bind the onChange function to the original 'this'
        onChange?.onChange.bind(onChange)(country?.value);
      }}
      textFieldProps={{
        error: error,
        label: translate(label),
        required: required,
        helperText,
        className: classes.booking.input,
      }}
      className={classes.booking.field.field}
      ref={ref}
    />
  );
};
