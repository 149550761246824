import { Box, Typography } from '@mui/material';
import InformationIcon from '@mui/icons-material/InfoRounded';

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Box sx={{ display: 'flex' }} mt={1}>
    <InformationIcon color="error" sx={{ fontSize: '1.25em', marginTop: '0.1em' }} />
    <Typography variant="caption" color="error" ml={1}>
      {message}
    </Typography>
  </Box>
);
