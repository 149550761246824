import { GuestTypeDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import { UpsellItem } from '../../upsellContext/upsell';
import { Quantity } from '../product/dtos/request';
import { LineItem, Upsell } from '../product/dtos/response';
import { removeTimezone } from '../product/formatDate';
import { AllocationType } from '../../productContext/product';

export const mapToUpsells = (lineItem: LineItem): UpsellItem[] => {
  const { upsells } = lineItem;

  if (!upsells) return [];

  const filteredUpsells =
    import.meta.env.VITE_RESTRICT_UPSELL_CASES === 'true'
      ? upsells.filter(
          ({
            configuration: { dateRestriction, attachmentType, restrictGuestTypeAdjustment },
            product: { guestTypes },
          }) =>
            dateRestriction === 'useParentDates' &&
            attachmentType === 'optional' &&
            !restrictGuestTypeAdjustment &&
            (!guestTypes || guestTypes.length < 2)
        )
      : upsells;

  return filteredUpsells.map((upsell) => ({
    parent: mapToParent(lineItem, upsell),
    configuration: mapToConfiguration(upsell),
    product: mapToProduct(upsell.product),
    price: mapToPrice(upsell.price),
    guestTypes: upsell.product.guestTypes ? mapToGuestTypes(upsell.product.guestTypes) : { qty: 0 },
    totalQuantity: 0,
  }));
};

const mapToParent = (lineItem: LineItem, upsell: Upsell) => ({
  productId: upsell.configuration.productId.toString(),
  lineId: lineItem.lineId,
  start: removeTimezone(lineItem.start),
  end: removeTimezone(lineItem.end),
});

const mapToConfiguration = (upsell: Upsell) => ({
  attachmentType: upsell.configuration.attachmentType,
  dateRestriction: upsell.configuration.dateRestriction,
  sameParentQuantity: upsell.configuration.restrictGuestTypeAdjustment,
});

const mapToProduct = (product: Upsell['product']) => ({
  name: product.title,
  productId: product.id.toString(),
  description: product.information.description.body,
  allocationType: '' as AllocationType,
  brand: '',
  image: {
    alt: product.images[0]?.alt,
    thumbnailUrl: product.images[0]?.thumbnailUrl,
  },
});

const mapToPrice = (price: Upsell['price']) => ({
  total: {
    amount: Number(price.gross.amount),
    currencyCode: price.gross.currencyCode,
  },
});

const mapToGuestTypes = (guestTypes: GuestTypeDto[]) => {
  return guestTypes?.reduce((acc, guestType) => {
    return { ...acc, [guestType.id]: 0 };
  }, {} as Quantity);
};
