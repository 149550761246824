import React, { useEffect } from 'react';

import { useCheckout } from '../../contexts/apiContext/checkout/checkoutContext';
import { useBookingCreate } from '../../contexts/apiContext/checkout/mutations/useBookingCreate';

import { CheckoutCountDown } from './CheckoutCountDown';

export const Checkout = () => {
  const { bookingLink, setBookingLink } = useCheckout();
  const bookingCreate = useBookingCreate();

  useEffect(() => {
    bookingCreate.mutate();
  }, []);

  useEffect(() => {
    if (bookingCreate.isSuccess && bookingCreate.data?.bookingLink) {
      setBookingLink(bookingCreate.data.bookingLink);
    }
  }, [bookingCreate]);

  if (!bookingLink) {
    return null;
  }

  return <CheckoutCountDown bookingLink={bookingLink} />;
};
