import { useMutation } from '@tanstack/react-query';

import { AddToCartParams } from './useAddToCart';

interface DestroyParams extends AddToCartParams {}

export const useDestroy = ({ cartSession }: DestroyParams) => {
  return useMutation({
    mutationFn: async () => {
      cartSession.clearErrors();
      return cartSession.destroy();
    },
  });
};
