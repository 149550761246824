import { useMutation } from '@tanstack/react-query';

import { useSession } from '../session/useSession';
import { CartItem } from '../cart';

export interface AddToCartParams {
  cartSession: ReturnType<typeof useSession>;
}

export const useAddToCart = ({ cartSession }: AddToCartParams) => {
  return useMutation({
    mutationFn: async (cartItems: CartItem[]) => {
      cartSession.clearErrors();
      return await cartSession.addToCart(cartItems);
    },
  });
};
