/**
 * map moment format to date-fns format
 * @param momentFormat
 * @returns
 */
export const mapMomentToDateFormat = (momentFormat: string): string => {
  const formatMap: Record<string, string> = {
    YYYY: 'yyyy', // Year
    YY: 'yy', // Year (2 digit)
    MMMM: 'MMMM', // Full month name
    MMM: 'MMM', // Abbreviated month name
    MM: 'MM', // Month (zero-padded)
    M: 'M', // Month (no padding)
    DD: 'dd', // Day of month (zero-padded)
    D: 'd', // Day of month (no padding)
    HH: 'HH', // Hour (24-hour, zero-padded)
    H: 'H', // Hour (24-hour, no padding)
    mm: 'mm', // Minute (zero-padded)
    m: 'm', // Minute (no padding)
    ss: 'ss', // Second (zero-padded)
    s: 's', // Second (no padding)
    A: 'a', // AM/PM (uppercase)
    a: 'aaa', // AM/PM (lowercase)
    SSS: 'SSS', // Millisecond (0-padded, 3 digits)
    ddd: 'EEE', // Abbreviated day of week
    dddd: 'EEEE',
  };

  let dateFnsFormat: string = momentFormat;
  const regex = new RegExp(Object.keys(formatMap).join('|'), 'g');

  // Replace moment.js format elements with date-fns format elements
  dateFnsFormat = dateFnsFormat.replace(regex, (match) => {
    return formatMap[match] || match; // If a match is found in the formatMap, replace it with the corresponding value, otherwise keep it unchanged
  });

  return dateFnsFormat;
};
