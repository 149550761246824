import { useEffect, useRef } from 'react';
import { useQueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';

import type { QueryStringParams } from './settings';

/**
 * retrieve querystring params
 * @remarks they are available only in the first render,
 * after they are removed to avoid inconsistent states in further page navigation
 * */
export const useQueryStringSettings = (): Record<QueryStringParams, string | undefined> => {
  const query = useQueryRouter();

  const rwid = useRef(query.get(queryId) || undefined);
  const rwstep = useRef(query.get(queryStep) || undefined);
  const utmContent = useRef(query.get(queryUtmContent) || undefined);

  useEffect(() => {
    query.delete(queryId);
    query.delete(queryStep);
    query.delete(queryUtmContent);
  }, [rwid, rwstep, utmContent]);

  return {
    rwid: rwid.current,
    rwstep: rwstep.current,
    utm_content: utmContent.current,
  };
};

const queryId: QueryStringParams = 'rwid';
const queryStep: QueryStringParams = 'rwstep';
const queryUtmContent: QueryStringParams = 'utm_content';
