import { UpsellWithPricing, UpsellItem } from '../../upsellContext/upsell';
import { LineItem, ValidatePricing, ValidateResponse } from '../product/dtos/response';
import { isValidationNeeded, mapToError } from '../product/mapToPricing';

export const mapToUpsellsFromPricing = (
  upsells: UpsellItem[],
  response: ValidateResponse
): UpsellWithPricing => {
  const { lineItems, isValid } = response?.data || {};
  const upsellItems = lineItems.filter((upsell) => upsell.lineId.includes('.'));

  if (!upsellItems) {
    return {
      upsells,
      totals: mapToTotals(upsells[0], response?.data?.pricing),
      isValid,
    };
  }

  return {
    upsells: mapToUpsells(upsells, upsellItems),
    totals: mapToTotals(upsells[0], response?.data?.pricing),
    isValid,
  };
};

const mapToUpsells = (upsells: UpsellItem[], lineItems: LineItem[]): UpsellItem[] => {
  return upsells.map((upsell) => {
    const lineItem = lineItems.find((item) => item.lineId === upsell.id);

    if (!lineItem) return upsell;

    return {
      ...upsell,
      guestTypes: lineItem.guestTypes,
      id: upsell.id ?? lineItem.lineId,
      price: mapToPrice(upsell, lineItem.pricing?.subTotal),
      totalQuantity: lineItem.guestCount,
      error:
        isValidationNeeded(lineItem.error?.context, lineItem.guestTypes) && !!lineItem.guestCount
          ? mapToError(lineItem.error)
          : undefined,
    };
  });
};

const mapToPrice = (upsell: UpsellItem, subTotal?: number) => ({
  total: {
    ...upsell.price.total,
    amount: subTotal ? subTotal / 100 : upsell.price.total.amount,
  },
});

const mapToTotals = (firstUpsell: UpsellItem, pricing?: ValidatePricing) => ({
  subtotal: {
    amount: pricing?.subTotal ? pricing.subTotal / 100 : 0,
    currencyCode: firstUpsell.price.total.currencyCode,
  },
  total: {
    amount: pricing?.total ? pricing.total / 100 : 0,
    currencyCode: firstUpsell.price.total.currencyCode,
  },
});
