import '@guest-widgets/shared/src/utils/enableDevTools';
import { QueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { FeatureProvider } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';

import { ApiContextProvider } from './contexts/apiContext/apiContext';
import { Router } from './Router';
import { ProductContextProvider } from './contexts/productContext/productContext';
import { RouterContextProvider } from './contexts/routerContext/routerContext';
import { CartContextProvider } from './contexts/cartContext/cartContext';
import {
  SettingsConsumer,
  SettingsProps,
  SettingsProvider,
} from './contexts/settingsContext/settingsContext';
import { LocalizationProvider } from './contexts/localizationContext';
import { BookingThemeContextProvider } from './contexts/Theme/bookingThemeContext';
import { CountryContextProvider } from './contexts/countryContext/countryContext';
import { UpsellContextProvider } from './contexts/upsellContext/upsellContext';

export type AppProps = SettingsProps;

export const App = (props: AppProps) => (
  <QueryRouter>
    <ApiContextProvider>
      <SettingsProvider {...props}>
        <BookingThemeContextProvider>
          <LocalizationProvider>
            <CountryContextProvider>
              <SettingsConsumer>
                {({ disableFeatures, enableFeatures }) => (
                  <FeatureProvider disable={disableFeatures} enable={enableFeatures}>
                    <RouterContextProvider>
                      <ProductContextProvider>
                        <CartContextProvider>
                          <UpsellContextProvider>
                            <Router />
                          </UpsellContextProvider>
                        </CartContextProvider>
                      </ProductContextProvider>
                    </RouterContextProvider>
                  </FeatureProvider>
                )}
              </SettingsConsumer>
            </CountryContextProvider>
          </LocalizationProvider>
        </BookingThemeContextProvider>
      </SettingsProvider>
    </ApiContextProvider>
  </QueryRouter>
);

export default App;
