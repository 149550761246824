import { Appointment, AppointmentDayTicket } from '../../contexts/cartContext/cart';

export const isAppointmentDayTicket = (
  appointment: Appointment
): appointment is AppointmentDayTicket => !!(appointment as AppointmentDayTicket).datetimeRange;

export const getAppointmentStartDatetime = (appointment: Appointment): Date =>
  isAppointmentDayTicket(appointment)
    ? appointment.datetimeRange.startDatetime
    : appointment.datetime;
