import { Box, Collapse } from '@mui/material';
import { styled } from '@guest-widgets/core';

import { InfoContainer } from '../../common/InfoContainer';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useCart } from '../../contexts/cartContext/cartContext';

import { ItemDetails } from './ItemDetails';

export interface ItemInfoProps {
  item: CartItem;
}

export const ItemInfo = ({ item }: ItemInfoProps) => {
  const { cartWithSteps } = useCart();
  const upsellItems =
    cartWithSteps.data?.cart.items.filter(
      (cartItem) => cartItem.id.includes('.') && item.id === cartItem.id.split('.')[0]
    ) ?? [];

  return (
    <Container>
      <InfoContainer item={item} />
      <ItemDetails cartItem={item} />
      <Collapse in={!!upsellItems.length} unmountOnExit>
        <UpsellsContainer>
          {upsellItems.map((upsell) => (
            <Collapse key={upsell.product.productId} in={!!upsell.totalQuantity} unmountOnExit>
              <InfoContainer item={upsell} />
            </Collapse>
          ))}
        </UpsellsContainer>
      </Collapse>
    </Container>
  );
};

export const Container = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: spacing(2),
}));

const UpsellsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  marginLeft: spacing(8),
  paddingBlock: spacing(4),
}));
