import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

export interface CheckoutContext {
  paymentId: string | undefined;
  setPaymentId: (paymentReference: string) => void;
  bookingLink: string | undefined;
  setBookingLink: (bookingLink: string) => void;
}

const checkoutContext = createContext({} as CheckoutContext);

export const CheckoutContextProvider = ({ children }: PropsWithChildren<any>) => {
  const [bookingLink, setBookingLink] = useState<CheckoutContext['bookingLink']>();
  const [paymentId, setPaymentId] = useState<CheckoutContext['paymentId']>();

  const contextData = {
    bookingLink,
    setBookingLink,
    paymentId,
    setPaymentId,
  };

  return <checkoutContext.Provider value={contextData}>{children}</checkoutContext.Provider>;
};

export const useCheckout = () => useContext(checkoutContext);
