import { useState, useEffect, useRef } from 'react';

//more info: https://usehooks.com/useDebounce/
export function useDebouncedValue<T>(value: T, delay: number = 600): T | undefined {
  const initialValue = useRef<T>(value);
  const [debouncedValue, setDebouncedValue] = useState<T>();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value === initialValue.current) return;
      setDebouncedValue(value);
      initialValue.current = value;
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
