import type {
  Timeslots,
  TrafficLightStatus,
} from '../../../../contexts/timeslotsContext/timeslots';
import { useTimeslots } from '../../../../contexts/timeslotsContext/timeslotsContext';

/** It returns the modifieds days (traffic light status) of the product timeslot */
export const useDayModifiers = () => {
  const { timeslot } = useTimeslots();
  return mapToDayModifier(timeslot.data?.days);
};

const mapToDayModifier = (timeslots: Timeslots['days'] = []) => {
  const initialValue: Record<TrafficLightStatus, Array<Date>> = {
    available: [],
    'high-demand': [],
    'sold-out': [],
  };

  return timeslots.reduce((result, { day, trafficLightStatus = 'available' }) => {
    result[trafficLightStatus].push(day);
    return result;
  }, initialValue);
};
