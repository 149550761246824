import { Theme } from '@mui/material';

import { ComponentPosition, InputProps } from './styles';

/** Get margin to align the error message with the buttons controller. Only if there is label and it is left aligned */
export const getMarginLeft = (position: ComponentPosition, label?: string) => {
  return `${label && position === 'start' ? label.length + EXTRA_CHARACTERS : 0}ch`;
};

export const cssButtonValues = (size: InputProps['size'], theme?: Theme) => {
  const { typography, spacing } = theme || {};
  const fontSize = typography?.body1?.fontSize || 0;

  const paddingMapper: Record<InputProps['size'], number | string> = {
    small: spacing ? 4 : 'inherit',
    medium: spacing ? '10px 11px' : 'inherit',
    large: 'inherit',
  };

  const fontSizeMapper: Record<InputProps['size'], number | string> = {
    small: fontSize ? Number(fontSize) * 1.125 : 'inherit',
    medium: fontSize ? Number(fontSize) * 1.5 : 'inherit',
    large: 'inherit',
  };

  const widthMapper: Record<InputProps['size'], number | string> = {
    small: 30,
    medium: 46,
    large: 'inherit',
  };

  return { padding: paddingMapper[size], fontSize: fontSizeMapper[size], width: widthMapper[size] };
};

export const cssTextFieldValues = (size: InputProps['size'], spacing?: Theme['spacing']) => {
  const paddingMapper: Record<InputProps['size'], number | string> = {
    small: spacing ? '4.2px 6px' : 'inherit',
    medium: spacing ? '9.8px 15.5px' : 'inherit',
    large: 'inherit',
  };

  const widthMapper: Record<InputProps['size'], number | string> = {
    small: 36,
    medium: 54,
    large: 'inherit',
  };

  return { padding: paddingMapper[size], width: widthMapper[size] };
};

const EXTRA_CHARACTERS = 3;
