import type { GuestProduct } from '../../productContext/product';
import { SupportedCurrencies } from '../../settingsContext/settings';
import { ApiInvalidStructureException } from '../apiContext';

/**
 * Mapper from Checkfront API request to product domain
 */
export const mapToProduct = (
  userCurrency: SupportedCurrencies,
  response: any = {}
): GuestProduct => {
  if (response.id) {
    return {
      name: response.title,
      currency: userCurrency,
      productId: response.id,
      // TODO: hardcoded temporary to view Guest product.
      isAvailable: true,
      allocationType: response.allocationType,
      guestTypes: response.guestTypes,
      displayTime: response.displayTime,
      interval: response.interval,
      companyInformation: response.company!,
      images: response.images,
      price: response.price?.gross?.amount ?? 0,
      allowBookingMultiDays: response.allowBookingMultiDays,
    };
  }

  throw new ApiInvalidStructureException(response?.message);
};
