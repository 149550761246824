import React from 'react';
import { MenuItem, Select, TextFieldProps, styled } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import type { CountryCode } from 'libphonenumber-js/core';

import { CountryOption, CountryOptionProps } from '../CountryAutocomplete';

import { getSortedCountries } from './getSortedCountries';

interface SelectCountryProps {
  value?: CountryCode;
  onChange: (country?: CountryCode) => void;
  size: TextFieldProps['size'];
  flagSize: CountryOptionProps['flagSize'];
  language: string;
}

export const SelectCountry = ({
  value,
  onChange,
  size = 'medium',
  flagSize,
  language,
}: SelectCountryProps) => (
  <StyledSelect
    variant="standard"
    value={value || ''}
    displayEmpty
    onChange={(e) => onChange((e.target.value as CountryCode) || undefined)}
    renderValue={
      value
        ? (country) => <CountryOption value={country as string} flagSize={flagSize} />
        : undefined
    }
  >
    <MenuItem value="">
      <LanguageIcon fontSize={size} />
    </MenuItem>
    {getSortedCountries(language).map((country) => (
      <MenuItem key={country.code} value={country.code}>
        <CountryOption
          label={`${country.name} ${country.callingCode}`}
          flagSize={flagSize}
          value={country.code}
        />
      </MenuItem>
    ))}
  </StyledSelect>
);

const StyledSelect = styled(Select)({
  '&:after': {
    borderBottom: 'none',
  },
});
