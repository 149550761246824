import React from 'react';
import { IconButton, styled } from '@mui/material';
import {
  DayPicker,
  DayPickerBase,
  DayPickerDefaultProps,
  DayPickerSingleProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
} from 'react-day-picker';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { lightGreen, amber } from '@mui/material/colors';

import { classes as dayPicker } from './classes';
import { CalendarDayPicker } from './CalendarDayPicker';
import { useCalendarLanguage } from './useCalendarLanguage';

type CalendarProps = { language: string } & (
  | DayPickerDefaultProps
  | DayPickerSingleProps
  | DayPickerMultipleProps
  | DayPickerRangeProps
);

export const Calendar = ({ language, components, ...props }: CalendarProps) => {
  const calendarLanguage = useCalendarLanguage(language);

  return (
    <StyledDayPicker
      components={{
        Day: CalendarDayPicker,
        IconRight: () => (
          <IconButton component="span" size="large" aria-label="Next">
            <ChevronRightIcon />
          </IconButton>
        ),
        IconLeft: () => (
          <IconButton component="span" size="large" aria-label="Prev">
            <ChevronLeftIcon />
          </IconButton>
        ),
        ...components,
      }}
      locale={calendarLanguage}
      weekStartsOn={calendarLanguage?.options?.weekStartsOn as DayPickerBase['weekStartsOn']}
      {...props}
    />
  );
};

const StyledDayPicker = styled(DayPicker)(({ theme: { spacing, palette, typography } }) => ({
  '& .rdp-months': {
    display: 'flex',
    justifyContent: 'space-around',
    gap: spacing(8),
    marginLeft: spacing(-2),
    marginRight: spacing(-2),
    marginBottom: spacing(2),
  },
  '& .rdp-month': {
    width: '100%',
    maxWidth: 567,
  },
  '& .rdp-table': {
    width: '100%',
    borderSpacing: 0,
    marginTop: spacing(5),
    overflow: 'hidden',
  },
  '& .rdp-head_cell': {
    fontSize: typography.h5.fontSize,
    textAlign: 'center',
    paddingBottom: spacing(3),
  },
  '& .rdp-cell': {
    display: 'table-cell',
    textAlign: 'center',
  },
  '& .rdp-vhidden': {
    display: 'none',
  },
  '& .rdp-button': {
    outline: 'none',
    appearance: 'none',
    border: 'none',
    backgroundColor: 'inherit',
  },
  '& .rdp-caption': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: spacing(2),
    '& h2': {
      margin: 0,
    },
  },
  '& .rdp-nav': {
    position: 'absolute',
    top: -10,
    left: -15,
    right: -15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .rdp-caption_end .rdp-nav': {
    flexDirection: 'row-reverse',
  },
  '& .rdp-caption_start .rdp-nav': {
    flexDirection: 'row',
  },
  '& .rdp-caption_label': {
    display: 'inline',
    fontSize: typography.h2.fontSize,
    lineHeight: typography.h2.lineHeight,
    letterSpacing: typography.h2.letterSpacing,
    fontWeight: 700,
  },
  '& .rdp-nav button[disabled]': {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  [`& .${dayPicker.dayElement}`]: {
    padding: 0,
    fontWeight: 400,
    lineHeight: 2,
    borderRadius: 4,
  },
  [`& .${dayPicker.trafficLights.available} .${dayPicker.dayElement}`]: {
    color: palette.getContrastText(lightGreen[400]),
    backgroundColor: lightGreen[400],
    transition:
      'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: palette.primary.main,
      color: palette.getContrastText(palette.primary.main),
      transform: 'scale(1.14)',
    },
    '& > span': {
      opacity: 1,
    },
  },
  [`& .${dayPicker.trafficLights.highDemand} .${dayPicker.dayElement}`]: {
    color: palette.getContrastText(amber[200]),
    backgroundColor: amber[200],
    transition:
      'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: palette.primary.main,
      color: palette.getContrastText(palette.primary.main),
      transform: 'scale(1.14)',
    },
    '& > span': {
      opacity: 1,
    },
  },
  [`& .${dayPicker.trafficLights.soldOut} .${dayPicker.dayElement}`]: {
    color: palette.grey[900],
    backgroundColor: palette.grey[400],
    opacity: 0.55,
    '& > span': {
      opacity: 1,
    },
  },
  [`& .${dayPicker.selected} .${dayPicker.dayElement}`]: {
    backgroundColor: palette.primary.main,
    color: palette.getContrastText(palette.primary.main),
    fontWeight: 600,
  },
}));
