import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { regionApi } from '@guest-widgets/shared/src/apis/guestExperience';
import { UseQueryResult } from '@tanstack/react-query';

import { ContactApi, DynamicFieldsAction, ContactFromApi } from '../apiContext/contact/types';
import { useContactApi } from '../apiContext/contact/useContactApi';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { useLoadingError } from '../../hooks/useLoadingError';

import type { ContactContextProviderProps } from './types';
import { ContactFormContextProvider } from './contactFormContext';
import { isBuyerAdditionalField, isDropDownField } from './typeGuards';
import { ActionSubscriber, AdditionalField, Contact } from './contact';

const contactApiContext = createContext({} as ContactApi);

export const getField = (
  contact: Contact | undefined,
  name: string
): AdditionalField | undefined => {
  const buyerFields = contact?.additionalInformation?.find(isBuyerAdditionalField);
  if (!buyerFields) return;
  return buyerFields.find((field) => field.id === name);
};

const countryAction: DynamicFieldsAction = {
  fieldId: 'customer_country',
  hash: 1,
  action: async (fieldValue, contact) => {
    const field = getField(contact, 'customer_region');
    if (!field) return;
    const regions = await regionApi.getRegions(fieldValue);
    if (regions.length === 0) {
      // change field type to text if no regions
      field.type = 'text';
    } else {
      field.type = 'dropdown';
      // this condition is only for typescript this will be always dropdown field
      if (!isDropDownField(field)) return;
      field.options = regions.map((region) => ({
        label: region,
        value: region,
      }));
    }
  },
};

export const ContactContextProvider = ({
  children,
  ...rest
}: PropsWithChildren<ContactContextProviderProps>) => {
  const contactApi = useContactApi(rest.initialState);
  const [dynamicFields, setDynamicFields] = useState<DynamicFieldsAction[]>([
    countryAction,
    ...(contactApi.dynamicFields ?? []),
  ]);
  const [contact, setContact] = useState<UseQueryResult<ContactFromApi, unknown>>(
    contactApi.contact
  );

  const contactDeps = contactApi?.contact?.data?.contact?.additionalInformation
    ?.find(isBuyerAdditionalField)
    ?.map((field) => field.id)
    .join('__');

  useEffect(() => {
    if (contact.isFetching) return;
    if (!contact.data?.contact) return;
    dynamicFields.forEach((dynamicActions) => {
      const field = getField(contact.data?.contact, dynamicActions.fieldId);
      if (!field) return;
      if (!field.onChange) {
        field.onChange = new ActionSubscriber({ ...contact }, setContact, [dynamicActions]);
      } else {
        field.onChange.register([dynamicActions]);
      }
    });
  }, [contact, dynamicFields]);

  const { isLoading, errorCode } = useLoadingError(
    contactApi.contact.error,
    contactApi.contact.fetchStatus,
    contactApi.contact.status
  );

  useEffect(() => {
    setContact(contactApi.contact);
    const actions = [countryAction, ...(contactApi.contact.data?.contact?.dynamicActions ?? [])];
    setDynamicFields(actions);
  }, [contactDeps, contactApi.contact.data?.contact, setDynamicFields]);

  const value = { ...contactApi, contact, dynamicFields, setDynamicFields };

  return (
    <contactApiContext.Provider value={value} {...rest}>
      <LoadingErrorWrapper
        isError={contactApi.contact.isError}
        isLoading={isLoading}
        errorCode={errorCode}
      >
        <ContactFormContextProvider> {children}</ContactFormContextProvider>
      </LoadingErrorWrapper>
    </contactApiContext.Provider>
  );
};

export const useContactContext = () => useContext(contactApiContext);
