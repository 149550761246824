import { register } from '@guest-widgets/core';
import { widgetTagNames } from '@guest-widgets/shared/src/config';
import { checkLoadedAndRegister } from '@guest-widgets/shared/src/utils/singleLoad';

import { BookingWidgetWebComponent } from './BookingWidgetWebComponent';

(async () => {
  if (checkLoadedAndRegister('booking')) return;

  register(
    BookingWidgetWebComponent,
    widgetTagNames.booking,
    [
      'customer-code',
      'product',
      'locale',
      'disable-features',
      'enable-features',
      'styles-template-id',
      'widget-id',
      'configuration',
      'sub-id',
    ],
    () => true
  );
})();
