import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { Text } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';

import { LoadingContainer, StyledRowContainer } from '../StyledSharedComponents';
import { useProduct } from '../contexts/productContext/productContext';
import { PriceWithBreakLine } from '../common/PriceWithBreakLine';

export interface ProductDescriptionProps {
  name: string;
  price: number;
  currency: string;
  isLoading?: boolean;
}

export const ProductDescription = ({
  name,
  price,
  currency,
  isLoading = false,
}: ProductDescriptionProps) => {
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  return (
    <StyledRowContainer sx={{ alignItems: 'flex-start', position: 'relative' }}>
      <Typography variant="h3" className={booking.totals.title}>
        {name}
      </Typography>
      <Box className={booking.totals.value} sx={{ minWidth: '12ch' }}>
        <Typography align="right" className={booking.totals.grandTotal}>
          <PriceWithBreakLine price={formatPrice(price, currency)} />
        </Typography>
        <Typography align="right" className={booking.totals.vat}>
          <Text id="incl-vat" />
        </Typography>
      </Box>
      {isLoading && (
        <LoadingContainer>
          <CircularProgress size={35} />
        </LoadingContainer>
      )}
    </StyledRowContainer>
  );
};
