import { UseQueryResult } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';

import { ContactFromApi, DynamicFieldsAction } from '../apiContext/contact/types';

import { InvoiceFattura, InvoiceFatturaFromItaly, InvoiceNormal } from './invoice';

/** Basic contact information types, required for applying custom validations/formatting */
export const basicTypes = [
  'email',
  'emailConfirm',
  'telephone',
  'text',
  'date',
  'firstName',
  'lastName',
  'postalCode',
  'textarea',
] as const;

export type BasicTypes = typeof basicTypes[number];

type TicketDescription = string;
type FieldId = string;

export class ActionSubscriber {
  private registeredActions: Record<number, DynamicFieldsAction> = {};
  constructor(
    private contact: UseQueryResult<ContactFromApi, unknown>,
    private persistState: Dispatch<SetStateAction<UseQueryResult<ContactFromApi, unknown>>>,
    initActions?: DynamicFieldsAction[]
  ) {
    if (initActions) {
      this.register(initActions);
    }
  }

  register(actions: DynamicFieldsAction[]) {
    for (let action of actions) {
      this.registeredActions[action.hash] = action;
    }
  }

  async onChange(event: any) {
    let contact = { ...this.contact };
    for (let action of Object.values(this.registeredActions)) {
      await Promise.resolve(action.action(event, contact.data?.contact));
    }
    this.persistState(contact);
  }
}

/** It represents a single field in the contact form */
export interface Field<ValueType = string> {
  /**Field identifier, also used as form field name */
  id: FieldId;
  type: string;
  required: boolean;
  label: string;
  /** initial field state */
  value?: ValueType;
  tip?: string;
  hidden: boolean;
  onChange?: ActionSubscriber;
}

export interface BasicField extends Field {
  type: BasicTypes;
}

export interface WaiverField extends Field<boolean> {
  /** plain text (make sure to replace <br /> tags with \r\n or any other html markup) waiver content */
  waiverText: string;
  type: 'waiver';
}

export interface DropdownField extends Field {
  options: Array<{ value: string; label: string }>;
  type: 'dropdown';
}

export interface CountryField extends Field {
  options: Array<{ value: string; label: string }>;
  type: 'country';
}

export interface CheckboxField extends Field<boolean> {
  checked: boolean;
  type: 'checkbox';
  defaultValue?: boolean;
}

export interface MultiCheckboxField extends Field<Array<string>> {
  type: 'multicheckbox';
  options: Array<{ value: string; label: string }>;
}

export interface RadioField extends Field {
  type: 'radio';
  others: boolean;
  defaultValue?: string;
  options: Array<{ value: string; label: string }>;
}

export interface HtmlField extends Field {
  type: 'html';
}

export type AdditionalField =
  | BasicField
  | WaiverField
  | DropdownField
  | CountryField
  | CheckboxField
  | MultiCheckboxField
  | HtmlField
  | RadioField;

export type BuyerAdditionalField = Array<AdditionalField>;

export type PerAttendeeAdditionalField = Record<TicketDescription, Array<AdditionalField>>;

export type BasicInformation = Array<BasicField>;

export interface Contact {
  basicInformation: BasicInformation;
  /** Additional information may required per experience (buyer or per attendee)  */
  additionalInformation?: (BuyerAdditionalField | PerAttendeeAdditionalField)[];
  invoice?: Invoice;
}

export interface DynamicContact extends Contact {
  dynamicActions?: DynamicFieldsAction[];
}

export interface Invoice {
  /** Indicates whether the invoice is required by the customer */
  required: boolean;
  /** Indicates whether the user has required the invoice
   *
   * @remarks if invoice required, always true
   */
  requested: boolean;
  content: SupportedInvoices;
}

export type SupportedInvoices = InvoiceNormal | InvoiceFattura | InvoiceFatturaFromItaly;

export type ContactFormFieldValue = string | boolean | string[];

/** It represents the form contact (including invoice) values */
export type ContactForm = {
  contact: Record<FieldId, ContactFormFieldValue>;
  invoice?: Record<FieldId, string>;
  legalNotice?: boolean;
  newsletter?: boolean;
};
