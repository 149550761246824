import React from 'react';

import {
  isBasicField,
  isCheckboxField,
  isCountryField,
  isDropDownField,
  isHtmlField,
  isMultiCheckboxField,
  isRadioField,
  isWaiverField,
} from '../contexts/contactContext/typeGuards';
import { AdditionalField } from '../contexts/contactContext/contact';

import { CheckboxView } from './Fields/CheckboxView';
import { RadioView } from './Fields/RadioView';
import { MultiCheckboxView } from './Fields/MultiCheckboxView';
import { WaiverView } from './Fields/WaiverView';
import { HtmlView } from './Fields/HtmlView';
import { DropdownView } from './Fields/DropdownView';
import { CountryView } from './Fields/CountryView';
import { TelephoneView } from './Fields/TelephoneView';
import { TextAreaView } from './Fields/TextAreaView';
import { TextView } from './Fields/TextView';

interface FieldProps {
  field: AdditionalField;
}

export const Field = ({ field }: FieldProps) => {
  if (isBasicField(field)) {
    if (field.type === 'telephone') return <TelephoneView field={field} />;

    if (field.type === 'textarea') return <TextAreaView field={field} />;

    return <TextView field={field} />;
  }

  if (isCheckboxField(field)) return <CheckboxView field={field} />;

  if (isRadioField(field)) return <RadioView field={field} />;

  if (isMultiCheckboxField(field)) return <MultiCheckboxView field={field} />;

  if (isHtmlField(field)) return <HtmlView field={field} />;

  if (isWaiverField(field)) return <WaiverView field={field} />;

  if (isDropDownField(field)) return <DropdownView field={field} />;

  if (isCountryField(field)) return <CountryView field={field} />;

  return null;
};
