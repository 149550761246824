import { ReactNode } from 'react';
import { classes } from '@guest-widgets/shared/src/classes';

import { Step } from './contexts/routerContext/router';
import { useRouter } from './contexts/routerContext/routerContext';
import { Contact } from './Contact/Contact';
import { Checkout as CheckfrontRedirectCheckout } from './Checkout/CheckfrontRedirect/Checkout';
import { Checkout as NextGenCheckout } from './Checkout/NextGen/Checkout';
import { Cart } from './Cart/Cart';
import { Product } from './Product/Product';
import { useTrackingProductInfo } from './trackingInfo/useTrackingProductInfo';
import { useScrollToTop } from './useScrollToTop';
import { Upsell } from './Upsell/Upsell';
import { PurchaseFailure } from './Success/PurchaseFailure';
import PurchaseSuccess from './Success/PurchaseSuccess';
import { isNextGenCheckoutEnabled } from './common/utils';

export const Router = () => {
  const { currentStep } = useRouter();

  useTrackingProductInfo();
  useScrollToTop();

  const Checkout = isNextGenCheckoutEnabled() ? NextGenCheckout : CheckfrontRedirectCheckout;

  const mapper: Record<Step, ReactNode> = {
    product: <Product />,
    upsell: <Upsell />,
    contact: <Contact />,
    cart: <Cart />,
    checkout: <Checkout />,
    purchaseFailure: <PurchaseFailure />,
    purchaseSuccess: <PurchaseSuccess />,
  };

  return (
    <div className={classes.booking.content}>
      <div className={classes.booking.tabs.tabs}>{mapper[currentStep]}</div>
    </div>
  );
};
