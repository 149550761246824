import format from 'date-fns/format';
import add from 'date-fns/add';

export const formatDate = (date?: Date | string) => {
  if (!date) return '';
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return format(dateObj, "yyyy-MM-dd'T'HH:mm:ss");
};

export const addDays = (date: Date, days: number) => add(date, { days });

/** It receives a format iso date as string and find the last + or - after T,
 * then it splits the string and returns the first part.
 */
export const removeTimezone = (date: string) =>
  date.length >= 20 ? date.split(/[+-](?!.*[+-])/)[0] : date;
