import React, { HTMLInputTypeAttribute } from 'react';
import { styled } from '@mui/material/styles';
import { TextField as MuiTextField } from '@mui/material';
import { useTranslator } from '@eo-locale/react';
import { classes } from '@guest-widgets/shared/src/classes';

import { BasicField as BasicFieldType, BasicTypes } from '../../contexts/contactContext/contact';
import { useContact } from '../../contexts/contactContext/contactFormContext';

export type TextViewProps = {
  field: BasicFieldType;
};

const typeToLabelMap: Partial<Record<BasicTypes, string>> = {
  firstName: 'first-name-lowercase',
  lastName: 'last-name-lowercase',
  email: 'email',
  emailConfirm: 'confirm-e-mail',
};

export const TextView = ({ field: { type, required, label, id, tip, hidden } }: TextViewProps) => {
  const {
    form: { formState, register },
  } = useContact();
  const { translate } = useTranslator();
  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message || tip;

  if (hidden) return null;

  return (
    <TextField
      error={error}
      helperText={helperText}
      variant="outlined"
      type={mapToHtmlType[type]}
      required={required}
      label={translate(label)}
      {...register(`contact.${id}`)}
      className={classes.booking.field.field}
      InputProps={{
        inputProps: {
          className: classes.booking.input,
        },
      }}
    />
  );
};

const mapToHtmlType: Record<BasicTypes, HTMLInputTypeAttribute> = {
  email: 'email',
  emailConfirm: 'email',
  telephone: 'tel',
  text: 'text',
  firstName: 'text',
  lastName: 'text',
  postalCode: 'text',
  date: 'date',
  textarea: 'text',
};

const TextField = styled(MuiTextField)({
  '& input::-webkit-calendar-picker-indicator': {
    filter: 'invert(0.5)',
  },
});
