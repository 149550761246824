import { useQuery } from '@tanstack/react-query';

import { useSettings } from '../../../settingsContext/settingsContext';
import { queryKeys } from '../../../../queryKeys';

import { AddToCartParams } from './useAddToCart';

export const useGetCartWithSteps = ({ cartSession }: AddToCartParams, enabled: boolean = true) => {
  const { locale, providerId } = useSettings();
  const initialData = {};
  const queryDeps = [queryKeys.CART, locale, providerId, cartSession.cartState];
  const queryFn = () => {
    return cartSession.getShoppingCart();
  };

  return useQuery({
    queryKey: queryDeps,
    queryFn,
    placeholderData: initialData,
    enabled,
  });
};
