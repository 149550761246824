import { ImageDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import { PublicMoney, TotalPrice } from '../../cartContext/cart';
import { AllocationType } from '../../productContext/product';
import { Quantity } from '../product/dtos/request';
import { Step } from '../../routerContext/router';
import { UpsellItem } from '../../upsellContext/upsell';

export interface CartState extends TotalPrice {
  id: string;
  items: CartItem[];
  errors: string[];
  isValid: boolean;
  bookingId?: string;
}

export interface CartWithSteps {
  cart: CartState;
  steps: Step[];
}

export interface CartApi {
  cartWithSteps: CartState;
}

export interface CartItemPrice {
  subtotal?: PublicMoney;
  total: PublicMoney;
  tax?: PublicMoney;
  inclusiveTaxTotal?: PublicMoney;
}

export interface CartItemDiscount {
  code: string;
  name?: string;
  discountType?: string;
  amount?: number;
  adjustmentType?: string;
}

export interface CartItem {
  id: string;
  product: {
    productId: string;
    name: string;
    allocationType: AllocationType;
    brand: string;
    image?: ImageDto;
  };
  form?: Record<string, string>;
  guestTypes: Quantity;
  price: CartItemPrice;
  start: string;
  end: string;
  isAvailable?: boolean;
  totalQuantity: number;
  error?: string;
  discount?: CartItemDiscount;
  upsells?: UpsellItem[];
}

export interface CartDeposit {
  option: DepositOption;
  price: PublicMoney;
}

export enum DepositOption {
  DEPOSIT = 'pay-deposit',
  FULL = 'pay-full',
}
