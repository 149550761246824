import { CartItem, CartState, CartWithSteps } from '../cart';
import { Quantity } from '../../product/dtos/request';

import { refreshCartState } from './refreshCartState';
import { AddToCartResult } from './useSession';

interface SimilarItemChecker {
  similarItem?: CartItem;
  remainingItems: CartItem[];
}

interface AddToCartParams {
  cartState: CartState;
  newCartItems: CartItem[];
  currencyCode: string;
  customerCode: string;
  steps: CartWithSteps['steps'];
  discountCodes: string[];
}

export const addToCart = async ({
  cartState,
  newCartItems,
  currencyCode,
  customerCode,
  steps,
  discountCodes,
}: AddToCartParams): Promise<AddToCartResult> => {
  const items = newCartItems.reduce(
    (acc, newCartItem) => {
      const { similarItem, remainingItems } = handleSimilarItems(acc, newCartItem);
      return [...remainingItems, sumSimilarItems(newCartItem, similarItem)];
    },
    [...cartState.items] as CartItem[]
  );

  const newStateCandidate = {
    ...cartState,
    items,
    errors: [],
  };

  const newStateChecked = await refreshCartState(
    newStateCandidate,
    customerCode,
    currencyCode,
    discountCodes
  );

  // TODO: handle error
  const addedItemError = newStateChecked.items.find((item) => item.id === newCartItems[0].id)
    ?.error;

  const newState = addedItemError ? cartState : newStateChecked;

  return {
    cartWithSteps: { cart: newState, steps },
    ...(addedItemError ? { addedItemError } : {}),
  };
};

export const getItemSimilarityKey = ({ product, start, end, discount }: CartItem): string =>
  JSON.stringify({
    productId: product.productId,
    start,
    end,
  });

const handleSimilarItems = (
  cartStateItems: CartItem[],
  experience: CartItem
): SimilarItemChecker => {
  return cartStateItems.reduce(
    (acc, item) => {
      if (getItemSimilarityKey(item) === getItemSimilarityKey(experience)) {
        return { ...acc, similarItem: item };
      }
      return { ...acc, remainingItems: [...acc.remainingItems, item] };
    },
    { similarItem: undefined, remainingItems: [] } as SimilarItemChecker
  );
};

const sumSimilarItems = (experience: CartItem, similarItem?: CartItem): CartItem => {
  if (!similarItem) return experience;

  const guestTypesEntries = [
    ...Object.entries(similarItem.guestTypes),
    ...Object.entries(experience.guestTypes),
  ];
  const guestTypes = guestTypesEntries.reduce((acc, [key, value]) => {
    acc[key] = (acc[key] || 0) + value;
    return acc;
  }, {} as Quantity);

  return { ...similarItem, guestTypes };
};
