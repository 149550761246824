import { Button } from '@mui/material';
import { Text } from '@eo-locale/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { classes } from '@guest-widgets/shared/src/classes';
import { ICON_BASE_SIZE, LoadingButton } from '@guest-widgets/shared/src/components/LoadingButton';

import { useRouter } from '../contexts/routerContext/routerContext';
import { ActionStatus, ApiInvalidStructureException } from '../contexts/apiContext/apiContext';
import { NavigationContainer, StyledErrorContainer } from '../StyledSharedComponents';
import { ContactSubmitException } from '../contexts/apiContext/checkout/exceptions';
import { ErrorMessage } from '../Error';

export interface NavigationProps {
  actionStatus: ActionStatus;
}

export const Navigation = ({ actionStatus }: NavigationProps) => {
  const { setCurrentStep } = useRouter();

  const renderError = () => {
    if (!actionStatus.isError) {
      return null;
    }

    if (
      !(actionStatus.error instanceof ApiInvalidStructureException) &&
      !(actionStatus.error instanceof ContactSubmitException)
    ) {
      return null;
    }
    if (actionStatus.error.message.includes('429')) {
      return <ErrorMessage show messageKey={'rate-limited-error'} mt={0} mb={0} />;
    }
    return <ErrorMessage show message={actionStatus.error.message} />;
  };

  return (
    <NavigationContainer>
      <LoadingButton
        loading={actionStatus.isLoading}
        variant="contained"
        color="primary"
        size="large"
        iconSize={ICON_BASE_SIZE}
        fullWidth
        type="submit"
        className={`${classes.booking.buttons.button} ${classes.booking.buttons.checkout}`}
      >
        <Text id="next" />
      </LoadingButton>
      {renderError()}
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ChevronLeftIcon />}
        onClick={() => setCurrentStep('product')}
        fullWidth
        className={classes.booking.buttons.back}
      >
        <Text id="back" />
      </Button>
    </NavigationContainer>
  );
};
