import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { Box, Typography } from '@mui/material';
import { styled } from '@guest-widgets/core';
import { useTranslator } from '@eo-locale/react';

import { useProduct } from '../../contexts/productContext/productContext';

interface RadioLabelProps {
  text: string;
  price: number;
  currency: string;
  withVat?: boolean;
}

export const RadioLabel = ({ text, price, currency, withVat }: RadioLabelProps) => {
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const { translate } = useTranslator();

  return (
    <LabelContainer>
      <LabelText>
        <Typography variant="h4" sx={{ marginRight: 2 }}>
          {text}
        </Typography>
        {withVat && (
          <Typography variant="caption" color="textSecondary">
            {translate('incl-vat')}
          </Typography>
        )}
      </LabelText>
      <Typography align="right" variant="h2">
        {formatPrice(price, currency)}
      </Typography>
    </LabelContainer>
  );
};

const LabelContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const LabelText = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});
