import React from 'react';
import { Text } from '@eo-locale/react';
import { Typography } from '@mui/material';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import NoTicketsIcon from '@guest-widgets/shared/src/components/Icons/NoTicketsIcon';

import { ErrorContainer, ERROR_ICON_SIZE } from '../StyledSharedComponents';
import { useSettings } from '../contexts/settingsContext/settingsContext';

export const Unavailable = () => {
  const { providerPhone } = useSettings();
  return (
    <div className={booking.soldOut.root}>
      <ErrorContainer className={booking.soldOut.inner}>
        <NoTicketsIcon color="error" style={{ fontSize: ERROR_ICON_SIZE }} />
        <Typography variant="h2" className={booking.soldOut.title}>
          <Text id="tickets" />
          &nbsp;
          <Text id="sold-out" />
        </Typography>
        <div>
          <Typography align="center" className={booking.soldOut.text}>
            <Text id="sorry-this-offer-is-no-longer-available" />
          </Typography>

          {providerPhone && (
            <Typography align="center" className={booking.soldOut.phoneContainer}>
              <span className={booking.soldOut.label}>
                <Text id="please-call-variable" />
              </span>
              <span className={booking.soldOut.phone}>{providerPhone}</span>
            </Typography>
          )}
        </div>
      </ErrorContainer>
    </div>
  );
};
