import TimeErrorIcon from '@mui/icons-material/HistoryToggleOffRounded';

import { Timeslot } from '../Steps/Time/Timeslot';
import { Flextime } from '../Steps/Time/Flextime';
import { ErrorMessage } from '../../Error';

import { useProductInSelection } from './productInSelectionContext';

interface TimeProps {
  hasError: boolean;
  error?: { errorType: string; message: string };
}

export const Time = ({ hasError, error }: TimeProps) => {
  const { allocationType, date } = useProductInSelection();

  if (!date?.from) return null;

  return (
    <>
      {allocationType === 'timeslot' ? <Timeslot dateFrom={date.from} /> : <Flextime />}
      <ErrorMessage
        show={hasError && error?.errorType === 'time'}
        message={error?.message}
        icon={TimeErrorIcon}
      />
    </>
  );
};
