import React, { PropsWithChildren } from 'react';
import { Locale, format } from 'date-fns';
import { CompanyLocaleDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { mapMomentToDateFormat } from '@guest-widgets/shared/src/mappers/momentToDateFnsMapper';

import {
  AvailabilityTime,
  AvailabilityTimeStartTime,
} from '../../../contexts/timeslotsContext/timeslots';
import { isDayTicketTimeslot } from '../../../contexts/timeslotsContext/timeslotsContext';
import { defaultProviderLocale } from '../../../contexts/productContext/productContext';

import { getTimeslotOptionLabel } from './getTimeslotOptionLabel';

interface TimeUnitProps {
  availabilityTime: AvailabilityTime;
  locale?: Locale;
  showEndTimes?: boolean;
  providerLocale?: CompanyLocaleDto;
}

interface TimeUnitLabelProps {
  timeslot: AvailabilityTime;
  locale?: Locale;
  showEndTimes?: boolean;
  providerLocale?: CompanyLocaleDto;
}

export const formatTimeUnit = ({
  timeslot,
  locale,
  showEndTimes,
  providerLocale = defaultProviderLocale,
}: TimeUnitLabelProps): string => {
  if (!locale) return '';

  if (isDayTicketTimeslot(timeslot)) {
    return getTimeslotOptionLabel({
      timeRange: timeslot.timeRange,
      locale,
      showEndTimes,
      providerLocale,
    });
  }

  return format(
    (timeslot as AvailabilityTimeStartTime).time,
    mapMomentToDateFormat(providerLocale?.timeFormatMoment!),
    { locale }
  );
};

export const TimeUnit = ({
  availabilityTime,
  locale,
  showEndTimes,
  providerLocale = defaultProviderLocale,
}: PropsWithChildren<TimeUnitProps>) => {
  return (
    <>{formatTimeUnit({ timeslot: availabilityTime, locale, showEndTimes, providerLocale })}</>
  );
};
