import React from 'react';
import { Typography, Button } from '@mui/material';
import { Text } from '@eo-locale/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { classes } from '@guest-widgets/shared/src/classes';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

import { useRouter } from '../contexts/routerContext/routerContext';
import { EmptyContainer, StyledContainer } from '../StyledSharedComponents';

export const EmptyCart = () => {
  const { setCurrentStep } = useRouter();

  return (
    <StyledContainer className={classes.booking.cart.empty}>
      <EmptyContainer
        className={`${classes.booking.checkout.checkout} ${classes.booking.checkout.cart} ${classes.booking.cart.empty}`}
      >
        <RemoveShoppingCartIcon color="primary" style={{ fontSize: ICON_SIZE }} />
        <Typography variant="h2" align="center" className={classes.booking.cart.emptyText}>
          <Text id="cart-is-empty" />
        </Typography>
        <Typography align="center">
          <Text id="you-do-not-have-any-items-in-your-shopping-cart" />
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ChevronLeftIcon />}
          onClick={() => setCurrentStep('product')}
          className={`${classes.booking.buttons.secondary} ${classes.booking.buttons.continue}`}
        >
          <Text id="continue-shopping" />
        </Button>
      </EmptyContainer>
    </StyledContainer>
  );
};

const ICON_SIZE = 56;
