import { SupportedCurrencies } from '@guest-widgets/booking/src/App/contexts/settingsContext/settings';

export enum ViewEvent {
  view = 'page_view',
  search = 'search',
  product = 'view_item',
  virtualPage = 'virtualPageview',
  viewItemList = 'view_item_list',
  viewCart = 'view_cart',
}

export enum GA4Event {
  product = 'view_item',
  add = 'add_to_cart',
  remove = 'remove_from_cart',
  checkout = 'begin_checkout',
  purchase = 'purchase',
  viewItemList = 'view_item_list',
  selectProduct = 'select_item',
  viewCart = 'view_cart',
}

export enum FBEvent {
  view = 'ViewContent',
  select = 'Search',
  add = 'AddToCart',
  checkout = 'InitiateCheckout',
  purchase = 'Purchase',
}

export enum CustomEvent {
  add = 'actionAddToCart',
  remove = 'actionRemoveFromCart',
  checkout = 'actionCheckout',
  purchase = 'actionPurchase',
}

export enum ADWEvent {
  conversion = 'conversion',
}

export interface GA4Items {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_variant: string;
  quantity: number;
  price: number;
  tax?: number;
  price_incl_tax?: number;
}

export interface GA4ViewCartData {
  value: number;
  currency: SupportedCurrencies;
  items: GA4Items[];
}

export interface FBContents {
  id: string;
  name: string;
  quantity: number;
  item_price: number;
}

export interface Legacy {
  location: string;
  timestamp: number;
  payment_method: string;
  city: string;
  country: string;
  product_ids_txt: string;
  product_ids_cnt: number;
  product_ids_txt2: string;
}

export interface TrackingId {
  tracking_adw_id: string;
  tracking_adw_label: string;
  tracking_fb_id: string;
  tracking_ga_id: string;
}

export interface PurchaseEcommerceGtm {
  transaction_id: string;
  value: number;
  tax: number;
  shipping: number;
  currency: string;
  coupon?: string;
  discount?: number;
  items: GA4Items[];
}
