import { useMutation } from '@tanstack/react-query';
import { useTranslator } from '@eo-locale/react';

import type { ContactForm } from '../../../contactContext/contact';
import { guestApi } from '../../product/guestApi';
import { useSettings } from '../../../settingsContext/settingsContext';
import { ContactSubmitResponse } from '../types';
import { useCart } from '../../../cartContext/cartContext';
import { CartState } from '../../cart/cart';
import { ContactSubmitException } from '../../checkout/exceptions';
import { mapToBookingCreateRequest } from '../../cart/mapToRequest';

type SetContact = (contact?: ContactForm) => void;

export const useContactSubmit = (setContact: SetContact) => {
  const { customerCode } = useSettings();
  const { cartWithSteps } = useCart();
  const { translate } = useTranslator();

  const cart: CartState | undefined = cartWithSteps.data?.cart;

  return useMutation({
    mutationFn: async (contactForm: ContactForm): Promise<ContactSubmitResponse> => {
      setContact(contactForm);

      if (!cart || cart.items.length === 0) {
        throw new ContactSubmitException(translate('cart-is-empty'));
      }

      const validate = await guestApi.validateContact(
        mapToBookingCreateRequest(cart, contactForm),
        customerCode
      );

      return Promise.resolve({ noPaymentNeeded: false, validate });
    },
  });
};
