import { classes } from '@guest-widgets/shared/src/classes';
import { Box, BoxProps, Typography, styled } from '@mui/material';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { Text } from '@eo-locale/react';

import { useProduct } from '../../contexts/productContext/productContext';
import type { TotalRow as TotalRowProps } from '../../hooks/cart/useTotal';
import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';

const TotalRow = ({
  title,
  variable,
  icon: Icon,
  amount,
  currencyCode,
  formatType = 'positive',
  variant = 'body1',
  withColor,
  ...rest
}: TotalRowProps) => {
  const { companyInformation } = useProduct();
  const { formatPrice, formatNegativePrice } = useNumberFormatter(
    companyInformation?.locale?.language
  );
  return (
    <Row className={classes.booking.totals.deposit} withColor={withColor}>
      {Icon && <Icon fontSize="small" />}
      <Typography variant={variant} {...rest} className={classes.booking.totals.title}>
        <Text id={title} {...variable} />
      </Typography>
      {amount !== undefined && (
        <Typography variant={variant} {...rest} className={classes.booking.totals.value}>
          {formatType === 'positive' ? (
            <PriceWithBreakLine price={formatPrice(amount, currencyCode)} />
          ) : (
            <PriceWithBreakLine price={formatNegativePrice(amount, currencyCode)} />
          )}
        </Typography>
      )}
    </Row>
  );
};

const Row = styled(({ withColor, ...props }: { withColor?: boolean } & BoxProps) => (
  <Box {...props} />
))(({ withColor, theme: { spacing, palette } }) => ({
  ...(!withColor ? { color: palette.text.secondary } : {}),
  display: 'flex',
  alignItems: 'center',
  '& > *:not(svg):first-of-type': {
    flexGrow: 1,
    textAlign: 'left',
    paddingLeft: 0,
  },
  '& > *': {
    textAlign: 'right',
    paddingLeft: spacing(5),
    paddingBottom: spacing(1),
  },
}));

export default TotalRow;
