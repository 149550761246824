import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useCart } from '../contexts/cartContext/cartContext';

import { SoldItem } from './SoldItem';
import { SoldItemUpsells } from './SoldItemUpsells';

export const SoldItems = () => {
  const { cartWithSteps } = useCart();
  const cartItems = cartWithSteps.data?.cart?.items?.filter((item) => !item.id.includes('.')) ?? [];

  return (
    <>
      {cartItems.map((cartItem) => (
        <Box key={cartItem.id}>
          <StyledDivider />
          <SoldItem item={cartItem} />
          <SoldItemUpsells item={cartItem} />
        </Box>
      ))}
      <StyledDivider />
    </>
  );
};

const StyledDivider = styled(Divider)(({ theme: { border, spacing } }) => ({
  border: border,
  marginTop: spacing(4),
}));
