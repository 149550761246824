import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';

import { useTimeslotApi } from '../apiContext/timeslot/useTimeslotApi';
import { useProduct } from '../productContext/productContext';
import { useSettings } from '../settingsContext/settingsContext';
import { LoadingErrorWrapper } from '../../LoadingErrorWrapper';
import { CartContext, useCart } from '../cartContext/cartContext';
import { useLoadingError } from '../../hooks/useLoadingError';

import { AvailabilityTime, AvailabilityTimeDayTicket, Timeslots } from './timeslots';

type BasicTimeslotFilters = {
  numberOfMonths?: number;
  dateFrom?: Date;
  nextAvailableDay?: boolean;
  showSoldOut?: boolean;
};

export interface TimeslotContext {
  timeslot: UseQueryResult<Timeslots, unknown>;
  filters: BasicTimeslotFilters;
  setFilters: (filter: BasicTimeslotFilters) => void;
}

const timeslotContext = createContext({} as TimeslotContext);

export interface TimeslotContextProviderProps {
  initialData?: Timeslots;
}

export const TimeslotsContextProvider = ({
  children,
  initialData,
}: PropsWithChildren<TimeslotContextProviderProps>) => {
  const { productId } = useSettings();
  const { trafficLights, displayTime } = useProduct();
  const cartApi = useCart() as CartContext;
  const [filters, setFilters] = useState<BasicTimeslotFilters>({
    showSoldOut: displayTime?.showUnavailable,
    nextAvailableDay: true,
  });
  const hasProduct = !!productId;

  const timeslot = useTimeslotApi({
    initialData,
    ...filters,
    enabled: hasProduct,
    trafficLights,
    cart: cartApi.cartWithSteps?.data?.cart,
  });

  const { isLoading, errorCode } = useLoadingError(
    timeslot.error,
    timeslot.fetchStatus,
    timeslot.status
  );

  const value: TimeslotContext = {
    timeslot,
    setFilters: (newFilters) => setFilters((current) => ({ ...current, ...newFilters })),
    filters,
  };

  return (
    <timeslotContext.Provider value={value}>
      <LoadingErrorWrapper isError={timeslot.isError} isLoading={isLoading} errorCode={errorCode}>
        {children}
      </LoadingErrorWrapper>
    </timeslotContext.Provider>
  );
};

export const useTimeslots = () => useContext(timeslotContext);

export const isDayTicketTimeslot = (
  timeslot: AvailabilityTime
): timeslot is AvailabilityTimeDayTicket => !!(timeslot as AvailabilityTimeDayTicket).timeRange;

export const getTimeslotStartTime = (timeslot: AvailabilityTime): Date => {
  return isDayTicketTimeslot(timeslot) ? timeslot.timeRange.startTime : timeslot.time;
};
