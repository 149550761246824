import { MutationKey, useMutation } from '@tanstack/react-query';

import { AddToCartParams } from './useAddToCart';

export const useRefreshCartState = ({ cartSession }: AddToCartParams) => {
  const mutationKey: MutationKey = ['refreshCart'];

  const mutationFn = async () => {
    return await cartSession.refreshCart();
  };

  return useMutation({ mutationKey, mutationFn });
};
