import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ActiveStepIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
      <circle cx="12" cy="12" r="3" fill="currentColor" stroke="currentColor" strokeWidth="2" />
    </svg>
  </SvgIcon>
);

export const CompleteStepIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.11773 12.2577L10.3959 14.4652L16.8823 8.18608C17.1383 7.93797 17.5519 7.93797 17.808 8.18608C18.064 8.43419 18.064 8.83499 17.808 9.0831L10.862 15.8139C10.6059 16.062 10.1923 16.062 9.93629 15.8139L7.19203 13.1547C6.93599 12.9066 6.93599 12.5058 7.19203 12.2577C7.44808 12.0095 7.86168 12.0095 8.11773 12.2577Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export const InactiveStepIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} color="disabled">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
    </svg>
  </SvgIcon>
);
