import React from 'react';
import { MuiProvider } from '@guest-widgets/core/src/theme/MuiProvider';
import { getThemeType } from '@guest-widgets/core/src/theme/getThemeType';
import { parseJson } from '@guest-widgets/core/src/parseJson';
import { splitProp } from '@guest-widgets/core/src/splitProp';
import { handleStringified } from '@guest-widgets/core/src/handleStringified';
import { APMInit, ErrorBoundaryWrapper } from '@guest-widgets/shared/src/components/ErrorBoundary';
import { classes } from '@guest-widgets/shared/src/classes';
import { uniqueCharId } from '@guest-widgets/shared/src/utils/uniqueCharId';
import type { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';

import { App, AppProps } from './App/App';

APMInit();

interface BookingWidgetWebComponentProps
  extends Omit<AppProps, 'productId' | 'disableFeatures' | 'enableFeatures' | 'configuration'> {
  product?: string;
  disableFeatures?: string | string[];
  enableFeatures?: string | string[];
  configuration?: string | ConfigurationSettingsDto;
}

export const BookingWidgetWebComponent = ({
  product,
  disableFeatures,
  enableFeatures,
  configuration,
  ...props
}: BookingWidgetWebComponentProps) => (
  <ErrorBoundaryWrapper>
    <MuiProvider classNamePrefix={uniqueCharId(classes.booking.prefix)} themeType={getThemeType()}>
      <App
        {...props}
        productId={product}
        disableFeatures={handleStringified(disableFeatures, splitProp)}
        enableFeatures={handleStringified(enableFeatures, splitProp)}
        configuration={handleStringified(configuration, parseJson)}
      />
    </MuiProvider>
  </ErrorBoundaryWrapper>
);
