export const calcLineHeight = (
  fontSize: number | string | undefined,
  lineHeight?: number | undefined,
  sizeFactor?: number
) => {
  if (!fontSize) return undefined;
  if (!sizeFactor) {
    return lineHeight ? Number(fontSize) * lineHeight : fontSize;
  }
  return lineHeight ? Number(fontSize) * lineHeight * sizeFactor : Number(fontSize) * sizeFactor;
};
