import {
  addMinutes,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  set,
  subHours,
} from 'date-fns';

import {
  AvailabilityTimeDayTicket,
  Rules,
  TimeslotsDay,
} from '../../contexts/timeslotsContext/timeslots';
import { TimeRange, useProductInSelection } from '../../Product/Guest/productInSelectionContext';
import { TimeslotDayRange } from '../../Product/Steps/Day/Calendar/CalendarRange/useCalendarRange';

export const useFlextime = () => {
  const { date = {}, time = {}, setTime, interval } = useProductInSelection();
  const { from, to } = date;
  const startTimes = getStartTimeList(from);
  const endTimes = getEndTimeList(to, from);
  const isSelectedStartTimeAvailable = isTimeAvailable('startTime', date, time);
  const isSelectedEndTimeAvailable = isTimeAvailable('endTime', date, time);

  const handleAutoSelect = () => {
    if (!startTimes?.length) return;

    const {
      timeRange: { startTime: firstStartTime },
      rules,
    } = startTimes[0];
    const { endTime: firstEndTime } = endTimes[0]?.timeRange ?? {};
    const { startTime: selectedStart, endTime: selectedEnd } = mapToTimeRange(date, time);
    const startTime = isSelectedStartTimeAvailable ? selectedStart : firstStartTime;
    const endTime = isSelectedEndTimeAvailable
      ? getEndTime(startTime, selectedEnd, rules, interval)
      : getEndTime(startTime, firstEndTime, rules, interval);

    setTime({ startTime, endTime });
  };

  const startTimeChange = (startTime?: Date) => {
    if (!startTime) return setTime({ startTime: undefined, endTime: undefined });

    const availability = startTimes?.find((item) => isEqual(item.timeRange.startTime, startTime));

    if (!availability) {
      throw new Error('Selected time is not available');
    }

    const { rules } = availability;

    setTime({
      startTime,
      endTime: getEndTime(startTime, time.endTime, rules, interval),
    });
  };

  const endTimeChange = (endTime?: Date) => {
    const { startTime } = time;

    if (!endTime) return setTime({ startTime, endTime: undefined });

    if (!startTime || !isAfter(endTime, startTime)) {
      throw new Error('End time must be after start time');
    }

    setTime({ startTime, endTime });
  };

  return {
    startTimes,
    endTimes,
    startTimeChange,
    endTimeChange,
    handleAutoSelect,
    getEndTime,
    isSelectedStartTimeAvailable,
    isSelectedEndTimeAvailable,
  };
};

const getStartTimeList = (from?: TimeslotsDay) => {
  return from?.availabilityTimes as Array<AvailabilityTimeDayTicket>;
};

const getEndTimeList = (to?: TimeslotsDay, from?: TimeslotsDay) => {
  const availabilities =
    (to?.availabilityTimes as Array<AvailabilityTimeDayTicket>) ?? getStartTimeList(from);

  // In this case the time range can possibly finish in the 'startTime' of first avaiability of 'to'
  if (from?.day && to?.day && !isSameDay(from?.day!, to?.day)) {
    return forceFirstAvailability(availabilities);
  }

  return availabilities.length ? availabilities : getStartTimeList(from);
};

/** Includes one more availability when endAvailabilities has different day of startAvailabilities.
 * This extra availability has endTime = startTime since the range of endTimes
 * should start with this value */
const forceFirstAvailability = (
  availabilities: Array<AvailabilityTimeDayTicket>
): Array<AvailabilityTimeDayTicket> => {
  const firstAvailability = availabilities[0];
  const modifiedFirstAvailability = {
    ...firstAvailability,
    timeRange: {
      startTime: subHours(firstAvailability.timeRange.startTime, 1),
      endTime: firstAvailability.timeRange.startTime,
    },
  };

  return [modifiedFirstAvailability, ...availabilities];
};

const getEndTime = (startTime?: Date, endTime?: Date, rules?: Rules, interval: number = 60) => {
  if (startTime && endTime && isSameDay(startTime, endTime)) {
    const withMinimumRules = rules?.minDuration
      ? addMinutes(startTime, rules.minDuration * interval)
      : startTime;
    const withMaximumRules = rules?.maxDuration
      ? addMinutes(startTime, rules.maxDuration * interval)
      : set(startTime, { hours: 23, minutes: 59 });
    const higherThanMinimum = isAfter(endTime, withMinimumRules) ? endTime : withMinimumRules;
    const lowerThanMaximum = isBefore(higherThanMinimum, withMaximumRules)
      ? higherThanMinimum
      : withMaximumRules;

    return isEqual(lowerThanMaximum, startTime)
      ? addMinutes(startTime, interval)
      : lowerThanMaximum;
  }
  return endTime;
};

const isTimeAvailable = (
  timeKey: 'startTime' | 'endTime',
  date: TimeslotDayRange,
  time?: TimeRange
) => {
  const availabilities =
    timeKey === 'startTime' ? getStartTimeList(date.from) : getEndTimeList(date.to, date.from);
  return time?.[timeKey]
    ? availabilities.some(
        (item) =>
          `${item.timeRange[timeKey].getHours()}:${item.timeRange[timeKey].getMinutes()}` ===
          `${time?.[timeKey]!.getHours()}:${time?.[timeKey]!.getMinutes()}`
      )
    : false;
};

const mapToTimeRange = (date: TimeslotDayRange, time: TimeRange): TimeRange => {
  if (!date.from || !time.startTime) return { startTime: undefined, endTime: undefined };

  const { from, to } = date;
  const { startTime, endTime } = time;

  return {
    startTime: set(from.day, {
      hours: getHours(startTime),
      minutes: getMinutes(startTime),
    }),
    endTime: endTime
      ? set(to?.day ?? from.day, {
          hours: getHours(endTime),
          minutes: getMinutes(endTime),
        })
      : to?.day,
  };
};
