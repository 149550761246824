import { useState } from 'react';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { Box, Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { SelectorSwitcher } from '../SelectorSwitcher';
import ItemDeleteDialog from '../../common/ItemDeleteDialog';
import { useProduct } from '../../contexts/productContext/productContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../upsellInSelectionContext';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const SameParentQuantity = ({ item }: ItemUpsellPriceProps) => {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const { companyInformation } = useProduct();
  const { handleQuantityChange } = useUpsellInSelection();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const itemPrice = formatPrice(item.price.total.amount, item.price.total.currencyCode);
  const parentQuantity = Object.values(item.guestTypes).reduce((acc, value) => acc + value, 0);

  const onOpenRemoveDialog = () => {
    setOpenRemoveDialog(true);
  };

  const onCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const onDeleteCartItem = () => {
    handleQuantityChange(item.parent.lineId, item.product.productId, 0);
    onCloseRemoveDialog();
  };

  const FirstElement = () => (
    <Button
      variant="outlined"
      size="small"
      onClick={() =>
        handleQuantityChange(item.parent.lineId, item.product.productId, parentQuantity)
      }
      sx={{ fontSize: 14, borderWidth: 1 }}
    >
      <Text id="select" />
    </Button>
  );

  const SecondElement = () => (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={onOpenRemoveDialog}
        sx={{ minWidth: 'unset', padding: '6px', borderWidth: 1 }}
      >
        <DeleteIcon sx={{ fontSize: 18 }} />
      </Button>
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={onDeleteCartItem}
        productName={item?.product.name ?? ''}
      />
    </>
  );

  return (
    <PriceWrapper>
      <Box display="flex" flexDirection="column">
        {!!item.totalQuantity && (
          <Typography variant="caption" sx={{ opacity: 0.6, fontWeight: 700 }}>
            <Text id="for-variable-guests" quantity={parentQuantity} />
          </Typography>
        )}
        <PriceText variant="body2">{itemPrice} </PriceText>
      </Box>
      <SelectorSwitcher
        quantity={item.totalQuantity}
        FirstElement={FirstElement}
        SecondElement={SecondElement}
      />
    </PriceWrapper>
  );
};

const PriceWrapper = styled(Box)({
  display: 'flex',
  alignSelf: 'end',
  justifyContent: 'space-between',
  alignItems: 'end',
});

const PriceText = styled(Typography)({
  lineHeight: 1,
  fontWeight: 700,
});
