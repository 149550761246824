import React from 'react';
import { Text } from '@eo-locale/react';
import { Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ErrorContainer, ERROR_ICON_SIZE } from '../StyledSharedComponents';

export const PurchaseFailure = () => (
  <ErrorContainer>
    <ErrorOutlineIcon color="error" style={{ fontSize: ERROR_ICON_SIZE }} />
    <Typography variant="h2">
      <Text id="purchase-not-performed" />
    </Typography>
    <Typography align="center">
      <Text id="for-some-unknown-reason-the-purchase-was-not-performed" />
    </Typography>
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        window.location.reload();
      }}
    >
      <Text id="reload-page" />
    </Button>
  </ErrorContainer>
);
