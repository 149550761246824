import { EoLocale, LanguageMap } from '@guest-widgets/shared/src/i18n/i18n';

import enMessages from './locales/en_US.json';
import { localesMapper } from './localesMapper';

export const defaultLocale: EoLocale[] = [
  {
    language: 'en-US',
    messages: enMessages,
  },
];

// TODO: Some keys are missing in other languages (check comment in REG-16693). Translate them.
export const languageMap: LanguageMap = {
  'en-US': () => Promise.resolve(enMessages),
  ...localesMapper,
};
