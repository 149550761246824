import { AdditionalField, Contact } from '../../contactContext/contact';
import { getField } from '../../contactContext/contactContext';
import {
  isCountryField,
  isDropDownField,
  isMultiCheckboxField,
  isRadioField,
} from '../../contactContext/typeGuards';
import { ContactField } from '../product/dtos/response';

import { DynamicFieldsAction } from './types';

export const getFieldOption = (
  name: string,
  contact: Contact | undefined,
  index: number
): string | undefined => {
  const field = getField(contact, name);
  if (!field) return;
  if (
    !isMultiCheckboxField(field) &&
    !isCountryField(field) &&
    !isRadioField(field) &&
    !isDropDownField(field)
  )
    return;
  return field.options?.[index].value;
};

const hideAction = (
  inputField: ContactField,
  fieldValue: string,
  field: AdditionalField,
  contact: Contact | undefined
) => {
  if (inputField.filtered?.hideFilterId) {
    let targetValue = getFieldOption(
      inputField.filtered.hideFilterId,
      contact,
      inputField.filtered.hideFilterChoice
    );
    if (inputField.filtered.hideOperator === 'is' && targetValue === fieldValue) {
      field.hidden = true;
    }

    if (inputField.filtered.hideOperator === 'is not' && targetValue !== fieldValue) {
      field.hidden = true;
    }
  }
};

const hashCode = (s: string) => {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    let chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const showAction = (
  inputField: ContactField,
  fieldValue: string,
  field: AdditionalField,
  contact: Contact | undefined
) => {
  if (inputField.filtered?.showFilterId) {
    let targetValue = getFieldOption(
      inputField.filtered.showFilterId,
      contact,
      inputField.filtered.showFilterChoice
    );

    if (inputField.filtered.showOperator === 'is' && targetValue === fieldValue) {
      field.hidden = false;
    }

    if (inputField.filtered.showOperator === 'is not' && targetValue !== fieldValue) {
      field.hidden = false;
    }
  }
};

export const actionFactory = (inputField: ContactField): DynamicFieldsAction[] => {
  if (!inputField.filtered) return [];
  const returnValue = [];
  if (inputField.filtered.showFilterId) {
    returnValue.push({
      fieldId: inputField.filtered.showFilterId,
      hash: hashCode(
        inputField.filtered.showFilterId +
          inputField.filtered.showFilterChoice +
          inputField.filtered.showOperator
      ),
      action: (fieldValue, contact) => {
        const field = getField(contact, inputField.id);
        if (!field) return;
        showAction(inputField, fieldValue, field, contact);
      },
    } as DynamicFieldsAction);
  }
  if (inputField.filtered.hideFilterId) {
    returnValue.push({
      fieldId: inputField.filtered.hideFilterId,
      hash: hashCode(
        inputField.filtered.hideFilterId +
          inputField.filtered.hideFilterChoice +
          inputField.filtered.hideOperator
      ),
      action: (fieldValue, contact) => {
        const field = getField(contact, inputField.id);
        if (!field) return;
        hideAction(inputField, fieldValue, field, contact);
      },
    } as DynamicFieldsAction);
  }
  return returnValue;
};
